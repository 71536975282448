import praha from './region/praha';
import stk from './region/stk';
import jck from './region/jck';
import plk from './region/plk';
import kvk from './region/kvk';
import ulk from './region/ulk';
import lbk from './region/lbk';
import khk from './region/khk';
import pak from './region/pak';
import vys from './region/vys';
import jmk from './region/jmk';
import olk from './region/olk';
import zlk from './region/zlk';
import msk from './region/msk';

export default [
  [24, 21, 67.13, '771 224 472, 771 224 473 nebo 771 224 474', praha],
  [26, 17, 63.44, '257 280 472, 257 280 880', stk],
  [13, 18, 61.74, '386 720 156 ', jck],
  [11, 18, 59.91, '377 195 562 nebo 377 195 486', plk],
  [ 5, 17, 52.11, '702 158 856, 702 158 851 nebo 354 222 663', kvk],
  [13, 17, 52.38, '775 868 535', ulk],
  [ 8, 17, 60.05, '485 226 351', lbk],
  [11, 17, 63.25, '607 029 662', khk],
  [10, 18, 63.05, '702 127 641 nebo 702 126 717', pak],
  [10, 19, 64.03, '564 602 607', vys],
  [23, 21, 61.75, '734 792 793 nebo 702 238 783', jmk],
  [12, 20, 59.77, '725 816 254 nebo 602 802 442', olk],
  [12, 19, 62.12, '577 043 971 nebo 577 043 972', zlk],
  [22, 19, 55.86, '800 720 210', msk]
];
