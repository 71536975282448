import TwitterFeed from "@/components/twitter/do";
import {date, betterURL, getLinkType, getLinkShort} from "@/common/helpers";

export default {
	name: 'layout-person-detail',
	props: ['hash'],
	data: function () {
		return {}
	},
  components: {
		TwitterFeed
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		person: function () {
			var data = this.$store.getters.pdv('candidate/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		program: function () {
			if (!this.person || !this.person.program) return;

			var comments = [];
			var leader = null;
			var chunks = null;

			var p = this.person.program;

			if (p['leader-comment']) {
				if (p['leader-comment'].candidate === this.person.hash) {
					leader = p['leader-comment'];
				}
			}

			if (p.program && p.program.chunks) {
				chunks = JSON.parse(p.program.chunks);

				if (p.garant && p.garant.length > 0) {
					p.garant.forEach(g => {
						if (g.garant === this.person.hash) {
							var obj = {
								comment: undefined,
								candidate: g.garant,
								chunk: chunks.find(x => x.src === g.chunk)
							}

							if (p.comment && p.comment.length > 0) {
								p.comment.forEach(c => {
									if (c.candidate === this.person.hash && c.chunk === obj.chunk.src) {
										obj.comment = c;
									}
								});
							}

							comments.push(obj);
						}
					});
				}
			}

			return {
				comments,
				leader
			}
		},
		links: function () {
			if (!this.person) return undefined;

			var list = [];

			if (this.person.links) {
				this.person.links.forEach(x => list.push(x.link));
			}

			return list;
		},
		embeds: function () {
			if (!this.person) return undefined;

			var list = [];

			if (this.person.links) {
				this.person.links.forEach(x => {
						if (x.embed === 1 && x.link.split('twitter').length > 1) {
							list.push(x.link);
						}
				});
			}

			return list;
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		linksSelected: function () {
			var list = [];

			var prep = [];

			this.person.links.forEach(link => {
				prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (wiki) list.push(wiki);

			return list;
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		},
		tagsUsed: function () {
			var list = [];

			if (this.tags && this.person && this.person.program && this.person.program.tags) {
				this.person.program.tags.forEach(tag => {
					var chunks = tag.chunk.split(',');

					chunks.forEach(chunk => {

						var tu = this.tags.find(x => x.hash === tag.tag);

						if (tu) {
							var obj = {
								src: chunk,
								icon: tu.icon,
								name: tu.name,
								tag: tag.tag
							}

							list.push(obj);
						}
					});
				});
			}

			return list;
		}
	},
  methods: {
		betterURL, date, getLinkShort,
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		},
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.person) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: self.person.name_first + ' ' + self.person.name_family});
				}
			}, 100);
			
			setTimeout(() => {
				if (location.hash != '') {
					this.$el.querySelector("a[name=" + location.hash.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
				}
			}, 1500);
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
		this.ga();
  }
};
