import {beautifyNumber, betterURL} from "@/common/helpers";

export default {
	name: 'party-regional',
	props: ['hash', 'reg'],
	data: function () {
		return {
			selected: null
		}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		regionName: function () {
			var name = this.$store.state.static.regions.find(x => betterURL(x) === this.hash);

			return name || 'Nezařazení kandidáti';
		},
		regionID: function () {
			var name = this.$store.state.static.regions.findIndex(x => betterURL(x) === this.hash);

			if (this.hash === 'praha') this.name = 0;

			return typeof name != 'undefined' ? name : -1;
		},
		peopleData: function () {
			return this.$store.getters.pdv('region/list/' + this.$store.state.static.regions.findIndex(x => betterURL(x) === this.hash));
		},
		people: function () {
			var list = [];

			if (this.peopleData) {
				this.$store.getters.parties().forEach(party => {
					var obj = {
						party,
						list: []
					};

					obj.list = this.peopleData.list.filter(x => x.party === party.hash);

					obj.list.sort((a, b) => (a.position || 99) - (b.position || 99));

					if (obj.list.length > 0) list.push(obj);
				});
			}

			list.sort((a, b) => a.party.short.localeCompare(b.party.short, 'cs'));

			return list;
		},
		rozhlas: function () {
			var list = [];

			if (this.peopleData && this.people) {
				this.$store.getters.parties().forEach(party => {

					var p = this.people.find(x => x.party.hash === party.hash);

					if (p && party.status === 1) {
						var pos = 1;

						if (this.$store.state.static.rozhlas[(this.regionID + 1) + '_' + party.election_number]) {
								pos = this.$store.state.static.rozhlas[(this.regionID + 1) + '_' + party.election_number];
						}

						// console.log(party.hash, pos);

						if (pos > 0) {
							var obj = {
								party,
								person: p.list.find(x => x.position === pos),
								label: party.short
							}

							if (obj.person) {
								obj.label = obj.person.name_first + ' ' + obj.person.name_family + ' (' + obj.party.short + ')';
							}

							list.push(obj);
						}
					}
				});

				list.sort((a, b) => a.person.name_family.localeCompare(b.person.name_family, 'cs'));

			}

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		ga: function () {
			this.$store.dispatch("ga", {title: 'Kandidátky pro ' + this.regionName});
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
