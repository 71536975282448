export default {
  source: 'https://spravni-odbor.kraj-lbc.cz/page1279/volby-2021-do-poslanecke-snemovny-parlamentu-cr-8-a-9-rijna-2021/11-informace-pro-volice-v-karantene-izolaci-z-duvodu-covid-19/01-k-hlasovani-u-volebniho-stanoviste',
  list: [
    {
      name: "prostor v areálu v Poříční",
      address: "Česká Lípa, Poříční 1918",
      designated: "Česká Lípa",
      maps: {
        "link": 'nagecofefe',
        "embed": 'nagecofefe'
      }
    },
    {
      name: "parkoviště v Pastýřské",
      address: "Liberec, Pastýřska",
      designated: "Liberec",
      maps: {
        "link": 'jesatagaka',
        "embed": 'jesatagaka'
      }
    },
    {
      name: "parkoviště u Městské haly",
      address: "Jablonec nad Nisou, U Přehrady 4747/20",
      designated: "Jablonec nad Nisou",
      maps: {
        "link": 'bucucetaru',
        "embed": 'bucucetaru'
      }
    },
    {
      name: "parkoviště u Sportovního centra",
      address: "Semily, 3. května 327",
      designated: "Semily",
      maps: {
        "link": 'cabugavesa',
        "embed": 'cabugavesa'
      }
    }
  ]
}
