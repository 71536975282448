export default {
	name: 'layout-elections',
	data: function () {
		return {}
	},
  components: {},
	computed: {
	},
  methods: {
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Důležité informace o volbách"});
		setTimeout(() => {
			if (location.hash != '') {
				this.$el.querySelector("a[name=" + location.hash.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
			}
		}, 1500);
  }
};
