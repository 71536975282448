import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'layout-research',
	data: function () {
		return {
			token: null,
			sid: null,
			results: null,
			sent: false,
			email: {
				personal: null,
				val: null,
				guess: null,
				sent: false
			},
			answers: {
				electionParticipation: -1,
				electionDecision: -1,
				electionVote: [],
				electionAnticipation: -1,
				programImportance: -1,
				newsWatch: -1,
				pollsTrust: -1,
				govHappiness: -1,
				govExpectation: -1,
				govLeader: null,
				electionTopics: [],
				personalTopics: [],
				moodPeople: -1,
				moodPersonal: -1
			},
			demographics: {
				sex: -1,
				region: -1,
				age: -1,
				education: -1
			},
			questions: {
				electionParticipation: ['Ano', 'Ještě nevím', 'Ne'],
				electionDecision: ['Ano', 'Ne'],
				electionVote: [],
				electionAnticipation: ['s pocitem naděje', 'nevěřím, že se něco změní', 's obavami', 'potřebujeme změnu'],
				programImportance: ['velmi', 'docela jo', 'moc ne', 'vůbec'],
				newsWatch: ['několikrát denně', 'každý den', 'občas', 'zřídka'],
				pollsTrust: ['považuji je za důvěryhodné', 'sleduji spíš trendy', 'beru je jen orientačně', 'nevěřím jim', 'věřím jiným průzkumům', 'průzkumy nesleduji'],
				govHappiness: ['velmi spokojený', 'spokojený', 'něco se povedlo, něco ne', 'nespokojený', 'velmi nespokojený'],
				govExpectation: ['pokračovat v koalici ANO+ČSSD', 'v jiné podobě za účasti hnutí ANO', 'v úplně novém složení', 'úřednická'],
				govLeader: null,
				electionTopics: ['vystavení účtu vládě','koronavirus / očkování','zdravotnictví obecně','návrat do normálního života','obnova ekonomiky','využití dotací EU','konec uhlí','konec montoven','kvóty na potraviny','digitalizace','státní dluh','dividendy','daňové reformy','minimální mzda','zvýšení důchodů','legalizace drog','energetika','Dukovany','migrace a bezpečnost','manželství pro všechny','ekologie','EU/euro','něco jiné','maturity','důchodová reforma'].sort((a, b) => a.localeCompare(b, 'cs')),
				personalTopics: ['mám zdravotní problémy','přišel jsem o práci','nemohu podnikat','mám finanční problémy','zavřené obchody a služby','nemohu dát děti do školy','nemohu studovat','nemohu cestovat','nemohu do hospody','chybí mi kultura','nemohu jet na chalupu','cítím napětí v rodině','cítím se osaměle','chybí mi kamarádi','vadí mi roušky / respirátory','celkový pocit vyčerpání','mám deprese','cítím se pod tlakem','pocit ohrožení svobody','něco jiné','nic mě netrápí'].sort((a, b) => a.localeCompare(b, 'cs')),
				moodPeople: ['lidé jsou pozitivní', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'lidé jsou negativní', 'lidé jsou naštvaní'],
				moodPersonal: ['mám se dobře', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'je to špatné'],
				sex: ['žena', 'muž', 'jiné', 'nechci uvést'],
				edu: ['základní/vyučený', 'maturita/voš', 'vysokoškolské'],
				age: ['do 30 let', '30-39 let', '40-49 let', '50-59 let', '60-69 let', '70 let a více'],
				region: this.$store.state.static.regions.concat('Žiju mimo Českou republiku')
			}
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			return this.$store.getters.pdv('party/list');
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		leaders: function () {
			return this.$store.getters.pdv('candidate/list/andrej-babis,ivan-bartos,petr-fiala,tomio-okamura,jan-hamacek,vojtech-filip,robert-slachta,lubomir-volny,zuzana-majerova-zahradnikova');
		},
		completed: function () {
			var c = 0;

			if (this.answers.electionParticipation != -1) c++;
			if (this.answers.electionDecision != -1) c++;
			if (this.answers.electionVote.length > 0) c++;
			if (this.answers.electionAnticipation != -1) c++;
			if (this.answers.programImportance != -1) c++;
			if (this.answers.newsWatch != -1) c++;
			if (this.answers.pollsTrust != -1) c++;
			if (this.answers.govHappiness != -1) c++;
			if (this.answers.govExpectation != -1) c++;
			if (this.answers.govLeader != null) c++;
			if (this.answers.electionTopics.length > 0) c++;
			if (this.answers.personalTopics.length > 0) c++;
			if (this.answers.moodPeople != -1) c++;
			if (this.answers.moodPersonal != -1) c++;

			return c;
		},
		canSend: function () {
			var c = true;

			if (this.answers.electionParticipation === -1) c = false;
			if (this.answers.electionParticipation != 2) {
				if (this.answers.electionDecision === -1) c = false;
				if (this.answers.electionVote.length === 0) c = false;
			}
			if (this.answers.electionAnticipation === -1) c = false;
			if (this.answers.programImportance === -1) c = false;
			if (this.answers.newsWatch === -1) c = false;
			if (this.answers.pollsTrust === -1) c = false;
			if (this.answers.govHappiness === -1) c = false;
			if (this.answers.govExpectation === -1) c = false;
			if (this.answers.govLeader === null) c = false;
			if (this.answers.electionTopics.length === 0) c = false;
			if (this.answers.moodPeople === -1) c = false;

			return c;
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		}
	},
  methods: {
		ix: function (list, item) {
			return list.findIndex(x => x === item)
		},
		toggle: function (list, item) {
			var index = this.ix(list, item);

			if (index > -1) {
				list.splice(index, 1);
			} else if (list.length < 3) {
				list.push(item);
			}
		},
		jump: function (hash) {
			var el = document.querySelector("a[name=" + hash + "]");

			if (el) {
				window.scrollTo({top: el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 180, behavior: 'smooth'})
			}
		},
		getToken: function () {
			axios.post(this.$store.state.api + 'research/init', {}).then(response => {
				if (response.data.token) {
					this.token = response.data.token;
					this.sid = response.data.sid;
					setTimeout(() => this.jump('volebni-preference'), 150);

					this.$store.dispatch("ge", {
						action: "init",
						category: "research",
						label: "research_init",
						value: 0
					});
				} else {
					this.sent = true;
					this.email.sent = true;
				}
			});
		},
		sendResults: function () {
			if (this.canSend === false) return;

			var results = [
				this.answers.electionParticipation,
				-1,
				null,
				null,
				null,
				this.answers.electionAnticipation,
				this.answers.programImportance,
				this.answers.newsWatch,
				this.answers.pollsTrust,
				this.answers.govHappiness,
				this.answers.govExpectation,
				this.answers.govLeader,
				null,
				null,
				null,
				null,
				null,
				null,
				this.answers.moodPeople,
				this.answers.moodPersonal,
				this.demographics.sex,
				this.demographics.region,
				this.demographics.age,
				this.demographics.education
			];

			if (this.answers.electionParticipation != 2) {

				results[1] = this.answers.electionDecision;

				this.answers.electionVote.forEach((item, index) => {
					results[2 + index] = item;
				});
			}

			this.answers.electionTopics.forEach((item, index) => {
				results[12 + index] = betterURL(item);
			});

			this.answers.personalTopics.forEach((item, index) => {
				results[15 + index] = betterURL(item);
			});

			axios.post(this.$store.state.api + 'research/store', {
				key: this.sid,
				token: this.token,
				results
			}).then(results => {
				if (results.data.code === 200) {
					this.sent = true;
					this.token = null;
					this.email.token = results.data.token;
					setTimeout(() => this.jump('odeslano'), 150);

					this.$store.dispatch("ge", {
						action: "results",
						category: "research",
						label: "research_results",
						value: 1
					});
				}
			});
		},
		sendEmail: function () {
			axios.post(this.$store.state.api + 'research/contest', {
				key: this.sid,
				token: this.email.token,
				guess: this.email.guess,
				email: this.email.val
			}).then(() => {
				this.email.sent = true;

				this.$store.dispatch("ge", {
					action: "email",
					category: "research",
					label: "research_email_sent",
					value: 2
				});
			});
		},
		betterURL
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Předvolební anketa"});
  }
};
