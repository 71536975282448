import {date, betterURL} from '@/common/helpers';
import SimpleGraph from '@/layout/research/simple-graph/do';

export default {
	name: 'layout-research-monthly-results',
	props: ['month'],
	data: function () {
		return {
			questions: {
				election_participation: ['Ano', 'Ještě nevím', 'Ne'],
				election_decision: ['Ano', 'Ne'],
				// electionVote: [],
				election_anticipation: ['s pocitem naděje', 'nevěřím, že se něco změní', 's obavami', 'potřebujeme změnu'],
				program_importance: ['velmi', 'docela jo', 'moc ne', 'vůbec'],
				news_watch: ['několikrát denně', 'každý den', 'občas', 'zřídka'],
				polls_trust: ['považuji je za důvěryhodné', 'sleduji spíš trendy', 'beru je jen orientačně', 'nevěřím jim', 'věřím jiným průzkumům', 'průzkumy nesleduji'],
				gov_happiness: ['velmi spokojený', 'spokojený', 'něco se povedlo, něco ne', 'nespokojený', 'velmi nespokojený'],
				gov_expectation: ['pokračovat v koalici ANO+ČSSD', 'v jiné podobě za účasti hnutí ANO', 'v úplně novém složení', 'úřednická'],
				// govLeader: null,
				election_topics: ['vystavení účtu vládě','koronavirus / očkování','zdravotnictví obecně','návrat do normálního života','obnova ekonomiky','využití dotací EU','konec uhlí','konec montoven','kvóty na potraviny','digitalizace','státní dluh','dividendy','daňové reformy','minimální mzda','zvýšení důchodů','legalizace drog','energetika','Dukovany','migrace a bezpečnost','manželství pro všechny','ekologie','EU/euro','něco jiné','maturity','důchodová reforma'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				personal_topics: ['mám zdravotní problémy','přišel jsem o práci','nemohu podnikat','mám finanční problémy','zavřené obchody a služby','nemohu dát děti do školy','nemohu studovat','nemohu cestovat','nemohu do hospody','chybí mi kultura','nemohu jet na chalupu','cítím napětí v rodině','cítím se osaměle','chybí mi kamarádi','vadí mi roušky / respirátory','celkový pocit vyčerpání','mám deprese','cítím se pod tlakem','pocit ohrožení svobody','něco jiné','nic mě netrápí'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				mood_people: ['lidé jsou pozitivní', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'lidé jsou negativní', 'lidé jsou naštvaní'],
				mood_personal: ['mám se dobře', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'je to špatné'],
				sex: ['žena', 'muž', 'jiné', 'nechci uvést'],
				edu: ['základní/vyučený', 'maturita/voš', 'vysokoškolské'],
				age: ['do 30 let', '30-39 let', '40-49 let', '50-59 let', '60-69 let', '70 let a více'],
				region: this.$store.state.static.regions.concat('Žiju mimo Českou republiku')
			}
		}
	},
  components: {
		SimpleGraph
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			if (!this.leaders) return;

			return this.$store.getters.pdv('party/list');
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		leaders: function () {
			return this.$store.getters.pdv('candidate/list/andrej-babis,ivan-bartos,petr-fiala,tomio-okamura,jan-hamacek,vojtech-filip,robert-slachta,lubomir-volny,zuzana-majerova-zahradnikova');
		},
		results: function () {
			return this.$store.getters.pdv('research/report/' + this.monthData.id);
		},
		stats: function () {
			if (!this.results) return;

			var obj = {
				age: [],
				sex: [],
				region: [],
				education: []
			};

			this.results.report.stats.age.forEach(x => {
				obj.age.push({pct: this.pct(x), count: x});
			});

			this.results.report.stats.sex.forEach(x => {
				obj.sex.push({pct: this.pct(x), count: x});
			});

			this.results.report.stats.region.forEach(x => {
				obj.region.push({pct: this.pct(x), count: x});
			});

			this.results.report.stats.edu.forEach(x => {
				obj.education.push({pct: this.pct(x), count: x});
			});

			return obj;
		},
		monthData: function () {
			var obj = {
				id: 0,
				name: '',
				hash: this.month
			}

			if (obj.hash === 'duben') {
				obj.id = 4;
				obj.name = 'duben';
			}

			if (obj.hash === 'kveten') {
				obj.id = 5;
				obj.name = 'květen';
			}

			if (obj.hash === 'cerven') {
				obj.id = 6;
				obj.name = 'červen';
			}

			if (obj.hash === 'cervenec') {
				obj.id = 7;
				obj.name = 'červenec';
			}

			if (obj.hash === 'srpen') {
				obj.id = 8;
				obj.name = 'srpen';
			}

			if (obj.hash === 'zari') {
				obj.id = 9;
				obj.name = 'září';
			}

			if (obj.hash === 'rijen') {
				obj.id = 10;
				obj.name = 'říjen';
			}

			return obj;
		}
	},
  methods: {
		date,
		pct: function (count, base) {
			return Math.round(10000 * count / (base || this.results.report.count)) / 100;
		},
		leader: function (hash) {
			var l = ((this.leaders || {list: []}).list.find(x => x.hash === hash) || {name_family: 'Jiná odpověď'});

			if (l.name_first) {
				return l.name_first + ' ' + l.name_family;
			} else {
				return l.name_family;
			}
		},
		photo: function (hash) {
			var l = ((this.leaders || {list: []}).list.find(x => x.hash === hash) || {});

			return l.photo ? l.photo[0].link : undefined;
		},
		chapter: function (hash) {
			var name = {
				topic: '',
				short: '',
				value: ''
			}

			var topic = {
				election_participation: 'Půjdete volit?',
				election_decision: 'Už víte, koho budete volit?',
				// electionVote: [],
				election_anticipation: 'Volby vyhlížíte ...',
				program_importance: 'Jak důležitý je pro vás volební program?',
				news_watch: 'Jak často v poslední době hledáte informace o volbách?',
				polls_trust: 'Jak vnímáte předvolební průzkumy Kantaru, Medianu nebo CVVM?',
				gov_happiness: 'Jak jste spokojeni s činností vlády?',
				gov_expectation: 'Očekáváte, že vláda bude po volbách ...',
				gov_leader: 'Kdo se podle vás stane po volbách premiérem a získá důvěru Sněmovny?',
				election_topics: 'Co budou podle vás hlavní témata voleb?',
				personal_topics: 'Co je pro vás aktuálně největší téma?',
				mood_people: 'Jak vnímáte náladu lidí ve svém okolí?',
				mood_personal: 'Jak se vám daří osobně?',
				sex: 'Jste ...',
				edu: 'Vámi dosažené vzdělání?',
				age: 'Kolik je vám let?',
				region: 'V kterém kraji bydlíte?'
			};

			var short = {
				election_participation: 'Účast',
				election_decision: 'Rozhodnutí',
				// electionVote: [],
				election_anticipation: 'Očekávání',
				program_importance: 'Důležitost programu',
				news_watch: 'Sledování zpráv',
				polls_trust: 'Důvěra v průzkumy',
				gov_happiness: 'Spokojenost s vládou',
				gov_expectation: 'Vláda po volbách',
				gov_leader: 'Budoucí premiér',
				election_topics: 'Téma voleb',
				personal_topics: 'Osobní téma',
				mood_people: 'Nálada',
				mood_personal: 'Jak se máte?',
				sex: 'Pohlaví',
				edu: 'Vzdělání',
				age: 'Věk',
				region: 'Kraj'
			};

			var h = hash.split(':');

			name.topic = topic[h[0]];
			name.short = short[h[0]];

			if (h.length === 2) {
				if (this.questions[h[0]] && this.questions[h[0]][0].name) {
					name.value = (this.questions[h[0]].find(x => x.hash === h[1]) || {name: 'neznámé'}).name;
				} else if (typeof h[1] === 'string' && isNaN(Number(h[1]))) {
					name.value = this.leader(h[1]);
				} else {
					name.value = this.questions[h[0]][h[1]];
				}

				var n = name.value.split('');
				n[0] = n[0].toUpperCase();
				name.value = n.join('');
			}

			return name;
		},
		sortElectionVote: function (list) {
			var l = [];

			Object.keys(list).forEach(key => {
				l.push({hash: key, value: list[key]})
			})

			l.sort((a, b) => b.value - a.value);

			return l;
		},
		processData: function (data, base) {
			var list = [];

			data.forEach(value => list.push({
				value,
				pct: this.pct(value, base)
			}));

			return list;
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Výsledky za měsíc " + this.monthData.name});
  }
};
