import axios from 'axios';

export default {
	name: 'admin-comment',
	props: ['party', 'person', 'chunk', 'sid', 'data', 'headline'],
	data: function () {
		return {
			txt: '',
			src: '',
			alert: null,
			sending: false
		}
	},
	methods: {
		save: function () {
			this.sending = true;
			axios.post(this.$store.state.api + 'admin/comment-add', {
				key: this.sid,
				content: this.txt.split("'").join('&apos;').split('"').join('&quot;'),
				candidate: this.person,
				chunk: this.chunk,
				source: this.src,
				hash: this.party
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		}
	},
	mounted: function () {
		if (this.data && this.data[0]) {
			this.txt = this.data[0].content;
			this.src = this.data[0].source;
		}
	}
};
