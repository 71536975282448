import BubbleChart from '../../../types/line-chart.js'

export default {
  components: {
    BubbleChart
  },
  props: ['data', 'color'],
  data () {
    return {
      datacollection: null,
      height: 400
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      this.datacollection = {
        datasets: this.myDatasets
      }
    }
  },
  computed: {
    myDatasets () {
      var ds = [];

      this.data.forEach(item => {

        var obj = {
          label: item.about.short,
          data: [],
          borderColor: item.about.color || '#ddd',
          pointBackgroundColor: item.about.color || '#ccc',
          backgroundColor: 'transparent',
          lineTension: .001,
          borderWidth: 3,
          beginAtZero: true,
          radius: 2
        }

        item.data.forEach(d => {
          obj.data.push({
            x: new Date(d.date),
            y: d.value,
            r: 10
          })
        })

        ds.push(obj);
      });

      return ds;
    },
    myStyles () {
      return {
        height: `${this.height}px`,
        position: 'relative'
      }
    },
    myOptions () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        lineTension: 0,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'month',
              tooltipFormat: 'D. M. 20YY'
            },
            ticks: {
              min: '2021-04-25',
              max: '2021-10-05'
            }
          }],
          yAxes: [{
            beginAtZero: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10
            }
          }]
        }
      }
    }
  },
  watch: {
    data: function () {
      this.fillData();
    }
  }
}
