import {beautifyNumber, date} from "@/common/helpers";

export default {
	name: 'PopularitySummary',
	props: ['data', 'label'],
	methods: {
		beautifyNumber
	},
	computed: {
		lastUpdate: function () {
			var datePrint = '-';

			Object.keys(this.data.platform).forEach(key => {
				if (this.data.platform[key].length > 0) {
					datePrint = date(this.data.platform[key][0].date, true)
				}
			});

			return datePrint;
		}
	}
};
