import {date, betterURL} from '@/common/helpers';
import SimpleGraph from '@/layout/research/simple-graph/do';

export default {
	name: 'layout-research-rolling-results',
	data: function () {
		return {
			questions: {
				election_participation: ['Ano', 'Ještě nevím', 'Ne'],
				election_decision: ['Ano', 'Ne'],
				// electionVote: [],
				election_anticipation: ['s pocitem naděje', 'nevěřím, že se něco změní', 's obavami', 'potřebujeme změnu'],
				program_importance: ['velmi', 'docela jo', 'moc ne', 'vůbec'],
				news_watch: ['několikrát denně', 'každý den', 'občas', 'zřídka'],
				polls_trust: ['považuji je za důvěryhodné', 'sleduji spíš trendy', 'beru je jen orientačně', 'nevěřím jim', 'věřím jiným průzkumům', 'průzkumy nesleduji'],
				gov_happiness: ['velmi spokojený', 'spokojený', 'něco se povedlo, něco ne', 'nespokojený', 'velmi nespokojený'],
				gov_expectation: ['pokračovat v koalici ANO+ČSSD', 'v jiné podobě za účasti hnutí ANO', 'v úplně novém složení', 'úřednická'],
				// govLeader: null,
				election_topics: ['vystavení účtu vládě','koronavirus / očkování','zdravotnictví obecně','návrat do normálního života','obnova ekonomiky','využití dotací EU','konec uhlí','konec montoven','kvóty na potraviny','digitalizace','státní dluh','dividendy','daňové reformy','minimální mzda','zvýšení důchodů','legalizace drog','energetika','Dukovany','migrace a bezpečnost','manželství pro všechny','ekologie','EU/euro','něco jiné'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				personal_topics: ['mám zdravotní problémy','přišel jsem o práci','nemohu podnikat','mám finanční problémy','zavřené obchody a služby','nemohu dát děti do školy','nemohu studovat','nemohu cestovat','nemohu do hospody','chybí mi kultura','nemohu jet na chalupu','cítím napětí v rodině','cítím se osaměle','chybí mi kamarádi','vadí mi roušky / respirátory','celkový pocit vyčerpání','mám deprese','něco jiné'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				mood_people: ['lidé jsou pozitivní', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'lidé jsou negativní', 'lidé jsou naštvaní'],
				mood_personal: ['mám se dobře', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'je to špatné'],
				sex: ['žena', 'muž', 'jiné', 'nechci uvést'],
				edu: ['základní/vyučený', 'maturita/voš', 'vysokoškolské'],
				age: ['do 30 let', '30-39 let', '40-49 let', '50-59 let', '60-69 let', '70 let a více'],
				region: this.$store.state.static.regions.concat('Žiju mimo Českou republiku')
			},
			show: {
				type: 'total',
				index: -1,
				title: 'Všichni'
			}
		}
	},
  components: {
		SimpleGraph
	},
	computed: {
		parties: function () {
			return this.$store.getters.pdv('party/list');
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		leaders: function () {
			return this.$store.getters.pdv('candidate/list/andrej-babis,ivan-bartos,petr-fiala,tomio-okamura,jan-hamacek,vojtech-filip,robert-slachta,lubomir-volny,zuzana-majerova-zahradnikova');
		},
		results: function () {
			return this.$store.getters.pdv('research/fetch');
		},
		segments: function () {
			var list = [];

			list.push({type: 'total', index: -1, title: 'Všichni'});

			this.questions.sex.forEach((item, index) => {
				list.push({type: 'sex', index, title: 'Pohlaví: ' + item});
			});

			this.questions.edu.forEach((item, index) => {
				list.push({type: 'education', index, title: 'Vzdělání: ' + item});
			});

			this.questions.age.forEach((item, index) => {
				list.push({type: 'age', index, title: 'Věk: ' + item});
			});

			this.questions.region.forEach((item, index) => {
				list.push({type: 'region', index, title: 'Kraj: ' + item});
			});

			return list;
		},
		data: function () {
			if (!this.results) return;

			var results = {};

			if (!this.results.halted || this.results.halted === false) {
				Object.keys(this.results.results.results).forEach(key => {
					results[key] = null;

					if (this.show.type === 'total') {
						results[key] = this.results.results.results[key].total;
					} else {
						results[key] = this.results.results.results[key][this.show.type][this.show.index];
					}
				});
			}

			return results;
		},
		topicsPersonalResults: function () {
			if (!this.results) return;

			var data = [];
			var questions = [];

			Object.keys(this.data.personal_topic.options).forEach(key => {
				var q = this.questions.personal_topics.find(x => x.hash === key);

				if (q) {
					// questions.push(q.name);
					data.push({pct: this.data.personal_topic.options[key].pct, hash: key, q: q.name})
				}
			});

			data.sort((a, b) => a.q.localeCompare(b.q, 'cs'));

			data.forEach(x => questions.push(x.q));

			return {data, questions};
		},
		topicsElectionResults: function () {
			if (!this.results) return;

			var data = [];
			var questions = [];

			Object.keys(this.data.election_topic.options).forEach(key => {
				var q = this.questions.election_topics.find(x => x.hash === key);

				if (q) {
					// questions.push(q.name);
					data.push({pct: this.data.election_topic.options[key].pct, hash: key, q: q.name})
				}
			});

			data.sort((a, b) => a.q.localeCompare(b.q, 'cs'));

			data.forEach(x => questions.push(x.q));

			return {data, questions};
		},
		partiesResults: function () {
			if (!this.parties) return;
			if (!this.results) return;
			if (this.results.halted === true) return;

			var data = [];
			var questions = [];

			Object.keys(this.data.election_vote.options).forEach(key => {
				var q = this.partiesRunning.find(x => x.hash === key);

				if (q) {
					// questions.push(q.name);
					data.push({pct: this.data.election_vote.options[key].pct, hash: key, short: q.short, color: q.color, logo: q.logo})
				}
			});

			// data.sort((a, b) => a.short.localeCompare(b.short, 'cs'));
			data.sort((a, b) => b.pct - a.pct);

			data.forEach(x => questions.push(x.short));

			return {data, questions};
		}
	},
  methods: {
		date
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Průběžné výsledky ankety"});
  }
};
