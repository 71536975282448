import axios from 'axios';

export default {
	name: 'admin-minister-plan',
	props: ['sid', 'item', 'data', 'party'],
	data: function () {
		return {
			txt: '',
			src: '',
			alert: null,
			sending: false
		}
	},
	methods: {
		save: function () {
			this.sending = true;
			axios.post(this.$store.state.api + 'admin/minister-plan-add', {
				key: this.sid,
				content: this.txt.split("'").join('&apos;').split('"').join('&quot;'),
				source: this.src,
				person: this.item.person,
				ministry: this.item.ministry,
				mcn: this.item.mcn,
				hash: this.party
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.txt = this.data.content;
			this.src = this.data.source;
		}
	}
};
