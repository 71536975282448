import {betterURL, date} from '@/common/helpers';

export default {
	name: 'party-priority',
	props: ['hash'],
	data: function () {
		return {}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			return this.$(this.hash);
		},
		source: function () {
			return this.$store.getters.pdv('program/priority/' + this.hash);
		},
		priority: function () {
			if (!this.party || !this.party.program || !this.party.program.priority) return;

			return this.party.program.priority;
		}
	},
	methods: {
		betterURL, date,
		ga: function () {
			this.$store.dispatch("ga", {title: 'Priority: ' + this.party.name});
			setTimeout(() => {
				if (location.hash != '') {
					this.$el.querySelector("a[name=" + location.hash.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
				}
			}, 200);
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
