import axios from 'axios';
import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-guide-results',
	data: function () {
		return {
			about: this.$store.state.static.regionals
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			return this.$route.query.strany.split(',').sort((a, b) => a.localeCompare(b, 'cs'))
		},
		regionID: function () {
			return this.$store.state.static.regions.findIndex(x => betterURL(x) === this.$route.query.kraj);
		},
		regionName: function () {
			var name = this.$store.state.static.regions.find(x => betterURL(x) === this.$route.query.kraj);

			return name || 'Nezařazení kandidáti';
		},
		externals: function () {
			return this.$store.getters.pdv('party/externals/' + this.parties.join(','));
		},
		peopleData: function () {
			return this.$store.getters.pdv('region/list/' + this.$store.state.static.regions.findIndex(x => betterURL(x) === this.$route.query.kraj));
		},
		video: function () {
			return this.$store.getters.pdv('program/video');
		},
		people: function () {
			var list = [];

			if (this.peopleData) {
				this.$store.getters.parties().forEach(party => {
					if (this.parties.find(x => x === party.hash)) {
						var obj = {
							party,
							list: []
						};

						obj.list = this.peopleData.list.filter(x => x.party === party.hash);

						obj.list.sort((a, b) => (a.position || 99) - (b.position || 99));

						if (obj.list.length > 0) list.push(obj);
					}
				});
			}

			list.sort((a, b) => a.party.short.localeCompare(b.party.short, 'cs'));

			return list;
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => {
					if (this.$route.query.tema.split(',').find(x => x === tag.hash)) {
						var obj = {
								hash: tag.hash,
								name: tag.name,
								content: tag.content,
								data: this.$store.getters.pdv('program/tag/' + tag.hash)
						}

						list.push(obj);
					}
				});
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		},
		topics: function () {
			var list = [];
			var topics = this.$store.getters.pdv('calc/topics/' + this.$route.query.otazky);

			var tops = this.$route.query.otazky.split(',');
			var parties = this.parties;

			if (topics) {
				this.$store.state.static.questions.topics.forEach(t => {
					if (tops.find(x => x === t.topic)) {
						var obj = {
							name: t.name,
							topic: t.topic,
							answers: topics.list.filter(x => x.topic === t.topic && parties.find(y => y === x.hash)),
							questions: []
						}

						this.$store.state.static.questions.list.filter(x => x.topic === t.topic).forEach(q => {
							var qobj = {
								id: q.id,
								question: q.question,
								answers: obj.answers.filter(x => x.question === q.id)
							}

							obj.questions.push(qobj);

							parties.forEach(p => {
								if (!qobj.answers.find(x => x.hash === p)) {
									qobj.answers.push({
										hash: p,
										question: q.id,
										answer: 4,
										importance: 1
									});
								}
							});

							qobj.answers.sort((a, b) => a.hash.localeCompare(b.hash, 'cs'));
						});

						list.push(obj);
					}
				});
			}

			return list;
		},
		pop: function () {
			return this.$store.getters.getSource('volby/psp/2021/poll-of-polls')
		},
		ministers: function () {
			return this.$store.getters.pdv('ministers/all');
		},
		rozhlas: function () {
			var list = [];

			if (this.peopleData && this.people) {
				this.$store.getters.parties().forEach(party => {

					var p = this.people.find(x => x.party.hash === party.hash);

					if (p && party.status === 1) {
						var pos = 1;

						if (this.$store.state.static.rozhlas[(this.regionID + 1) + '_' + party.election_number]) {
								pos = this.$store.state.static.rozhlas[(this.regionID + 1) + '_' + party.election_number];
						}

						if (pos > 0) {
							var obj = {
								party,
								person: p.list.find(x => x.position === pos),
								label: null
							}

							obj.label = obj.person.name_first + ' ' + obj.person.name_family + ' (' + obj.party.short + ')'

							list.push(obj);
						}
					}
				});

				list.sort((a, b) => a.person.name_family.localeCompare(b.person.name_family, 'cs'));

			}

			return list;
		}
	},
  methods: {
		getChunkData: function (id, party) {
			var chunks = JSON.parse(this.$(party).program.program.chunks);

			var chunk = chunks.find(x => x.src === id);

			if (!chunk) // console.log(party);

			return {
				name: chunk.headline,
				src: chunk.src,
				hash: betterURL(chunk.headline)
			}
		},
		color: function (a, i) {
			if (a === 1 && i === 2) return '#009206';
			if (a === 1 && i === 1) return 'rgba(0, 146, 6, 0.5)';
			if (a === 3 && i === 2) return '#d72027';
			if (a === 3 && i === 1) return 'rgba(215, 32, 39, 0.5)';
			if (a === 2) return 'rgba(254, 204, 3, 0.4)';

			return '#eee'
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Průvodce volbami - výsledky"});

		axios.post(this.$store.state.api + 'votecalc/guide', {
			kraj: this.regionID,
			strany: this.$route.query.strany,
			tema: this.$route.query.tema,
			otazky: this.$route.query.otazky,
			form: this.$route.query.form
		})
  }
}
