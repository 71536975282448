import NewsItem from '@/components/news-feed/item/do';
import {date} from '@/common/helpers';

export default {
	name: 'archive',
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Archiv novinek"});
  },
	components: {
		NewsItem
	},
	computed: {
		news: function () {
			return this.$store.getters.pdv("news/all");
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		list: function () {
			var list = [
				{month: 'Říjen', id: "2021-10", news: []},
				{month: 'Září', id: "2021-09", news: []},
				{month: 'Srpen', id: "2021-08", news: []},
				{month: 'Červenec', id: "2021-07", news: []},
				{month: 'Červen', id: "2021-06", news: []},
				{month: 'Květen', id: "2021-05", news: []},
				{month: 'Duben', id: "2021-04", news: []},
				{month: 'Březen', id: "2021-03", news: []},
				{month: 'Únor', id: "2021-02", news: []},
				{month: 'Leden 2021', id: "2021-01", news: []},
				{month: 'Říjen-Prosinec 2020', id: "2020", news: []}
			];

			if (this.news && this.parties) {
				this.news.list.forEach(item => {
					var obj = {
						datesort: item.date,
						date: date(item.date),
						content: item.content,
						source: item.source,
						label: item.source.split('/')[2].split('.'),
						logo: item.logo || '/static/icon/logo-programy.svg',
						link: item.link,
						hash: '/strana/' + item.hash,
						title: item.title || 'Programy do voleb',
						color: '#aaa',
						embed: item.embed
					}

					obj.label = obj.label[obj.label.length - 2].toUpperCase();

					// if ((!this.hash && item.hash) || this.hash) {
						obj.hash = '/strana/' + (item.hash || this.hash);

						var party = this.parties.list.find(x => x.hash === item.hash);

						if (party) {
							if (!item.logo) obj.logo = this.$store.state.server + party.logo;
							if (!item.title) obj.title = party.name;
							obj.color = party.color;
						}
					// }

					var whereTo = undefined;

					if (item.date.charAt(3) === '0') {
						whereTo = list.find(x => x.id === "2020");
					} else {
						var s = item.date.substring(0, 7);
						whereTo = list.find(x => x.id === s);
					}
					if (whereTo) {
						whereTo.news.push(obj);
					}
				});
			}

			list.forEach(m => m.news.sort((a, b) => b.datesort.localeCompare(a.datesort, 'cs')));

			return list;
		}
	}
};
