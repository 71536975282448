export default {
	name: 'layout-changes',
	props: ['id'],
	data: function () {
		return {
			hide: []
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		answers: function () {

			var list = [];

			this.parties.forEach(party => {
				if (party.interestCount > 0) list.push(party.hash);
			});

			return this.$store.getters.pdv('calc/fetch/' + list.join(','));
		},
		parties: function () {
			var list = [];

			this.$store.getters.parties().forEach(party => {
				if (party.interestCount > 0) list.push(party);
			});

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		},
		partiesAll: function () {
			var list = [];

			this.$store.getters.parties().filter(x => x.status === 1).forEach(x => list.push(x));

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		},
		question: function () {
			return this.$store.state.static.questions.list.find(x => x.id === this.id);
		}
	},
  methods: {
		filterToggle: function (party) {
			if (this.hide.find(x => x === party)) {
				this.hide.splice(this.hide.findIndex(x => x === party), 1);
			} else {
				this.hide.push(party);
			}
		},
		color: function (a, i) {
			if (a === 1 && i === 2) return '#009206';
			if (a === 1 && i === 1) return 'rgba(0, 146, 6, 0.5)';
			if (a === 3 && i === 2) return '#d72027';
			if (a === 3 && i === 1) return 'rgba(215, 32, 39, 0.5)';
			if (a === 2) return 'rgba(254, 204, 3, 0.4)';

			return '#eee'
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Porovnání odpovědí na otázku " + this.id});
  }
};
