import axios from 'axios';

export default {
	name: 'admin-interest-answer',
	props: ['data', 'hash', 'sid', 'topic', 'question', 'compact', 'showQuestion'],
	data: function () {
		return {
			answer: 0,
			storedAnswer: 0,
			importance: 1,
			comment: '',
			sending: false,
			sent: false
		}
	},
	components: {

	},
	computed: {
		type: function () {
			return this.$store.getters.party(this.hash) ? 1 : 2
		},
		questionFull: function () {
			return this.$store.state.static.questions.list.find(x => x.id === this.question).question
		},
		answerCopy: function () {
			if (this.storedAnswer === 0) return 'Bez odpovědi';
			if (this.storedAnswer === 1) return 'Ano';
			if (this.storedAnswer === 2) return 'Nelze jasně odpovědět';
			if (this.storedAnswer === 3) return 'Ne';
			if (this.storedAnswer === 4) return 'Nechci odpovědět';
		}
	},
	methods: {
		updated: function () {
			this.sent = true;

			setTimeout(() => this.sent = false, 2000);
		},
		update: function () {
			if (this.sending) return;

			this.sending = true;

			axios.post(this.$store.state.api + 'admin/interest-answer-update', {
				key: this.sid,
				hash: this.hash,
				type: this.type,
				topic: this.topic,
				question: this.question,
				answer: this.answer,
				importance: 1,
				comment: this.comment.split('"').join('“')
			}).then((response) => {
				if (response.data.code === 200) {
					this.updated();
					this.sending = false;
					this.storedAnswer = this.answer;
				} else {
					this.sending = false;
				}
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.answer = this.data.answer;
			this.storedAnswer = this.data.answer;
			this.importance = this.data.importance;
			this.comment = this.data.comment;
		}
	}
};
