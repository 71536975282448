import AdminLinks from '../../links/do';
import AdminContact from '../../contacts/do';
import AdminCore from '../../party-core/do';
import AdminFiles from '../../files/do';
import AdminMotto from '../../motto/do';
import AdminGarantList from '../../garant-list/do';
import AdminMinisterList from '../../minister-list/do';
import AdminMyths from '../../myths/do';
import AdminVideo from '../../video/do';
import AdminCalc from '../../party-calc/do';

export default {
	name: 'admin-party',
	props: ['item', 'sid', 'data'],
	components: {
		AdminLinks,
		AdminContact,
		AdminCore,
		AdminFiles,
		AdminMotto,
		AdminGarantList,
		AdminMinisterList,
		AdminMyths,
		AdminCalc,
		AdminVideo
	}
};
