export default {
	name: 'layout-party-list',
	props: ['hash'],
	data: function () {
		return {}
	},
  components: {},
	computed: {
		parties: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/seznam/strany');
		},
		sorted: function () {
			return (this.parties || []).sort((a, b) => a.name.localeCompare(b.name, 'cs'))
		}
	},
  methods: {
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('youtube').length > 1) return 'yt';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: "Přehled stran"});
  }
};
