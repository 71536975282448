export default {
  source: '',
  list: [
    {
      name: "Parkoviště u Městské knihovny",
      address: "Labská, Děčín",
      designated: "Děčín",
      maps: {
        "link": 'befacufero',
        "embed": 'befacufero'
      }
    },
    {
      name: "Parkoviště u zimního stadionu",
      address: "Mostecká, Chomutov",
      designated: "Chomutov",
      maps: {
        "link": 'cetuzedame',
        "embed": 'cetuzedame'
      }
    },
    {
      name: "Prostory Zahrady Čech",
      address: "Českolipská, Litoměřice",
      designated: "Litoměřice",
      maps: {
        "link": 'cokofekube',
        "embed": 'cokofekube'
      }
    },
    {
      name: "Komunikace na výstavišti v Lounech",
      address: "Masarykovy sady, Louny",
      designated: "Louny",
      maps: {
        "link": 'ketohoguzu',
        "embed": 'ketohoguzu'
      }
    },
    {
      name: "Parkoviště u Zimního stadionu",
      address: "Rudolická, Most",
      designated: "Most",
      maps: {
        "link": 'norenovena',
        "embed": 'norenovena'
      }
    },
    {
      name: "Prostor u fotbalového stadionu FK Teplice, tzv. starých pokladen",
      address: "Na Stínadlech 2796, Teplice",
      designated: "Teplice",
      maps: {
        "link": 'jutabevesu',
        "embed": 'jutabevesu'
      }
    },
    {
      name: "Parkoviště u zimního a fotbalového stadionu",
      address: "Masarykova 974/232, Ústí nad Labem - Klíše",
      designated: "Ústí nad Labem",
      maps: {
        "link": 'cucojapoge',
        "embed": 'cucojapoge'
      }
    }
  ]
}
