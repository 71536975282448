import {beautifyNumber, betterURL, date, getLinkType} from "@/common/helpers";

export default {
	name: 'party-answers',
	props: ['hash'],
	data: function () {
		return {}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			return this.$(this.hash);
		},
		answers: function () {
			var list = [];

			var data = this.$store.getters.pdv('party/interest/' + this.hash);

			if (data) list = data.list;

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		getLinkType,
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: 'Odpovědi ' + self.party.name});
				}
			}, 100);
		},
		content: function (question) {
			var obj = {
				answer: 0,
				importance: 2,
				comment: null,
				check: null
			}

			var answer = this.answers.find(x => x.question === question);

			if (answer) {
				obj.answer = answer.answer;
				obj.importance = answer.importance === 1 ? 2 : 1;
				obj.comment = answer.comment;
			}

			return obj;
		},
		unanswered: function (topic) {
			var list = this.answers.filter(x => x.topic === topic.topic);
			var questions = this.$store.state.static.questions.list.filter(x => x.topic === topic.topic)



			return questions.length - list.length;
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
