import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'admin-person-add',
	props: ['hash', 'region'],
	data: function () {
		return {
			values: [],
			district: null,
			town: null,
			alert: null,
			sending: false
		}
	},
	methods: {
		save: function () {

			var data = {
				name: this.values.find(x => x.id === 'name_first').content,
				family: this.values.find(x => x.id === 'name_family').content,
				nominee: this.values.find(x => x.id === 'nominee').content,
				member: this.values.find(x => x.id === 'member').content,
				sex: this.values.find(x => x.id === 'sex').content,
				party: this.hash
			}

			data.hash = betterURL(data.name + '-' + data.family);

			if (data.name.trim() === '' || data.family.trim() === '' || data.nominee === null) return;

			this.sending = true;
			axios.post(this.$store.state.api + 'admin/person-garant-add', {
				key: this.sid,
				token: this.$route.params.token,
				values: data
			}).then((response) => {
				this.alert = "Přidáno";
				this.sending = false;
				this.values.find(x => x.id === 'name_first').content = '';
				this.values.find(x => x.id === 'name_family').content = '';
				setTimeout(() => this.alert = null, 2500);
				this.$emit('update', response.data.values)
			})
		},
		setValue: function (source, destination) {
			destination.contentNum = source.id;
			destination.content = source.name;
		}
	},
	computed: {
		parties: function () {
			var p = this.$store.getters.parties();
			var list = [];

			list.push({
				reg: null,
				extra: false,
				name: 'nevyplňeno'
			})

			list.push({
				reg: 99,
				extra: true,
				name: 'bez politické příslušnosti'
			})

			list.push({
				reg: null,
				name: '---------',
				extra: true
			})

			if (p) p.forEach(i => {
				if (i.reg && ((i.hash === this.hash && !i.coalition) || i.coalition === this.hash || i.support === this.hash)) {
					list.push({
						reg: i.reg,
						name: i.name,
						extra: false
					})
				}
			})

			// list = list.filter(x => x.reg);
			// list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

			list.push({
				reg: null,
				name: '---------',
				extra: true
			})

			if (p) p.forEach(i => {
				if (i.reg && (i.coalition != this.hash && i.support != this.hash)) {
					list.push({
						reg: i.reg,
						name: i.name,
						extra: true
					})
				}
			})

			list.push({
				reg: null,
				name: '---------',
				extra: true
			})
			list.push({
				reg: -1,
				extra: true,
				name: 'strana v seznamu chybí'
			})

			return list;
		}
	},
	mounted: function () {

		var pt = this.$store.getters.party(this.hash);

		if (pt.status === 2) pt = this.$store.getters.party(pt.coalition);

		this.values.push({
			label: 'Strana',
			id: 'hash',
			content: pt.name,
			hash: pt.hash,
			type: 'text',
			empty: false,
			editable: false
		})
		this.values.push({
			label: 'Jméno',
			id: 'name_first',
			original: '',
			content: '',
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Příjmení',
			id: 'name_family',
			original: '',
			content: '',
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Nominace',
			id: 'nominee',
			original: null,
			content: this.$store.getters.party(this.hash).reg,
			type: 'selectParty',
			options: this.parties,
			extra: false,
			empty: true,
			editable: !this.$store.getters.party(this.hash).reg
		})
		this.values.push({
			label: 'Členství',
			id: 'member',
			original: null,
			content: this.$store.getters.party(this.hash).reg,
			type: 'selectParty',
			options: this.parties,
			extra: true,
			empty: true,
			editable: !this.$store.getters.party(this.hash).reg
		})
		this.values.push({
			label: 'Pohlaví',
			id: 'sex',
			content: 1,
			options: [{id: 1, name: 'muž'}, {id: 2, name: 'žena'}],
			type: 'select',
			empty: true,
			editable: true
		})
	}
};
