import store from '@/store/store';

const getters = {
  vuexState: state => state
};

getters.parties = (state) => () => {
  var data = state.dynamic.pdv.find(x => x.request === 'party/list');

  if (data) {

    // data.content.list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

    return data.content.list;
  } else {
    return undefined;
  }
}

getters.party = (state) => (id) => {
  var lookup = {};

  var parties = state.dynamic.pdv.find(x => x.request === 'party/list')

  if (parties) {
    if (typeof id === 'string') {
      lookup = parties.content.list.find(x => x.hash === id);
    }
    if (typeof id === 'number') {
      lookup = parties.content.list.find(x => x.reg === id);
    }
  }

  return lookup;
}

getters.getSource = (state) => (source) => {
  var lookup = state.dynamic.source.find(s => s.source === source);

  if (lookup) {
    return lookup.content;
  } else {
    new Promise((resolve, reject) => {
      store.dispatch('fetchSource', {
        source: source,
        onComplete: () => resolve(),
        onError: () => reject(new Error('load fail'))
      });
    }).then((resolver, rejected) => {
      if (rejected) {
        return undefined;
      } else {
        lookup = state.dynamic.source.find(s => s.source === source);
        return lookup.content;
      }
    });
  }
}

var used = [];

getters.pdv = (state) => (request) => {

  var lookup = state.dynamic.pdv.find(s => s.request === request);

  if (used.indexOf(request) > -1 && !lookup) {
    return null;
  } else {
    used.push(request);

    if (lookup) {
      return lookup.content;
    } else {
      new Promise((resolve, reject) => {
        store.dispatch('pdv', {
          request: request,
          onComplete: () => resolve(),
          onError: () => reject(new Error('load fail'))
        });
      }).then((resolver, rejected) => {
        if (rejected) {
          return undefined;
        } else {
          lookup = state.dynamic.pdv.find(s => s.request === request);
          return lookup.content;
        }
      });
    }
  }
}

export default getters;
