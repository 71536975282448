import AdminLinks from '../../links/do';
import AdminContact from '../../contacts/do';
import AdminCore from '../../person-core/do';
import AdminCand from '../../person-cand/do';
import AdminAbout from '../../about/do';
import AdminPhoto from '../../photo/do';
import AdminMotto from '../../motto/do';
import AdminComment from '../../comment/do';
import AdminPlan from '../../minister-plan/do';
import AdminInterest from '../../interest-selection/do';

export default {
	name: 'admin-person',
	props: ['item', 'sid', 'data'],
	components: {
		AdminLinks,
		AdminContact,
		AdminCore,
		AdminCand,
		AdminAbout,
		AdminPhoto,
		AdminMotto,
		AdminComment,
		AdminPlan,
		AdminInterest
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		topics: function () {
			return this.$store.getters.pdv('admin/interest-list');
		},
		leader_comment: function () {
			var data = [];

			if (this.data.comment) {
				this.data.comment.forEach(com => {
					if (!com.chunk) {
						data.push(com);
					}
				});
			}

			return data;
		},
		chunks: function () {
			var data = [];

			if (this.data.program && this.data.program.program) {
				data = JSON.parse(this.data.program.program.chunks);
			}

			return data;
		},
		garants: function () {
			var data = [];

			if (this.chunks.length > 0 && this.data.garant && this.data.garant.length > 0) {
				this.data.garant.forEach(garant => {
					var obj = {
						garant,
						chunk: this.chunks.find(x => x.src === garant.chunk),
						data: []
					}

					var comment = (this.data.comment || []).find(x => x.candidate === garant.garant && x.chunk === garant.chunk)

					if (comment) obj.data.push(comment);

					data.push(obj);
				})
			}

			return data;
		}
	}
};
