import {elections, attendance, votes, chairs, results2017, scrutinize, turnout} from '@/helpers/votes-2021';

export default {
	name: 'election-simulation-21',
	props: ['defined'],
	data: function () {
		return {
			selectedElectionID: 0,
			selectedOverseasID: 1,
			attendanceCustom: [],
			attendanceCommon: 60,
			distribution: 'weighted',
			results: [],
			defaults: [],
			remaining: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			tick: 0,
			sourceSelectedID: 12,
			coalitions: [],
			definedTest: {
				data: {
					// attendance: 40,
					parties: [
						{hash: 'ano-2011', rs: 25.5},
						{hash: 'ceska-strana-socialne-demokraticka', rs: 5.6},
						{hash: 'komunisticka-strana-cech-a-moravy', rs: 4.1},
						{hash: 'svoboda-a-prima-demokracie---spd', rs: 9},
						{hash: 'pirati-a-starostove', rs: 19.3, coalition: ['ceska-piratska-strana', 'starostove-a-nezavisli']},
						{hash: 'spolu-ods-kducsl-top09', rs: 20.3, coalition: ['obcanska-demokraticka-strana', 'kdu-csl', 'top-09']},
						{hash: 'trikolora-hnuti-obcanu', rs: 3.9}
					]
				},
				run: {
					distribution: 'weighted'
				}
			}
		}
	},
	components: {},
	computed: {
		polls: function () {
			return this.$store.getters.pdv('polls/last-10')
		},
		turnout: function () {
			return turnout(this.attendanceCustom, undefined, this.tick, this.selectedOverseasID)
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		electionList: function () {
			return elections;
		},
		selectedElectionName: function () {
			return elections[this.selectedElectionID]
		},
		selectedVotesData: function () {
			return votes(this.selectedElectionID, this.attendanceCustom, this.tick, this.selectedOverseasID);
		},
		selectedChairsData: function () {
			var data = this.selectedVotesData;

			return chairs(data.votes, data.perMandate, this.tick);
		},
		sum: function () {
			var s = 0;

			this.selectedChairsData.forEach(c => {
				s += c.chairs + c.extra;
			});

			return s;
		},
		scrutinize: function () {
			return scrutinize(this.selectedChairsData, this.results, this.tick);
		},
		over2: function () {
			return this.coalitions.reduce((a, b) => a + (b.selected ? 1 : 0), 0) > 1
		},
		coalitionSum: function () {
			return this.coalitions.reduce((a, b) => a + (b.selected ? b.value : 0), 0)
		}
	},
	methods: {
		recalc: function () {
			this.results.forEach(party => {
				party.sum = 0;

				party.regpct.forEach((pct, i) => {
					party.votes[i] = Math.round(this.turnout.voters[i] * pct / 100);
					party.sum += party.votes[i];
				});

				party.pct = Math.round(party.sum / this.turnout.sum * 10000) / 100;
			});

			this.attendanceCustom.forEach((a, i) => {

				var regionVotes = 0;

				this.results.forEach(party => {
					regionVotes += party.votes[i];
				});

				this.remaining[i] = this.turnout.voters[i] - regionVotes;
			});

			while (this.coalitions.length > 0) this.coalitions.pop();

			this.scrutinize.parties.forEach(party => {
				if (party.sum > 0) {
					this.coalitions.push({
						hash: party.hash,
						value: party.sum,
						selected: false
					})
				}
			});

			this.coalitions.sort((a, b) => b.value - a.value);

			this.tick++;
		},
		$: function () {
			return this.$store.getters.party;
		},
		setDefaultAttendance: function () {
			while (this.attendanceCustom.length > 0) this.attendanceCustom.pop();

			attendance.forEach(a => {
				this.attendanceCustom.push(a);
			})
		},
		setDefaultResults: function () {
			results2017.forEach(r => {
				this.results.push(r);
				this.defaults.push(r);
			})
		},
		setAttendanceToCommon: function () {
			this.remaining.forEach((r, i) => {
				this.attendanceCustom[i] = this.attendanceCommon;
			});

			this.tick++;
			this.recalc();
		},
		redistributeBasic: function () {
			this.distribution = 'basic';

			this.results.forEach(party => {
				party.regpct.forEach((r, i) => party.regpct[i] = party.rs);
			});

			this.recalc();
		},
		getRedistributionCoef: function (hash, coalition) {
			var res = results2017.find(x => x.hash === hash);

			if (!res) {
				if (coalition) {
					res = {
						coef: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
					}
					coalition.forEach(hash => {
						var member = results2017.find(x => x.hash === hash);

						if (member) {
							member.coef.forEach((v, i) => {
								res.coef[i] += v;
							});
						}
					})

					res.coef.forEach((x, i) => res.coef[i] = res.coef[i] / coalition.length);
				}
			}

			if (!res) {
				res = {
					coef: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
				}
			}

			return res.coef;
		},
		redistributeWeighted: function () {
			this.distribution = 'weighted';

			this.results.forEach(party => {

				var coefs = this.getRedistributionCoef(party.hash, party.coalition);

				coefs.forEach((coef, i) => {
					party.regpct[i] = party.rs * coef;
				})
			});

			this.recalc();
		},
		redistribute: function () {
			if (this.distribution === 'weighted') {
				this.redistributeWeighted();
			}
			if (this.distribution === 'basic') {
				this.redistributeBasic();
			}
		},
		redistributeWithDelay: function () {
			this.results.forEach(party => {
				party.pct = party.rs;
			});
			this.redistribute();
		},
		parseSource: function (src) {
			if (src.data) {

				// attendance

				if (src.data.attendanceCustom) {
					this.selectedElectionID = 4;
					this.attendanceCustom = src.data.attendanceCustom;
				} else if (src.data.attendance) {
					this.selectedElectionID = 4;
					this.attendanceCommon = src.data.attendance;
					this.setAttendanceToCommon();
				} else {
					this.setDefaultAttendance();
				}

				// parties

				if (src.data.parties) {
					src.data.parties.forEach(party => {
						var obj = {
							hash: party.hash,
							pct: party.rs,
							rs: party.rs,
							requires: party.coalition ? party.coalition.length * 5 : 5,
							regpct: party.regpct || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							votes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							coef: party.coef || this.getRedistributionCoef(party.hash, party.coalition),
							sum: 0,
							coalition: party.coalition
						}

						this.results.push(obj);
					});
				} else {
					this.setDefaultResults();
				}

			}

			if (src.run) {
				if (src.run.attendance) {
					if (src.run.attendance === 'common') {
						this.setAttendanceToCommon();
					}
					if (src.run.attendance === 'legacy') {
						this.setDefaultAttendance();
					}
				}

				if (src.run.distribution) {
					this.distribution = src.run.distribution;
				}
			}

			this.redistribute();
		},
		setSource: function () {
			while (this.results.length > 0) this.results.pop();

			if (this.sourceSelectedID === 0) {
				this.parseSource(this.defined);
				this.setDefaultAttendance();
			} else if (this.sourceSelectedID === 11) {
				this.parseSource(this.definedTest);
			} else if (this.sourceSelectedID === 12) {
				this.setDefaultResults();
				this.setDefaultAttendance();
			} else {
				var obj = {
					data: {
						parties: []
					},
					run: {
						distribution: 'weighted'
					}
				}

				var poll = this.polls.list[this.sourceSelectedID - 1];

				Object.keys(poll).forEach(key => {
					if (['agency', 'date', 'link', 'type', 'error', 'collect_from', 'collect_to'].indexOf(key) === -1 && poll[key]) {
						if (key === 'spolu-ods-kducsl-top09') {
							obj.data.parties.push({hash: key, rs: poll[key], coalition: ['obcanska-demokraticka-strana', 'kdu-csl', 'top-09']})
						} else if (key === 'pirati-a-starostove') {
							obj.data.parties.push({hash: key, rs: poll[key], coalition: ['ceska-piratska-strana', 'starostove-a-nezavisli']})
						} else {
							obj.data.parties.push({hash: key, rs: poll[key]});
						}
					}
				});

				this.parseSource(obj);
			}

			this.tick++;
			this.recalc();
		}
	},
	mounted: function () {
		this.setSource();

		this.tick++;
		this.recalc();
	}
};
