export default {
  source: 'https://t.co/DtmS4wDMfj?amp=1',
  list: [
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "České Budějovice, Nemanická 2133/10",
      designated: "České Budějovice",
      maps: {
        "link": 'jacafecove',
        "embed": 'jacafecove'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Český Krumlov, Domoradice 127",
      designated: "Český Krumlov",
      maps: {
        "link": 'hepemedenu',
        "embed": 'hepemedenu'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Jindřichův Hradec, Jarošovská 1126",
      designated: "Jindřichův Hradec",
      maps: {
        "link": 'pofahukede',
        "embed": 'pofahukede'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Písek, Vrcovická 2148",
      designated: "Písek",
      maps: {
        "link": 'kuvupekeru',
        "embed": 'kuvupekeru'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Prachatice, Žernovická 916",
      designated: "Prachatice",
      maps: {
        "link": 'mevuzefeno',
        "embed": 'mevuzefeno'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Strakonice, U Řepické zastávky 1294",
      designated: "Strakonice",
      maps: {
        "link": 'nefazuhuce',
        "embed": 'nefazuhuce'
      }
    },
    {
      name: "Areál Správy a údržby silnic Jihočeského kraje",
      address: "Tábor, Vožická 2107",
      designated: "Tábor",
      maps: {
        "link": 'gumodevujo',
        "embed": 'gumodevujo'
      }
    }
  ]
}
