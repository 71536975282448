import Vue from 'vue';

import CandidateStats from './candidate-stats/do';
import CandidateStatsInline from './candidate-stats-inline/do';
import InterestAnswer from './interest-answer/do';
import PartySummary from './party-summary/do';
import PartyLineSummary from './party-line-summary/do';
import PersonSummary from './person-summary/do';
import PersonSummaryQuote from './person-summary-quote/do';
import PersonLineSummary from './person-line-summary/do';
import PersonCompactSummary from './person-compact-summary/do';
import PersonTinySummary from './person-tiny-summary/do';
import PersonTableSummary from './person-table-summary/do';
import PopularitySummary from './popularity-summary/do';
import PollsEntry from './polls-entry/do';
import BudgetEntry from './budget-entry/do';
import ProgramBlock from './program-block/do';
import ProgramBlockDynamic from './program-block-dynamic/do';
import ProgramComment from './program-comment/do';
import ReportBlock from './report-block/do';
import TopCandidates from './top-candidates/do';
import TruncatedText from './truncated-text/do';
import PartyFilter from './party-filter/do';
import RozhlasBlock from './rozhlas-block/do';
import ScrollableTable from './scrollable-table/do';

Vue.component('candidate-stats', CandidateStats);
Vue.component('candidate-stats-inline', CandidateStatsInline);
Vue.component('interest-answer', InterestAnswer);
Vue.component('party-summary', PartySummary);
Vue.component('party-line-summary', PartyLineSummary);
Vue.component('person-summary', PersonSummary);
Vue.component('person-summary-quote', PersonSummaryQuote);
Vue.component('person-line-summary', PersonLineSummary);
Vue.component('person-compact-summary', PersonCompactSummary);
Vue.component('person-tiny-summary', PersonTinySummary);
Vue.component('person-table-summary', PersonTableSummary);
Vue.component('popularity-summary', PopularitySummary);
Vue.component('polls-entry', PollsEntry);
Vue.component('budget-entry', BudgetEntry);
Vue.component('program-block', ProgramBlock);
Vue.component('program-block-dynamic', ProgramBlockDynamic);
Vue.component('program-comment', ProgramComment);
Vue.component('report-block', ReportBlock);
Vue.component('top-candidates', TopCandidates);
Vue.component('truncated-text', TruncatedText);
Vue.component('party-filter', PartyFilter);
Vue.component('rozhlas-block', RozhlasBlock);
Vue.component('scrollable-table', ScrollableTable);
