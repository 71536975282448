import {betterURL} from '@/common/helpers';

export default {
	name: 'BlockExpandable',
	props: ['level', 'headline', 'anchor', 'alert', 'subheadline', 'label', 'open'],
	data: function () {
		return {
			opened: false
		}
	},
	mounted: function () {
		if (this.open) this.opened = this.open;
	},
	computed: {
		hash: function () {
			return this.anchor || betterURL(this.headline)
		}
	}
};
