export default {
	name: 'interest-answer',
	props: ['answer', 'question', 'topic', 'importance', 'title', 'image', 'comment', 'color', 'highlight'],
	computed: {
		answerCopy: function () {
			if (this.answer === 0) return 'Bez odpovědi';
			if (this.answer === 1) return 'Ano';
			if (this.answer === 2) return 'Nelze jednoznačně odpovědět';
			if (this.answer === 3) return 'Ne';
			if (this.answer === 4) return 'Bez odpovědi';
		}
	},
	methods: {
		c: function (a, i) {
			if (a === 1 && i === 2) return '#009206';
			if (a === 1 && i === 1) return 'rgba(0, 146, 6, 0.5)';
			if (a === 3 && i === 2) return '#d72027';
			if (a === 3 && i === 1) return 'rgba(215, 32, 39, 0.5)';
			if (a === 2) return 'rgba(254, 204, 3, 0.4)';

			return '#eee'
		}
	}

};
