import axios from 'axios';
import ImageUpload from '../../image-upload/do';

export default {
	name: 'admin-files',
	props: ['hash', 'sid', 'data', 'region'],
	data: function () {
		return {
			list: [],
			nove: {
				name: '',
				source: '',
				icon: 'pdf',
				preview: ''
			}
		}
	},
	methods: {
		update: function (data) {
			this.nove.preview = data.link;
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/file-remove', {
				key: this.sid,
				source: src.source,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.source === src.source).removed = true;
				}
			})
		},
		add: function () {
			if (this.nove.name.trim() === '' || this.nove.source.trim() === '') return;
			axios.post(this.$store.state.api + 'admin/file-add', {
				key: this.sid,
				data: this.nove,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						source: response.data.source,
						icon: response.data.icon,
						name: response.data.name,
						preview: response.data.preview,
						removed: false
					})

					this.nove.name = '';
					this.nove.source = '';
					this.nove.icon = 'pdf';
					this.nove.preview = '';
				}
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					source: src.source,
					icon: src.icon,
					name: src.name,
					preview: src.preview,
					removed: false
				});
			})
		} else {
			this.list = []
		}
	},
	components: {
		ImageUpload
	}
};
