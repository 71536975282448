import store from '@/store/store';

var months = ['ledna', 'února', 'března', 'dubna', 'května', 'června', 'července', 'srpna', 'září', 'října', 'listopadu', 'prosince'];

export function date (input, nice) {
  var x = input.split(' ')[0].split('-');

  var d = [Number(x[2]) + '.'];

  if (nice) {
    d.push(months[Number(x[1]) - 1]);
  } else {
    d.push(Number(x[1]) + '.')
  }

  if (Number(x[0]) != 2021) {
    d[1] = Number(x[1]) + '.';
    d.push(x[0]);
  }

  return d.join(' ');
}

export function PDV (url) {
  return 'https://data.programydovoleb.cz/' + url.split('data/')[1];
}

export function stripURLintoDomain (url) {
  url = url.replace('https://', '');
  url = url.replace('http://', '');
  url = url.replace('www.', '');

  if (url.charAt(url.length - 1) === '/') url = url.slice(0, -1); // '12345.0'

  return url;
}

export function truncate (str, count, chars) {

  if (!str) return undefined;

  var limit = count || 20;
  var words = str.split(chars ? '' : ' ');
  var add = words.length > limit ? '...' : '';

  return words.splice(0, limit).join(chars ? '' : ' ') + add;
}

/* eslint-disable no-extend-native */
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};

export function beautifyNumber (num) {
  var str = num.toString().split('');

  if (str.length > 6) {
    str.splice(str.length - 3, 0, ' ');
    str.splice(str.length - 7, 0, ' ');
  } else if (str.length > 3) {
    str.splice(str.length - 3, 0, ' ');
  }

  return str.join('');
}

export function beautifyDate (date) {
  var day = date % 100;
  var month = Math.floor(date / 100) % 100;
  var year = Math.floor(date / 10000);

  return day + '. ' + month + '. ' + year;
}

export function betterURL (url) {
  var newURL = url;

  newURL = newURL.toLowerCase();
  newURL = newURL.replaceAll('.', '');
  newURL = newURL.replaceAll(',', '');
  newURL = newURL.replaceAll(';', '');
  newURL = newURL.replaceAll('?', '');
  newURL = newURL.replaceAll('!', '');
  newURL = newURL.replaceAll('(', '');
  newURL = newURL.replaceAll(')', '');
  newURL = newURL.replaceAll('á', 'a');
  newURL = newURL.replaceAll('č', 'c');
  newURL = newURL.replaceAll('ď', 'd');
  newURL = newURL.replaceAll('é', 'e');
  newURL = newURL.replaceAll('ě', 'e');
  newURL = newURL.replaceAll('í', 'i');
  newURL = newURL.replaceAll('ľ', 'l');
  newURL = newURL.replaceAll('ň', 'n');
  newURL = newURL.replaceAll('ó', 'o');
  newURL = newURL.replaceAll('ř', 'r');
  newURL = newURL.replaceAll('š', 's');
  newURL = newURL.replaceAll('ť', 't');
  newURL = newURL.replaceAll('ú', 'u');
  newURL = newURL.replaceAll('ů', 'u');
  newURL = newURL.replaceAll('ý', 'y');
  newURL = newURL.replaceAll('ž', 'z');
  newURL = newURL.replaceAll(' ', '-');

  var c = [];

  newURL.split('').forEach(x => {
    if ([780, 769, 778, 770, 776].indexOf(x.charCodeAt(0)) === -1) {
      c.push(x);
    }
  });

  var s = c.join('');

  return s;
}

String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length === 0) {
    return hash;
  }
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function toColor (num) {
  num >>>= 0;
  var b = num & 0xFF;
  var g = (num & 0xFF00) >>> 8;
  var r = (num & 0xFF0000) >>> 16;
  return 'rgb(' + [r, g, b].join(',') + ')';
}

export function createColorByName (name) {
  return toColor(name.hashCode());
}

export function gradient (party, getters) {
  if (party.color === '#aaa' && party.coalition) {
    var arr = [];
    var clr = [];

    if (!getters) getters = store.getters;

    party.coalition.forEach(reg => {
      arr.push(getters.getPartyByReg(reg).color);
    });

    arr.forEach((a, i) => {
      clr.push(a + ' ' + i / (arr.length - 1) * 100 + '%');
    });

    var css = 'linear-gradient(20deg, ' + clr.join(', ') + ')';

    return css;

  } else {
    return party.color;
  }
}

export function color (data, getters) {
  var party;

  if (!getters) getters = store.getters;

  if (data.member) {
    if (typeof data.member === 'number') {
      party = getters.getPartyByReg(data.member);
    }
  }

  if (((party && party.reg === 99) || !party) && data.nominee) {
    if (typeof data.nominee === 'number') {
      party = getters.getPartyByReg(data.nominee);
    }
  }

  if (party) {
    return party.color;
  } else {
    return '#aaa';
  }
}

export function getLinkType (link) {
  if (link.split('facebook.com').length > 1) return 'fb';
  if (link.split('twitter.com').length > 1) return 'tw';
  if (link.split('instagram.com').length > 1) return 'ig';
  if (link.split('wikipedia').length > 1) return 'wiki';
  if (link.split('youtube').length > 1) return 'yt';
  if (link.split('youtu').length > 1) return 'yt';
  if (link.split('hlidac').length > 1) return 'hlidac-statu';
  // if (link.split('nasi-politici').length > 1) return 'nasi-politici';
  if (link.split('demagog').length > 1) return 'demagog';
  return 'link';
}

function getLinksShortInside (link) {
  if (link.split('facebook.com').length > 1) return link.split('facebook.com/')[1];
  if (link.split('twitter.com').length > 1) return link.split('twitter.com/')[1];
  if (link.split('instagram.com').length > 1) return link.split('instagram.com/')[1];
  if (link.split('wikipedia.org/wiki').length > 1) return link.split('wikipedia.org/wiki/')[1];
  if (link.split('youtube.com').length > 1) return link.split('youtube.com/')[1];

  var dom = link.split('://')[1];

  if (dom.split('www.').length > 1) {
    return dom.split('www.')[1];
  } else {
    return dom;
  }
}

export function getLinkShort (link) {
  var l = getLinksShortInside(link);

  if (l.charAt(l.length - 1) === '/') {
    var d = l.split('/');
    d.pop();
    return d.join('/');
  } else {
    return l;
  }
}

export function round (value, digits) {

  var pow = Math.pow(10, digits || 2);

  return Math.round(value * pow) / pow;
}
