import {betterURL} from '@/common/helpers';
import TwitterFeed from "@/components/twitter/do";

export default {
	name: 'layout-support',
	data: function () {
		return {
			banners: {
				selected: '300x250',
				page: '',
				options: [
					'300x250', '160x600', '468x60', '728x90', '300x50', '320x50'
				]
			}
		}
	},
  components: {
		TwitterFeed
	},
	computed: {
	},
  methods: {
		betterURL
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Podpořte Programy do voleb"});
  }
};
