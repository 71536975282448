export default {
   "1_15": 0,
   "2_14": 2,
   "3_2": 0,
   "3_15": 0,
   "3_20": 3,
   "3_21": 3,
   "3_22": 0,
   "4_10": 2,
   "4_15": 0,
   "5_10": 0,
   "5_15": 0,
   "7_2": 0,
   "7_15": 0,
   "7_21": 3,
   "8_3": 2,
   "8_15": 0,
   "8_16": 0,
   "10_2": 0,
   "11_3": 0,
   "11_10": 2,
   "11_13": 3,
   "11_15": 0,
   "12_21": 0,
   "13_3": 2,
   "13_15": 0,
   "14_15": 0
 }
