import {beautifyNumber} from '@/common/helpers';

export default {
	name: 'CandidateStats',
	props: ['data', 'region', 'party', 'compareToAll', 'compareToRegion'],
	data: function () {
		return {
			selected: null
		}
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		youngest: function () {
			var list = [];

			if (this.data && this.data.length > 5) {
				this.data.forEach(p => list.push(p));
				list.sort((a, b) => a.age_at_election - b.age_at_election);
				list.splice(5);
			}

			return list;
		},
		oldest: function () {
			var list = [];

			if (this.data && this.data.length > 5) {
				this.data.forEach(p => list.push(p));
				list.sort((a, b) => b.age_at_election - a.age_at_election);
				list.splice(5);
			}

			return list;
		},
		results: function () {
			var obj = {
				age: this.averageAge(this.data),
				woman: this.data.filter(x => x.sex === 2).length,
				uni: this.data.filter(x => !!x.title_before || !!x.title_after).length,
				young: this.data.filter(x => x.age_at_election < 30).length,
				old: this.data.filter(x => x.age_at_election > 64).length
			};

			return obj;
		},
		demo: function () {
			var obj = [
				[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
				[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
			];

			this.data.forEach(person => {
				obj[person.sex - 1][Math.floor(person.age_at_election / 5) - 4]++;
			});

			var max = 0;

			obj[0].forEach(x => max = x > max ? x : max);
			obj[1].forEach(x => max = x > max ? x : max);

			return {
				data: obj,
				max
			};
		}
	},
	methods: {
		beautifyNumber,
		averageAge: function (list) {
			var age = 0;
			var count = 0;

			list.forEach(person => {
				if (person.age_at_election) {
					count++;
					age += person.age_at_election;
				}
			});

			var result = this.round(age / count);

			return result;
		},
		round: function (value) {
			return Math.round(100 * value)/100
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
	}
};
