export default {
  source: 'https://t.co/ZaecXRXZ0o?amp=1',
  list: [
    {
      name: "náměstí",
      address: "Kroměříž, Hanácké náměstí",
      designated: "Kroměříž",
      maps: {
        "link": 'fezopemecu',
        "embed": 'fezopemecu'
      }
    },
    {
      name: "parkoviště ČD-Sever",
      address: "Uherské Hradiště, ul. Na Stavidle",
      designated: "Uherské Hradiště",
      maps: {
        "link": 'jerugoleve',
        "embed": 'jerugoleve'
      }
    },
    {
      name: "parkoviště za hotelem Sport",
      address: "Vsetín, ul. U Bečvy",
      designated: "Vsetín",
      maps: {
        "link": 'jucavuvoge',
        "embed": 'jucavuvoge'
      }
    },
    {
      name: "parkoviště č. 24 firmy CREAM R.B.A., a. s.",
      address: "Zlín, Baťův areál",
      designated: "Zlín",
      maps: {
        "link": 'muvonacona',
        "embed": 'muvonacona'
      }
    }
  ]
}
