import axios from "axios";
import {betterURL} from "@/common/helpers";

export default {
	name: 'image-upload',
	data: function () {
		return {
			e: null,
			src: null,
			srcData: null,
			uploading: false,
			name: null,
			person: true,
			alert: null
		}
	},
	components: {
	},
	computed: {},
	methods: {
		ev: function (msg) {
			this.e = msg;

			setTimeout(() => this.e = null, 2500);
		},
		copy: function () {
			const el = document.createElement('textarea');
			el.value = document.querySelector('.upload_result_src').innerText;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
		uploadFile: function () {
			axios.post("https://data.programydovoleb.cz/api/lib/app/api.php?action=file/upload", {
				data: this.srcData,
				name: betterURL(this.name),
				person: this.person ? 1 : 0
			}).then(response => {
				if (response.data.code === 200) {
					this.src = response.data.src;
					this.$emit('update', {link: this.$store.state.server + 'lide/uploads/' + this.src});

					this.alert = 'Hotovo';

					setTimeout(() => this.alert = null, 2500);
				} else {
					this.ev('Chyba');
				}

				this.uploading = false;
			})
		},
		upload: function () {
			if (this.uploading === true) return;
			this.uploading = true;

			// from an input element

			var filesToUpload = this.$refs['myInput'].files;
			var file = filesToUpload[0];

			var img = document.createElement("img");
			var reader = new FileReader();

			reader.readAsDataURL(file);

			this.alert = 'Načítání fotografie';

			setTimeout(() => {

				img.src = reader.result;

				var canvas = this.$refs['myCanvas'];

				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);

				var dataurl;

				this.alert = 'Příprava aplikace';

				setTimeout(() => {

					this.alert = 'Změna velikosti';
					var MAX_WIDTH = 300;
					var MAX_HEIGHT = 400;
					var width = img.width;
					var height = img.height;

					if (width > height) {
						if (width > MAX_WIDTH) {
							height *= MAX_WIDTH / width;
							width = MAX_WIDTH;
						}
					} else {
						if (height > MAX_HEIGHT) {
							width *= MAX_HEIGHT / height;
							height = MAX_HEIGHT;
						}
					}
					canvas.width = width;
					canvas.height = height;

					ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, width, height);
				}, 500);

				setTimeout(() => {
					dataurl = canvas.toDataURL("image/jpeg", .5);

					this.name = file.name;

					this.alert = 'Příprava k odeslání';
				}, 1000);

				setTimeout(() => {
					if (dataurl.length > 10) {

						this.alert = 'Odesílání na server';

						this.srcData = dataurl;
						this.uploading = true;
						this.uploadFile();
					}
				}, 2000);
			}, 1000);
		}
	},
	mounted: function () {
	},
};
