import TwitterFeed from "@/components/twitter/do";
import NewsFeed from "@/components/news-feed/do";
import ChartSingleParty from "@/components/charts/components/single-party-chart/do";
import {beautifyNumber} from "@/common/helpers";

export default {
	name: 'layout-party-detail',
	props: ['hash'],
	data: function () {
		return {
			regions: ['Hlavní město Praha', 'Středočeský', 'Jihočeský', 'Plzeňský', 'Karlovarský', 'Ústecký', 'Liberecký', 'Královéhradecký', 'Pardubický', 'Vysočina', 'Jihomoravský', 'Olomoucký', 'Zlínský', 'Moravskoslezský'],
			pollsShowAll: false
		}
	},
  components: {
		TwitterFeed,
		ChartSingleParty,
		NewsFeed
	},
	computed: {
		party: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/strany/data/' + this.hash)
		},
		polls: function () {
			return this.$store.getters.getSource('pruzkumy/strany/' + this.hash)
		},
		popularity: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/popularita-social-media/' + this.hash)
		},
		people: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/seznam/poslanci');
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		ga: function () {
			if (this.party) {
				document.title = this.party.name;
			} else {
				document.title = "Detail strany"
			}
			return document.title;
		}
	},
  methods: {
		beautifyNumber,
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('youtube').length > 1) return 'yt';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: this.ga || this.hash});
  }
};
