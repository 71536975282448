import axios from 'axios';

export default {
	name: 'admin-ministry-list',
	props: ['candidates', 'hash', 'sid'],
	data: function () {
		return {
			ministry: null,
			mcn: '',
			person: null,
			list: []
		}
	},
	components: {

	},
	computed: {
		candlist: function () {
			var arr = [];

			this.candidates.forEach(c => {
				var o = {
					hash: c.hash,
					name: c.name_first + ' ' + c.name_family,
					ft: c.name_family + ' ' + c.name_first
				}

				arr.push(o);
			})

			arr.sort((a, b) => a.ft.localeCompare(b.ft, 'cs'));

			return arr;
		}
	},
	methods: {
		remove: function (contact) {
			axios.post(this.$store.state.api + 'admin/minister-remove', {
				key: this.sid,
				ministry: contact.ministry,
				mcn: contact.mcn,
				person: contact.person,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					contact.removed = true;
				}
			})
		},
		add: function () {
			axios.post(this.$store.state.api + 'admin/minister-add', {
				key: this.sid,
				ministry: this.ministry.hash,
				mcn: this.mcn,
				person: this.person.hash,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						ministry: this.ministry.hash,
						mcn: this.mcn,
						person: this.person.hash,
						removed: false
					})

					this.ministry = null;
					this.person = null;
					this.mcn = '';
				}
			})
		},
		fetch: function () {
			this.list = [];

			axios.post(this.$store.state.api + 'admin/minister-list', {
				key: this.sid,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					response.data.list.forEach(item => {
						this.list.push({
							ministry: item.ministry,
							mcn: item.mcn || '',
							person: item.person,
							removed: false
						})
					});

					this.list.sort((a, b) => a.ministry.localeCompare(b.ministry, 'cs'));

					if (this.list.find(elt => elt.ministry === 'premier')) {
						this.list.unshift(this.list.splice(this.list.findIndex(elt => elt.ministry === 'premier'), 1)[0])
					}
				}
			})
		}
	},
	mounted: function () {
		this.fetch();
	},
	watch: {
		data: function () {
			this.fetch();
		}
	}
};
