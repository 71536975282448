import TwitterFeed from "@/components/twitter/do";

export default {
	name: 'layout-person-detail',
	props: ['hash'],
	data: function () {
		return {}
	},
  components: {
		TwitterFeed
	},
	computed: {
		parties: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/seznam/strany')
		},
		person: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/lide/data/' + this.hash);
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		ga: function () {
			if (this.person) {
				document.title = this.person.name + ' - Programy do voleb'
			} else {
				document.title = "Detail osoby - Programy do voleb"
			}
			return document.title;
		}
	},
  methods: {
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: this.ga || this.hash});
  }
};
