import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PartyLineSummary',
	props: ['party'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		links: function () {
			var list = [];

			var prep = [];

			this.party.links.forEach(link => {
				if (typeof link.region === 'undefined') prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var link = prep.find(x => x.type === 'link');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (link) list.push(link);
			if (wiki) list.push(wiki);

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
