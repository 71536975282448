import axios from 'axios';

export default {
	name: 'layout-votecalc-form',
	data: function () {
		return {
			phase: 0,
			type: 0,
			questions: [],
			indexField: [],
			pointer: 0,
			disableAll: false,
			completed: false,
			sending: false
		}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		headline: function () {
			if (this.phase === 1) return "Základní volební kalkulačka";
			if (this.phase === 2) return "Rozšířená volební kalkulačka";
			if (this.phase === 3) return "Hotovo";
			if (this.phase === 0) return "Volební kalkulačka";
		},
		ix: function () {
			if (this.type === 1) {
				return this.indexField[this.pointer]
			} else {
				return this.pointer
			}
		}
	},
	methods: {
		vote: function (pointer, answer, importance) {
			this.questions[pointer].answer = answer;
			this.questions[pointer].importance = importance;

			this.disableAll = true;

			this.$el.querySelectorAll('.answer-primary button').forEach(el => el.classList.remove('answer-button---animate'));
			this.$el.querySelector('.answer-' + answer + importance + ' button').classList.add('answer-button---animate-hide---delay');
			this.$el.querySelectorAll('.answer button').forEach(el => el.classList.add('answer-button---animate-hide'));

			setTimeout(() => {
				if ((this.type === 1 && this.pointer === 39) || this.type === 2 && this.pointer === 112) {
					this.phase = 2;
					this.completed = true;
				} else if (this.completed) {
					this.phase = 2;
				} else {
					this.disableAll = false;
					this.pointer++;
					window.scrollTo(0, 0);
					this.$el.querySelectorAll('.answer-primary button').forEach(el => el.classList.add('answer-button---animate'));
					this.$el.querySelectorAll('.answer button').forEach(el => el.classList.remove('answer-button---animate-hide'));
					this.$el.querySelectorAll('.answer button').forEach(el => el.classList.remove('answer-button---animate-hide---delay'));
				}
			}, 500)
		},
		setType: function (type) {
			this.type = type;
			this.phase = 1;
		},
		go: function (index) {
			this.phase = 1;
			this.pointer = index;

			window.scrollTo(0, 0);
			this.disableAll = false;
		},
		point: function (index) {
			this.phase = 1;

			if (this.type === 1) {
				this.pointer = this.indexField.findIndex(x => x === index)
			} else {
				this.pointer = index;
			}

			window.scrollTo(0, 0);
			this.disableAll = false;
		},
		save: function () {
			this.sending = true;

			var values = [];

			this.questions.forEach(q => {
				if (this.type === 2 || q.priority === 1) {
					values.push([
						q.id,
						q.answer === 0 ? q.answer = 4 : q.answer,
						q.importance
					]);
				}
			});

			axios.post(this.$store.state.api + 'votecalc/add', {
				type: this.type,
				values: JSON.stringify(values)
			}).then((response) => {
				setTimeout(() => {
					this.sending = false;
					this.$router.push('/volebni-kalkulacka/vysledky/' + response.data.hash);
				}, 2000);
			})
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: "Volební kalkulačka"});

		this.$store.state.static.questions.list.forEach(question => {
			this.questions.push({
				id: question.id,
				priority: question.priority,
				question: question.question,
				topic: this.$store.state.static.questions.topics.find(x => x.topic === question.topic).name,
				answer: 0,
				importance: 0,
				rand: Math.random()
			});
		})

		this.questions.sort((a, b) => a.rand - b.rand);

		this.questions.forEach((q, index) => {
			if (q.priority === 1) {
				this.indexField.push(index);
			}
		})
	}
};
