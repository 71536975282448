import {date, beautifyNumber} from "@/common/helpers";

export default {
	name: 'BudgetEntry',
	props: ['fin'],
	data: function () {
		return {
			show: false,
			colors: {
				income: '#009206',
				spend: '#d72027',
				amount: '#00afa9'
			}
		}
	},
	methods: {
		date,
		beautifyNumber
	},
	mounted: function () {
		if (this.open) this.show = true;
	}
};
