import questions from './db/questions';
import parties from './db/parties';
import regionals from './db/regionals';
import rozhlas from './db/rozhlas';
import stats from './db/stats';

const state = {
  static: {
    regions: ['Praha', 'Středočeský kraj', 'Jihočeský kraj', 'Plzeňský kraj', 'Karlovarský kraj', 'Ústecký kraj', 'Liberecký kraj', 'Královéhradecký kraj', 'Pardubický kraj', 'Vysočina', 'Jihomoravský kraj', 'Olomoucký kraj', 'Zlínský kraj', 'Moravskoslezský kraj'],
    regionsShort: ['Praha', 'STK', 'JCK', 'PLK', 'KVK', 'ÚLK', 'LBK', 'KHK', 'PAK', 'VYS', 'JMK', 'OLK', 'ZLK', 'MSK'],
    pollsters: {
      'KANTAR TNS': '#000',
      'KANTAR CZ': '#000',
      KANTAR: '#000',
      CVVM: '#019DE0',
      STEM: '#B00058',
      MEDIAN: '#00518F',
      IPSOS: '#00afa9',
      'DATA COLLECT': '#11579a',
      'VÁŽENÝ PRŮMĚR PROGRAMY DO VOLEB': '#134d8c'
    },
    ministry: [
      {hash: 'premier', name: 'Premiér vlády', link: 'https://www.vlada.cz/'},
      {hash: 'doprava', name: 'Ministr dopravy', link: 'http://www.mdcr.cz/'},
      {hash: 'finance', name: 'Ministr financí', link: 'http://www.mfcr.cz/'},
      {hash: 'kultura', name: 'Ministr kultury', link: 'http://www.mkcr.cz/'},
      {hash: 'mistni-rozvoj', name: 'Ministr pro místní rozvoj', link: 'http://www.mmr.cz/'},
      {hash: 'obrana', name: 'Ministr obrany', link: 'http://www.army.cz/'},
      {hash: 'prace-a-socialni-veci', name: 'Ministr práce a sociálních věcí', link: 'http://www.mpsv.cz/'},
      {hash: 'prumysl-a-obchod', name: 'Ministr průmyslu a obchodu', link: 'http://www.mpo.cz/'},
      {hash: 'spravedlnost', name: 'Ministr spravedlnosti', link: 'http://www.justice.cz/'},
      {hash: 'skolstvi-mladez-telovychova', name: 'Ministr školství, mládeže a tělovýchovy', link: 'http://www.msmt.cz/'},
      {hash: 'vnitro', name: 'Ministr vnitra', link: 'http://www.mvcr.cz/'},
      {hash: 'zahranicni-veci', name: 'Ministr zahraničních věcí', link: 'http://www.mzv.cz/'},
      {hash: 'zdravotnictvi', name: 'Ministr zdravotnictví', link: 'http://www.mzcr.cz/'},
      {hash: 'zemedelstvi', name: 'Ministr zemědělství', link: 'http://www.mze.cz/'},
      {hash: 'zivotni-prostredi', name: 'Ministr životního prostředí', link: 'http://www.mzp.cz/'},
      {hash: 'jine', name: 'Jiný, nový, neuvedený resort'}
    ],
    questions,
    regionals,
    rozhlas,
    stats
  },
  dynamic: {
    source: [],
    pdv: [
      {
        added: 1,
        request: "party/list-demo",
        content: parties
      }
    ],
    quiz: null
  },
  hide: [],
  server: 'https://data.programydovoleb.cz/',
  api: 'https://2021.programydovoleb.cz/lib/app/api.php?action=',
  root: 'https://2021.programydovoleb.cz/',
  tick: 0,
  start: new Date().getTime()
};

export default state;
