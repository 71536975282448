import ChartMultiParty from "@/components/charts/components/multi-party-chart/do";
import ResultsGraph from "@/components/results-graph/do";

export default {
	name: 'layout-polls',
	data: function () {
		return {}
	},
  components: {
		ChartMultiParty, ResultsGraph
	},
	computed: {
		polls: function () {
			return this.$store.getters.pdv('polls/last-50')
		},
		pop: function () {
			return this.$store.getters.getSource('volby/psp/2021/poll-of-polls')
		},
		popData: function () {
			var list = [];

			if (this.pop) {
				Object.keys(this.pop[0].average).forEach(key => {
					var obj = {};

					var p = this.$store.getters.party(key);

					if (p) {
						obj.about = p;
						obj.data = [];

						list.push(obj);
					}

					// console.log(key, p);
				});

				this.pop.forEach(poll => {
					Object.keys(poll.average).forEach(key => {

						var p = list.find(x => x.about.hash === key);

						if (p) {
							var obj = {};

							obj.date = poll.date;
							obj.value = poll.average[key];

							p.data.push(obj);
						}
					});
				});
			}

			return list;
		},
		pollsList: function () {
			var years = [
				{
					label: '2022',
					list: this.polls.list.filter(x => x.date.charAt(3) === '2')
				},
				{
					label: '2021',
					list: this.polls.list.filter(x => x.date.charAt(3) === '1')
				},
				{
					label: '2020',
					list: this.polls.list.filter(x => x.date.charAt(3) === '0')
				},
				{
					label: '2019',
					list: this.polls.list.filter(x => x.date.charAt(3) === '9')
				},
				{
					label: '2018',
					list: this.polls.list.filter(x => x.date.charAt(3) === '8')
				},
				{
					label: '2017',
					list: this.polls.list.filter(x => x.date.charAt(3) === '7')
				}
			]

			years.forEach(year => {
				year.list.sort((a, b) => b.date.localeCompare(a.date, 'cs'));
			})

			return years;
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		latestSimar: function () {
			if (!this.polls) return undefined;

			return this.pollsList[0].list.find(x => ['IPSOS', 'MEDIAN', 'KANTAR CZ', 'CVVM', 'STEM', 'KANTAR', 'KANTAR TNS', 'DATA COLLECT'].indexOf(x.agency.toUpperCase()) > -1);
		},
		partiesMultiChartData: function () {
			var list = [];
			var parties = [];

			return {list, parties};
		}
	},
  methods: {
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Průzkumy a výzkumy"});
  }
};
