export default {
	name: 'rozhlas-block',
	props: ['region', 'no', 'person', 'compact'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		id: function () {
			return (this.region + 1) + '_' + (this.no || this.$(this.person.party).election_number);
		},
		media: function () {
			var addon = 1;

			if (this.$store.state.static.rozhlas[this.id]) {
				addon = this.$store.state.static.rozhlas[this.id];
			}

			if (this.id === '1_7') {
				return this.id + '_' + 2;
			} else if (this.person.run === 1 && this.person.position === addon) {
				return this.id + '_' + addon;
			} else {
				return null;
			}
		},
		hasAudio: function () {
			var x = true;

			return x;
		}
	},
	methods: {
		share: function (url) {
			window.open(url, 'Sdílení', 'width=550,height=450,scrollbars=no');
		},
		fb: function () {
			this.share('https://www.facebook.com/sharer/sharer.php?u=https://www.irozhlas.cz/volby/parlamentni-volby-2021/kandidati-vizitky/?' + this.id);
		},
		tw: function () {
			this.share('https://twitter.com/intent/tweet?text=' + this.person.name_first + ' ' + this.person.name_family + ' (' + this.$(this.person.party).short + ')&url=https://www.irozhlas.cz/volby/parlamentni-volby-2021/kandidati-vizitky/?' + this.id);
		},
		copy: function () {
			navigator.clipboard.writeText('https://www.irozhlas.cz/volby/parlamentni-volby-2021/kandidati-vizitky/?' + this.id);
		}
	}
};
