import axios from 'axios';
import ImageUpload from '../../image-upload/do';

export default {
	name: 'admin-photo',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			licence: '',
			print: false,
			link: null,
			alert: null,
			sending: false
		}
	},
	components: {
		ImageUpload
	},
	methods: {
		update: function (data) {
			this.link = data.link;
			this.licence = '';
			this.print = 0;

			this.save();
		},
		save: function () {
			this.sending = true;
			axios.post(this.$store.state.api + 'admin/photo-add', {
				key: this.sid,
				content: {
					link: this.link,
					licence: this.licence,
					print: this.print
				},
				hash: this.hash
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		}
	},
	mounted: function () {
		if (this.data[0]) {
			this.licence = this.data[0].licence;
			this.print = this.data[0].print;
			this.link = this.data[0].link;
		}
	}
};
