import axios from 'axios';

export default {
	name: 'admin-person-cand',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			values: [],
			alert: null,
			sending: false
		}
	},
	computed: {
		parties: function () {
			var p = this.$store.getters.parties();
			var list = [];

			if (p) p.forEach(i => list.push({
				reg: i.reg,
				name: i.name,
				extra: false
			}))

			list = list.filter(x => x.reg);
			list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			list.push({
				reg: 99,
				extra: true,
				name: 'bez politické příslušnosti'
			})
			list.push({
				reg: -1,
				extra: true,
				name: 'strana v seznamu chybí'
			})
			list.push({
				reg: null,
				extra: true,
				name: 'nevyplňeno'
			})

			return list;
		},
		partySuper: function () {
			return this.$store.getters.getSource('obecne/strany');
		},
		partySuperList: function () {
			var list = [];

			if (this.values.find(x => x.id === 'member').content === -1 && this.partySuper) {
				list = this.partySuper.list.filter(x => !x.coalition);
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		}
	},
	methods: {
		save: function () {
			this.sending = true;

			var data = {
				region: this.values.find(x => x.id === 'region').content,
				position: this.values.find(x => x.id === 'position').content,
				nominee: this.values.find(x => x.id === 'nominee').content,
				member: this.values.find(x => x.id === 'member').content
			}

			if (data.position === '') data.position = null;
			if (data.member === -1) data.member = null;

			if (data.member === -1 && this.values.find(x => x.id === 'member').contentExtra) {
				data.member = this.values.find(x => x.id === 'member').contentExtra;
			}

			axios.post(this.$store.state.api + 'admin/cand-update', {
				key: this.sid,
				hash: this.hash,
				values: data
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		}
	},
	mounted: function () {
		this.values.push({
			label: 'Subject',
			id: 'party',
			original: this.$store.getters.party(this.data.party),
			content: this.$store.getters.party(this.data.party),
			type: 'text',
			empty: false,
			editable: false
		})
		this.values.push({
			label: 'Kraj',
			id: 'region',
			original: this.data.region,
			content: this.data.region,
			type: 'select',
			options: this.$store.state.static.regions,
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Pozice',
			id: 'position',
			original: this.data.position,
			content: this.data.position,
			type: 'number',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Nominace',
			id: 'nominee',
			original: this.data.nominee,
			content: this.data.nominee,
			type: 'selectParty',
			options: this.parties,
			extra: false,
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Členství',
			id: 'member',
			original: this.data.member,
			content: this.data.member,
			contentExtra: null,
			type: 'selectParty',
			options: this.parties,
			extra: true,
			empty: true,
			editable: true
		})
	}
};
