import {beautifyNumber, betterURL, date, getLinkType} from "@/common/helpers";

export default {
	name: 'party-minister-candidates',
	props: ['hash'],
	data: function () {
		return {}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			var data = this.$store.getters.pdv('party/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		ministers: function () {
			return this.$store.getters.pdv('ministers/fetch/' + this.hash);
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		getLinkType,
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: 'Kandidáti ' + self.party.name});
				}
			}, 100);
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
