import Vue from 'vue';
import Router from 'vue-router';
import LayoutHomepageResults from '@/layout/results/do';
import LayoutHomepage from '@/layout/homepage/do';
import LayoutElectionAbout from '@/layout/elections/do';

import LayoutPersonDetailOld from '@/layout/old/person-detail/do';
import LayoutPersonList1721Old from '@/layout/old/person-list/elected-2017-2021/do';
import LayoutPartyDetailOld from '@/layout/old/party-detail/do';
import LayoutPartyListOld from '@/layout/old/party-list/all/do';

import LayoutParty from '@/layout/party/do';
import LayoutParties from '@/layout/parties/do';
import LayoutProgram from '@/layout/program/do';
import LayoutPriority from '@/layout/priority/do';
import LayoutAnswers from '@/layout/party-answers/do';
import LayoutCandidateList from '@/layout/candidates/do';
import LayoutCandidates from '@/layout/party-candidates/do';
import LayoutMinisters from '@/layout/party-ministers/do';
import LayoutMinisterList from '@/layout/ministers/do';
import LayoutRegional from '@/layout/regional/do';
import LayoutRegion from '@/layout/region/do';
import LayoutPerson from '@/layout/person/do';
import LayoutTags from '@/layout/tags/do';

import LayoutRoadToElection from '@/layout/road/do';
import LayoutInterestIdea from '@/layout/interest-idea/do';
import LayoutChangelog from '@/layout/changelog/do';
import LayoutTiplog from '@/layout/tiplog/do';

import LayoutMandateModeler from '@/layout/election-simulation/do';

import LayoutPolls from '@/layout/polls/do';
import LayoutArchive from '@/layout/archive/do';

import LayoutResearch from '@/layout/research/do';
import LayoutResearchRolling from '@/layout/research/rolling-results/do';
import LayoutResearchMonthly from '@/layout/research/monthly-results/do';
import LayoutResearchTrends from '@/layout/research/trends/do';

import LayoutDreamteam from '@/layout/dreamteam/finals/do';
import LayoutDreamteamResults from '@/layout/dreamteam/results/do';

import LayoutGuideForm from '@/layout/guide/form/do';
import LayoutGuideResults from '@/layout/guide/results/do';

import LayoutQuizForm from '@/layout/quiz/form/do';
import LayoutQuizResults from '@/layout/quiz/results/do';

import LayoutCalcCompare from '@/layout/votecalc/compare/do';
import LayoutCalcResults from '@/layout/votecalc/results/do';
import LayoutCalcSingle from '@/layout/votecalc/single/do';
import LayoutCalcForm from '@/layout/votecalc/form/do';

import LayoutSupport from '@/layout/support/do';
import LayoutAbout from '@/layout/about/do';

import LayoutAdmin from '@/admin/wrapper/do';
import LayoutAdminClosed from '@/admin/closed/do';

import PDVTEST from '@/layout/old/popularity-sql/do';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LayoutHomepageResults',
      component: LayoutHomepageResults
    },
    {
      path: '/predvolebni-prehled',
      name: 'LayoutHomepage',
      component: LayoutHomepage
    },
    {
      path: '/podporit',
      name: 'LayoutSupport',
      component: LayoutSupport
    },
    {
      path: '/o-volbach',
      name: 'LayoutElectionAbout',
      component: LayoutElectionAbout
    },
    {
      path: '/o-projektu',
      name: 'LayoutAbout',
      component: LayoutAbout
    },
    {
      path: '/pruvodce-volbami',
      name: 'LayoutGuideForm',
      component: LayoutGuideForm
    },
    {
      path: '/pruvodce-volbami/na-miru',
      name: 'LayoutGuideResults',
      component: LayoutGuideResults
    },
    {
      path: '/volebni-kviz',
      name: 'LayoutQuizForm',
      component: LayoutQuizForm
    },
    {
      path: '/volebni-kviz/vysledky',
      name: 'LayoutQuizResults',
      component: LayoutQuizResults
    },
    {
      path: '/kandidati',
      name: 'LayoutCandidateList',
      component: LayoutCandidateList
    },
    {
      path: '/neco-navic/otazky-pro-kandidaty',
      name: 'LayoutInterestIdea',
      component: LayoutInterestIdea
    },
    {
      path: '/volebni-kalkulacka/porovnani-odpovedi',
      name: 'LayoutCalcCompare',
      component: LayoutCalcCompare
    },
    {
      path: '/volebni-kalkulacka/porovnani-odpovedi/:id',
      name: 'LayoutCalcSingle',
      component: LayoutCalcSingle,
      props: true
    },
    {
      path: '/volebni-kalkulacka/vysledky/:id',
      name: 'LayoutCalcResults',
      component: LayoutCalcResults,
      props: true
    },
    {
      path: '/volebni-kalkulacka/vysledky',
      name: 'LayoutCalcResults2',
      component: LayoutCalcResults
    },
    {
      path: '/volebni-kalkulacka',
      name: 'LayoutCalcForm',
      component: LayoutCalcForm
    },
    {
      path: '/pdv',
      name: 'PDVTEST',
      component: PDVTEST
    },
    {
      path: '/admin/:token',
      name: 'LayoutAdminClosed',
      component: LayoutAdminClosed,
      props: true
    },
    {
      path: '/admini/:token',
      name: 'LayoutAdmin',
      component: LayoutAdmin,
      props: true
    },
    {
      path: '/predvolebni-anketa',
      name: 'LayoutResearch',
      component: LayoutResearch
    },
    {
      path: '/vladni-tym-snu',
      name: 'LayoutDreamteam',
      component: LayoutDreamteam
    },
    {
      path: '/vladni-tym-snu/:id',
      name: 'LayoutDreamteamResults',
      component: LayoutDreamteamResults,
      props: true
    },
    {
      path: '/predvolebni-anketa/prubezne-vysledky',
      name: 'LayoutResearchRolling',
      component: LayoutResearchRolling
    },
    {
      path: '/predvolebni-anketa/trendy',
      name: 'LayoutResearchTrends',
      component: LayoutResearchTrends
    },
    {
      path: '/predvolebni-anketa/vysledky/:month',
      name: 'LayoutResearchMonthly',
      component: LayoutResearchMonthly,
      props: true
    },
    {
      path: '/volebni-tema/:hash',
      name: 'LayoutTags',
      component: LayoutTags,
      props: true
    },
    {
      path: '/pruzkumy-a-odhady',
      name: 'LayoutPolls',
      component: LayoutPolls
    },
    {
      path: '/archiv',
      name: 'LayoutArchive',
      component: LayoutArchive
    },
    {
      path: '/zpracovani-tipu',
      name: 'LayoutTiplog',
      component: LayoutTiplog
    },
    {
      path: '/zmeny-na-webu',
      name: 'LayoutChangelog',
      component: LayoutChangelog
    },
    {
      path: '/cesta-k-volbam',
      name: 'LayoutRoadToElection',
      component: LayoutRoadToElection
    },
    {
      path: '/kandidati-na-ministra',
      name: 'LayoutMinisterList',
      component: LayoutMinisterList
    },
    {
      path: '/kraj/:hash',
      name: 'LayoutRegion',
      component: LayoutRegion,
      props: true
    },
    {
      path: '/strany-a-hnuti',
      name: 'LayoutParties',
      component: LayoutParties,
      props: true
    },
    {
      path: '/strana/:hash',
      name: 'LayoutParty',
      component: LayoutParty,
      props: true
    },
    {
      path: '/strana/:hash/volebni-program',
      name: 'LayoutProgram',
      component: LayoutProgram,
      props: true
    },
    {
      path: '/strana/:hash/priority',
      name: 'LayoutPriority',
      component: LayoutPriority,
      props: true
    },
    {
      path: '/strana/:hash/odpovedi',
      name: 'LayoutAnswers',
      component: LayoutAnswers,
      props: true
    },
    {
      path: '/strana/:hash/kandidati',
      name: 'LayoutCandidates',
      component: LayoutCandidates,
      props: true
    },
    {
      path: '/strana/:hash/kandidati-na-ministra',
      name: 'LayoutMinisters',
      component: LayoutMinisters,
      props: true
    },
    {
      path: '/strana/:hash/:reg',
      name: 'LayoutRegional',
      component: LayoutRegional,
      props: true
    },
    {
      path: '/kandidat/:hash',
      name: 'LayoutPerson',
      component: LayoutPerson,
      props: true
    },
    {
      path: '/rejstrik/lide/:hash',
      name: 'LayoutPersonDetailOld',
      component: LayoutPersonDetailOld,
      props: true
    },
    {
      path: '/rejstrik/strany/:hash',
      name: 'LayoutPartyDetailOld',
      component: LayoutPartyDetailOld,
      props: true
    },
    {
      path: '/rejstrik/strany',
      name: 'LayoutPartyListOld',
      component: LayoutPartyListOld
    },
    {
      path: '/rejstrik/poslanci-2017-2021',
      name: 'LayoutPersonList1721Old',
      component: LayoutPersonList1721Old
    },
    {
      path: '/simulace-voleb',
      name: 'LayoutMandateModeler',
      component: LayoutMandateModeler
    }
  ]
});

export default router;
