import axios from 'axios';

export default {
	name: 'admin-myths',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			list: [],
			myth: {
        claim: '',
				comment: '',
				snippet: '',
				chunk: null
			}
		}
	},
	computed: {
		party: function () {
			return this.$store.getters.party(this.hash)
		},
		chunks: function () {
			if (this.party && this.party.program && this.party.program.program) {
				return JSON.parse(this.party.program.program.chunks)
			} else {
				return null;
			}
		},
		invalid: function () {
			if (this.myth.claim.length > 50 || this.myth.claim.length < 5) {
				return true;
			} else if (this.myth.chunk === null) {
				return true;
			} else {
				return false;
			}
		}
	},
	methods: {
		remove: function (myth) {
			axios.post(this.$store.state.api + 'admin/myth-remove', {
				key: this.sid,
				myth: myth.item,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					myth.removed = true;
				}
			})
		},
		add: function () {
			axios.post(this.$store.state.api + 'admin/myth-add', {
				key: this.sid,
				myth: this.myth,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						item: {
							claim: this.myth.claim,
							chunk: this.myth.chunk,
							snippet: this.myth.snippet,
							comment: this.myth.comment,
						},
						removed: false
					})

					this.myth.claim = '';
					this.myth.comment = '';
					this.myth.snippet = '';
					this.myth.chunk = null;
				}
			})
		},
		selectText: function () {
			// console.log(1, window.getSelection(), document.selection);
			if (window.getSelection && window.getSelection()) {
				this.myth.snippet = window.getSelection().toString();
				this.$refs['program'].opened = false;
			}
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(item => {
				this.list.push({
					item,
					removed: false
				});
			})
		}
	}
};
