import store from '@/store/store';

const mutations = {
  demo () {}
};

mutations.fetchSource = function (state, payload) {
  var lookup = state.dynamic.source.find(s => s.source === payload.source);

  if (lookup) {
    // // console.log(lookup.source, 'already in store');
  } else {
    state.dynamic.source.push({
      source: payload.source,
      content: payload.content
    });
  }
}

mutations.pdv = function (state, payload) {
  var lookup = state.dynamic.pdv.find(s => s.request === payload.request);

  if (lookup) {
    // // console.log(lookup.request, 'already in store:', lookup.added);
  } else {
    state.dynamic.pdv.push({
      request: payload.request,
      added: payload.antiCache,
      content: payload.content
    });
  }
}

mutations.partyHideToggle = function (state, payload) {
  if (state.hide.find(x => x === payload)) {
    state.hide.splice(state.hide.findIndex(x => x === payload), 1);
  } else {
    state.hide.push(payload);
  }
}

mutations.partyHideAll = function (state) {
  store.getters.parties().filter(x => x.status === 1).forEach(party => {
    state.hide.push(party.hash);
  })
}

mutations.partyHideNone = function (state) {
  state.hide = [];
}

mutations.quiz = function (state, payload) {
  state.dynamic.quiz = payload.data;
  payload.cb();
}

export default mutations;
