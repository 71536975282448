import {date} from "@/common/helpers";
import ResultsGraph from "@/components/results-graph/do";

export default {
	name: 'PollsEntry',
	props: ['poll', 'hash', 'open'],
	data: function () {
		return {
			show: false
		}
	},
	components: {
		ResultsGraph
	},
	methods: {
		date,
		toggleShow: function () {
			this.show = !this.show;
		}
	},
	computed: {
		color: function () {
			var c = '#ddd';

			var agency = this.poll.agency.toUpperCase();

			if (agency === 'KANTAR CZ') agency = 'KANTAR';
			if (agency === 'KANTAR TNS') agency = 'KANTAR';

			if (this.$store.state.static.pollsters[agency]) {
				c = this.$store.state.static.pollsters[agency];
			}

			return c;
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		data: function () {
			if (!this.poll && !this.parties) return undefined;

			var list = [];
			var about = {};

			var p = this.poll;

			about.agency = p.agency;
			about.date = p.date;
			about.amount = p.type;
			about.source = p.link;

			Object.keys(p).forEach(key => {
				if (['agency', 'date', 'link', 'type', 'error', 'collect_from', 'collect_to', 'attendance'].indexOf(key) === -1 && p[key] != null) {
					var obj = {
						hash: key
					};

					var party = this.parties.list.find(x => x.hash === key);

					if (party) {
						obj.color = !this.hash || this.hash === party.hash ? party.color : '#ccc';
						obj.logo = this.$store.state.server + party.logo;
						obj.pct = p[key];
						obj.short = party.short;
					}

					list.push(obj);
				}
			});

			list.sort((a, b) => b.pct - a.pct);

			return {list, about};
		}
	},
	mounted: function () {
		if (this.open) this.show = true;
	}
};
