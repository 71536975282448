import Html2Canvas from 'html2canvas';

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export default {
	name: 'layout-dreamteam',
	props: ['id'],
	data: function () {
		return {
			output: null
		}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		results: function () {
			return this.$store.getters.pdv('dreamteam/fetch/' + this.id)
		},
		resorts: function () {
			var list = [];

			if (this.results) {
				this.$store.state.static.ministry.forEach(ministry => {
					if (this.results.result[ministry.hash]) {
						list.push({
							resort: ministry,
							person: this.results.list.find(x => x.hash === this.results.result[ministry.hash])
						})
					}
				})
			}

			return list;
		},
		partiesInTeam: function () {
			var list = [];

			if (this.results) {

				this.results.list.forEach(person => {
          var id = person.member === 99 ? person.nominee : person.member;
					// resort.suggestions = [];
					// if (resort.selected) {
          // console.log(id);
					if (id != null && list.indexOf(id) === -1) list.push(id);
					// }
				});

				// return list;

				list.sort((a, b) => this.$(a).short.localeCompare(this.$(b).short, 'cs'));
			}

			return list;
		}
	},
	methods: {
		getMinisterOrEmpty: function (hash) {
			var display = 'neurčeno';
			var image = '/static/missing.png';
			var color = null;
      var personHash = null;

			var r = this.resorts.find(x => x.resort.hash === hash);

			if (r) {
				var p = r.person;

				display = p.name_first.charAt(0) + '. ' + p.name_family;
				color = this.$(p.nominee).color;
        personHash = p.hash;

				if (p.photo && p.photo[0]) {
					image = p.photo[0].link
				} else {
					// image = 'https://data.programydovoleb.cz/' + this.$(p.nominee).logo;
				}
			}

			toDataURL(image).then(dataURL => {
				// // console.log(this.$refs[hash]);
				this.$refs[hash][0].style="background-image: url(" + dataURL + ")"
			})

			return {
				display,
				image,
				color,
				hash: personHash
			}
		},
		print() {
			const el = this.$refs.visual.$el;
			// add option type to get the image version
			// if not provided the promise will return
			// the canvas.

			// console.log(this.$refs.visual, this.$refs.output)
			const options = {
				type: 'dataURL'
			}
			if (el) {
				Html2Canvas(el, options).then((resolve) => {
					// // console.log(resolve);
					this.output = resolve.toDataURL();
					// this.$refs.output.appendChild(resolve);
				});
			}
			// // console.log(Html2Canvas, el, options);
			//
			// Html2Canvas()
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: "Vládní tým snů"});

		setTimeout(() => this.print(), 2500);
	}
}
