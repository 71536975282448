import TwitterFeed from "@/components/twitter/do";
import NewsFeed from "@/components/news-feed/do";
import ChartSingleParty from "@/components/charts/components/single-party-chart/do";
import ChartBudget from "@/components/charts/components/budget-chart/do";
import {beautifyNumber, betterURL, date, getLinkType, getLinkShort} from "@/common/helpers";

export default {
	name: 'party-detail',
	props: ['hash'],
	data: function () {
		return {
			regions: ['Hlavní město Praha', 'Středočeský', 'Jihočeský', 'Plzeňský', 'Karlovarský', 'Ústecký', 'Liberecký', 'Královéhradecký', 'Pardubický', 'Vysočina', 'Jihomoravský', 'Olomoucký', 'Zlínský', 'Moravskoslezský'],
			pollsShowAll: false
		}
	},
	components: {
		TwitterFeed,
		ChartSingleParty,
		ChartBudget,
		NewsFeed
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			var data = this.$store.getters.pdv('party/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		peopleSource: function () {
			if (!this.party) return undefined;

			var fce = 'candidate/party/' + this.hash;

			if (this.party.status != 1 && this.party.reg) {
				fce = 'candidate/reg/' + this.party.reg;
			}

			var data = this.$store.getters.pdv(fce);

			return data ? data.list : undefined;
		},
		peopleShort: function () {
			if (!this.peopleSource) return undefined;

			var list = [];

			this.peopleSource.forEach(x => list.push(x));

			list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));
			list.sort((a, b) => (a.position || 99) - (b.position || 99));

			return list;
		},
		people: function () {
			if (!this.peopleSource) return undefined;

			var regions = [];

			this.$store.state.static.regions.forEach((region, index) => {
				var obj = {
					name: region,
					list: this.peopleSource.filter(x => x.region === index),
					links: this.party.links.filter(x => typeof x.region === 'number' && x.region === index).map(x => x.link),
					contacts: this.party.contacts.filter(x => typeof x.region === 'number' && x.region === index).map(x => x.value)
				}

				regions.push(obj);
			});

			if (this.peopleSource.filter(x => x.region === -1).length > 0) {
				var obj = {
					name: "Nezařazeno",
					list: this.peopleSource.filter(x => x.region === -1),
					links: []
				}

				regions.push(obj);
			}

			regions.forEach(region => {
				if (region.list) {
					region.list.sort((a, b) => (a.position || 100) - (b.position || 100))
				}
			})

			return regions;
		},
		links: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (typeof x.region === 'undefined') {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		contacts: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.contacts) {
				this.party.contacts.forEach(x => {
					if (typeof x.region === 'undefined') {
						list.push(x.value);
					}
				});
			}

			return list;
		},
		embeds: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (x.embed === 1 && typeof x.region === 'undefined' && x.link.split('twitter').length > 1) {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		polls: function () {
			return this.$store.getters.pdv('polls/party/' + this.hash)
		},
		pollsList: function () {
			return (this.polls || {list: []}).list.sort((a, b) => b.date.localeCompare(a.date, 'cs'));
		},
		pollsGraph: function () {
			if (!this.polls) return undefined;

			var list = [];

			this.polls.list.forEach(poll => {
				var obj = {
					agency: poll.agency.toUpperCase(),
					date: poll.date,
					value: poll[this.hash],
					data: poll
				}

				if (obj.agency === 'KANTAR CZ') obj.agency = 'KANTAR';
				if (obj.agency === 'KANTAR TNS') obj.agency = 'KANTAR';

				if (obj.value) list.push(obj);
			})

			return list;
		},
		budgetGraph: function () {
			if (!this.party) return undefined;

			var list = [];

			var totalSpend = 0;
			var totalIncome = 0;

			this.party.budget.forEach(fin => {

				list.push({
					agency: 'Zůstatek',
					date: fin.date,
					value: fin.amount
				});

				list.push({
					agency: 'Příjmy',
					date: fin.date,
					value: fin.income
				});
				list.push({
					agency: 'Výdaje',
					date: fin.date,
					value: -fin.spend
				});

				totalSpend -= fin.spend;
				totalIncome += fin.income;

				list.push({
					agency: 'Celkové výdaje',
					date: fin.date,
					value: totalSpend
				});

				list.push({
					agency: 'Celkové příjmy',
					date: fin.date,
					value: totalIncome
				});
			})

			return list;
		},
		popularity: function () {
			return this.$store.getters.pdv('popularity/party/' + this.hash)
		},
		popularityData: function () {
			if (!this.popularity) return;

			var list = [];

			this.popularity.list.forEach(item => {
				if (!list.find(x => x.hash === item.hash)) {
					var obj = {
						hash: item.hash,
						platform: {
							fb: [],
							tw: [],
							ig: []
						}
					}

					list.push(obj);
				}
			});

			this.popularity.list.forEach(item => {
				var entry = list.find(x => x.hash === item.hash);

				if (entry) {
					entry.platform[item.platform].push({
						date: item.date,
						value: item.value,
						link: item.link
					});
				}

			});

			list.forEach(item => {
				Object.keys(item.platform).forEach(key => {
					item.platform[key].sort((a, b) => b.date.localeCompare(a.date, 'cs'));
				});
			});

			return list;
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		leader: function () {
			var data = undefined;

			if (this.peopleSource && this.party && this.party.leader_hash) {
				data = this.peopleSource.find(x => x.hash === this.party.leader_hash);
			}

			if (this.party && this.party.leader_hash) data = this.$store.getters.pdv('candidate/detail/' + this.party.leader_hash);

			if (data) data = data.list[0];

			return data;
		},
		coalition: function () {
			var list = [];

			var data = this.$store.getters.parties();

			if (data) {
				list = data.filter(x => x.status === 2 && x.coalition === this.hash);
			}

			return list;
		},
		support: function () {
			var list = [];

			var data = this.$store.getters.parties();

			if (data) {
				list = data.filter(x => (x.status === 3 || x.status === 5) && x.support === this.hash);
			}

			return list;
		},
		audio: function () {
			if (!this.party) return;
			if (!this.party.program) return;
			if (!this.party.program.program) return;
			if (!this.party.program.program.audio) return;

			return JSON.parse(this.party.program.program.audio);
		},
		ministers: function () {
			return this.$store.getters.pdv('ministers/fetch/' + this.hash);
		},
		linksSelected: function () {
			var list = [];

			var prep = [];

			this.party.links.forEach(link => {
				if (typeof link.region === 'undefined') prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var link = prep.find(x => x.type === 'link');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (link) list.push(link);
			if (wiki) list.push(wiki);

			return list;
		},
		video: function () {
			var list = [];

			if (this.party && this.party.video && this.party.video.length > 0) {
				this.party.video.forEach(x => list.push(x));

				list.sort((a, b) => b.added.localeCompare(a.added, 'cs'));
			}

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		getLinkType,
		getLinkShort,
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: self.party.name});
				}
			}, 100);
		},
		getPopularityLabel: function(hash) {
			var s = hash;
			var l = '';
			var e = false;

			if (hash === this.hash) {
				s = this.party.short;
				l = '/strana/' + hash;
				e = true;
			} else {
				var p = this.$(hash);
				var c = this.peopleSource.find(x => x.hash === hash)

				if (p) {
					s = p.short;
					l = '/strana/' + hash;
					e = true;
				}
				if (c) {
					s = c.name_first + ' ' + c.name_family;
					l = '/kandidat/' + hash;
					e = true;
				}
			}

			return {label: s, link: l, exists: e};
		},
		getChunkLink: function (chunk) {
			var link = chunk;

			if (this.party && this.party.program && this.party.program.program) {
				var chunks = JSON.parse(this.party.program.program.chunks);

				link = betterURL(chunks.find(x => x.src === chunk).headline);
			}

			return link;
		},
		getLinks: function (party) {
			var list = [];

			var p = this.$store.getters.party(party);

			if (p && p.links) {
				p.links.forEach(x => {
					if (typeof x.region === 'undefined') {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		slide: function (id) {
			this.$el.querySelector("a[name=" + id + "]").scrollIntoView({behavior: "smooth", block: "center"});
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
