export default {
  source: 'https://www.msk.cz/cs/kraj/zivot_v_kraji/informace-k-volbam-7096/',
  list: [
    {
      name: "parkoviště na ul. Nádražní za Městským úřadem Bruntál",
      address: "Nádražní 994/20, Bruntál",
      designated: "Bruntál",
      maps: {
        "link": 'fecubuhugo',
        "embed": 'fecubuhugo'
      }
    },
    {
      name: "parkoviště před bývalým hotelem Centrum",
      address: "Na Poříčí 494, Frýdek-Místek",
      designated: "Frýdek-Místek",
      maps: {
        "link": 'logovabuga',
        "embed": 'logovabuga'
      }
    },
    {
      name: "Spolkový dům a přilehlé parkoviště",
      address: "U Hřiště 718/27, Karviná-Ráj",
      designated: "Karviná",
      maps: {
        "link": 'mahubujazo',
        "embed": 'mahubujazo'
      }
    },
    {
      name: "areál v blízkosti sportovní haly ABC",
      address: "Purkyňova, Nový Jičín",
      designated: "Nový Jičín",
      maps: {
        "link": 'naratajaso',
        "embed": 'naratajaso'
      }
    },
    {
      name: "areál Magistrátu města Opavy",
      address: "Krnovská, Opava",
      designated: "Opava",
      maps: {
        "link": 'davaleguka',
        "embed": 'davaleguka'
      }
    },
    {
      name: "parkoviště naproti Hlavního nádraží v Ostravě-Přívoze",
      address: "Wattova 797/17, Ostrava",
      designated: "Ostrava-město",
      maps: {
        "link": 'fegalegoho',
        "embed": 'fegalegoho'
      }
    }
  ]
}
