import Vue from 'vue';

import Box from './do';
import BoxGap from './gap/do';
import BoxIcon from './icon/do';
import BoxImage from './image/do';
import BoxLabel from './label/do';
import BoxButton from './button/do';
import BoxGroup from './group/do';
import BoxList from './list/do';
import BoxTicker from './ticker/do';
import BoxColor from './color/do';
import BoxGraph from './graph/do';

Vue.component('p-box', Box);
Vue.component('p-box-gap', BoxGap);
Vue.component('p-box-icon', BoxIcon);
Vue.component('p-box-image', BoxImage);
Vue.component('p-box-label', BoxLabel);
Vue.component('p-box-button', BoxButton);
Vue.component('p-box-group', BoxGroup);
Vue.component('p-box-list', BoxList);
Vue.component('p-box-ticker', BoxTicker);
Vue.component('p-box-color', BoxColor);
Vue.component('p-box-graph', BoxGraph);
