import axios from 'axios';
import AdminPerson from '../../person/do';
import AdminParty from '../../party/do';
import AdminPersonAdd from '../../person-add/do';
import AdminPersonMultipleAdd from '../../person-add-multiple/do';
import AdminPersonGarantAdd from '../../person-garant-add/do';
import {betterURL} from '@/common/helpers';

function copyToClipboard(text) {
	var dummy = document.createElement("textarea");
	// to avoid breaking orgain page when copying more words
	// cant copy when adding below this code
	// dummy.style.display = 'none'
	document.body.appendChild(dummy);
	//Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
	dummy.value = text;
	dummy.select();
	document.execCommand("copy");
	document.body.removeChild(dummy);
}

export default {
	name: 'admin-wrapper',
	props: ['token'],
	data: function () {
		return {
			sid: '',
			pass: false,
			loaded: false,
			list: [],
			selected: undefined,
			data: undefined,
			generatedToken: null,
			quicksearch: '',
			responses: [null, null, null, null, null, null, null, null, null, null, null, null, null, null],
			lastResponse: {
				party: null,
				person: null,
				email: null
			},
			tick: 0
		}
	},
  components: {
		AdminPerson,
		AdminParty,
		AdminPersonAdd,
		AdminPersonGarantAdd,
		AdminPersonMultipleAdd
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		}
	},
  methods: {
		betterURL,
		login: function () {
			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=admin/available', {
				token: this.token,
				pass: (this.$refs['pass'] || {value: ''}).value
			}).then((response) => {
				if (response.data.code === 200) {
					this.loaded = true;
					this.list = response.data.list;
					this.sid = response.data.sid;

					this.list.sort((a, b) => a.hash.localeCompare(b.hash, 'cs'));

					if (this.list.length === 1) this.selectToEdit(this.list[0]);
				}
				if (response.data.code === 403) {
					this.pass = true;
				}
			});
		},
		load: function () {
			this.data = null;

			setTimeout(() => {
				axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=admin/load', {
					key: this.sid,
					item: this.selected
				}).then((response) => {if (
					response.data.code === 200) {
						this.data = response.data.list;
					}
				});
			}, 250)
		},
		selectToEdit: function (item) {
			this.selected = item;
			this.generatedToken = null;
			if (this.$refs && this.$refs['modalAdd']) {
				if (this.$refs['modalAdd'].length) {
					this.$refs['modalAdd'].forEach(x => x.hide());
				} else if (this.$refs['modalAdd'].hide) {
					this.$refs['modalAdd'].hide();
				}
			}
			if (this.$refs && this.$refs['collapsibles']) {
				if (this.$refs['collapsibles'].length && this.$refs['collapsibles'].length > 5) {
					this.$refs['collapsibles'].forEach(x => x.hide());
				} else {
					// this.$refs['collapsibles'].hide();
				}
			}
			this.load();
		},
		update: function (list, item) {
			list.push(item);
			this.selectToEdit(item);
		},
		generateToken: function () {
			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=admin/generate', {
				key: this.sid,
				item: this.selected.hash,
				name: this.selected.display,
				token: this.token
			}).then((response) => {if (
				response.data.code === 200) {
					this.generatedToken = response.data.token;
				}
			});
		},
		generateRegionalToken: function (index) {
			var leader = this.list[0].candidates.find(x => x.region === index && x.position === 1);

			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=admin/generate-region', {
				key: this.sid,
				region: index,
				region_name: this.$store.state.static.regions[index],
				region_hash: betterURL(this.$store.state.static.regions[index]),
				party: this.data.core.hash,
				party_name: this.data.core.short,
				party_reg: this.data.core.reg,
				person: leader ? leader.hash : null,
				person_name: leader ? leader.display : null,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					this.responses[index] = {party: response.data.token[1], person: response.data.token[0], email: response.data.email};
					this.lastResponse.party = this.responses[index].party;
					this.lastResponse.person = this.responses[index].person;
					this.lastResponse.email = this.responses[index].email;
					copyToClipboard(response.data.email);
					this.tick++;
					this.$nextTick();
				}
			});
		},
		generateRegionalAccess: function (region) {

			var list = [];

			this.list[0].candidates.forEach(x => {
				if (x.region === region) list.push({hash: x.hash, name: x.display});
			});

			// console.log(list);

			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=admin/generate-region-access', {
				key: this.sid,
				list,
				token: this.token
			}).then((response) => {
				if (response.data.code === 200) {
					copyToClipboard(response.data.email);
				}
			});
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Editace"});
		this.login();
  }
};
