import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'admin-person-add',
	props: ['hash', 'region'],
	data: function () {
		return {
			values: [],
			district: null,
			town: null,
			alert: null,
			sending: false
		}
	},
	methods: {
		save: function () {

			var data = {
				name: this.values.find(x => x.id === 'name_first').content,
				family: this.values.find(x => x.id === 'name_family').content,
				nominee: this.values.find(x => x.id === 'nominee').content,
				sex: this.values.find(x => x.id === 'sex').content,
				party: this.values.find(x => x.id === 'hash').hash,
				region: this.values.find(x => x.id === 'region').content,
				position: this.values.find(x => x.id === 'position').content,
				hash: ''
			}

			data.hash = betterURL(data.name + '-' + data.family);

			if (data.name.trim() === '' || data.family.trim() === '' || data.nominee === null) return;

			this.sending = true;
			axios.post(this.$store.state.api + 'admin/person-add', {
				key: this.sid,
				values: data
			}).then(() => {
				this.alert = "Přidáno";
				this.sending = false;
				this.values.find(x => x.id === 'name_first').content = '';
				this.values.find(x => x.id === 'name_family').content = '';
				setTimeout(() => this.alert = null, 2500);
				// this.$emit('update', response.data.values)
			})
		},
		setValue: function (source, destination) {
			destination.contentNum = source.id;
			destination.content = source.name;
		}
	},
	computed: {
		parties: function () {
			var p = this.$store.getters.parties();
			var list = [];

			if (p) {
				p.forEach(i => {
					if ((this.hash === i.hash) || i.coalition === this.hash) {
						list.push({
							reg: i.reg,
							name: i.name,
							extra: false
						})
					}
				})
			}

			list = list.filter(x => x.reg);
			list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

			return list;
		}
	},
	mounted: function () {

		var pt = this.$store.getters.party(this.hash);

		if (pt.status === 2) pt = this.$store.getters.party(pt.coalition);

		this.values.push({
			label: 'Strana',
			id: 'hash',
			content: pt.name,
			hash: pt.hash,
			type: 'text',
			empty: false,
			editable: false
		})
		this.values.push({
			label: 'Region',
			id: 'region',
			content: this.region || -1,
			options: this.$store.state.static.regions.map((x, i) => {
				return {
					id: i, name: x
				}}),
			type: 'select',
			empty: false,
			editable: !this.region,
			note: 'Vyberte kraj, nebo se uloží jako nezařazený'
		})
		this.values.push({
			label: 'Jméno',
			id: 'name_first',
			original: '',
			content: '',
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Příjmení',
			id: 'name_family',
			original: '',
			content: '',
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Nominace',
			id: 'nominee',
			original: null,
			content: this.$store.getters.party(this.hash).reg,
			type: 'selectParty',
			options: this.parties,
			extra: false,
			empty: true,
			editable: !this.$store.getters.party(this.hash).reg
		})
		this.values.push({
			label: 'Pořadí',
			id: 'position',
			content: null,
			type: 'number',
			empty: true,
			editable: true,
			note: 'není nutné vyplňovat'
		})
		this.values.push({
			label: 'Pohlaví',
			id: 'sex',
			content: 1,
			options: [{id: 1, name: 'muž'}, {id: 2, name: 'žena'}],
			type: 'select',
			empty: true,
			editable: true
		})
	}
};
