import {truncate} from '@/common/helpers'

export default {
	name: 'program-comment',
	props: ['data', 'link'],
	data: function () {
		return {
			full: false
		}
	},
	computed: {
		fetchPerson: function () {
			return this.$store.getters.pdv('candidate/detail/' + this.data.candidate);
		},
		person: function () {
			return this.fetchPerson ? this.fetchPerson.list[0] : undefined
		},
		party: function () {
			return this.$store.getters.party(this.data.hash);
		},
		txt: function () {
			var obj = {
				short: truncate(this.data.content, 60),
				full: this.data.content
			};

			obj.expandable = obj.short != obj.full;

			return obj;
		},
		txtShow: function () {
			var s = this.txt.short;

			if (this.full === true) s = this.txt.full;

			return s;
		},
		programLink: function () {
			if (!this.link) return;

			return '/strana/' + this.data.hash + '/volebni-program#' + this.data.chunk;
		}
	}
};
