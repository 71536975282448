import {date, betterURL} from '@/common/helpers';
import SimpleGraph from '@/layout/research/simple-graph/do';

export default {
	name: 'layout-research-trends',
	data: function () {
		return {
			topic: {
				election_participation: 'Půjdete volit?',
				election_decision: 'Už víte, koho budete volit?',
				// electionVote: [],
				election_anticipation: 'Volby vyhlížíte ...',
				program_importance: 'Jak důležitý je pro vás volební program?',
				news_watch: 'Jak často v poslední době hledáte informace o volbách?',
				polls_trust: 'Jak vnímáte předvolební průzkumy Kantaru, Medianu nebo CVVM?',
				gov_happiness: 'Jak jste spokojeni s činností vlády?',
				gov_expectation: 'Očekáváte, že vláda bude po volbách ...',
				gov_leader: 'Kdo se podle vás stane po volbách premiérem a získá důvěru Sněmovny?',
				election_topics: 'Co budou podle vás hlavní témata voleb?',
				personal_topics: 'Co je pro vás aktuálně největší téma?',
				mood_people: 'Jak vnímáte náladu lidí ve svém okolí?',
				mood_personal: 'Jak se vám daří osobně?'
			},
			questions: {
				election_participation: ['Ano', 'Ještě nevím', 'Ne'],
				election_decision: ['Ano', 'Ne'],
				// electionVote: [],
				election_anticipation: ['s pocitem naděje', 'nevěřím, že se něco změní', 's obavami', 'potřebujeme změnu'],
				program_importance: ['velmi', 'docela jo', 'moc ne', 'vůbec'],
				news_watch: ['několikrát denně', 'každý den', 'občas', 'zřídka'],
				polls_trust: ['považuji je za důvěryhodné', 'sleduji spíš trendy', 'beru je jen orientačně', 'nevěřím jim', 'věřím jiným průzkumům', 'průzkumy nesleduji'],
				gov_happiness: ['velmi spokojený', 'spokojený', 'něco se povedlo, něco ne', 'nespokojený', 'velmi nespokojený'],
				gov_expectation: ['pokračovat v koalici ANO+ČSSD', 'v jiné podobě za účasti hnutí ANO', 'v úplně novém složení', 'úřednická'],
				// govLeader: null,
				election_topics: ['vystavení účtu vládě','koronavirus / očkování','zdravotnictví obecně','návrat do normálního života','obnova ekonomiky','využití dotací EU','konec uhlí','konec montoven','kvóty na potraviny','digitalizace','státní dluh','dividendy','daňové reformy','minimální mzda','zvýšení důchodů','legalizace drog','energetika','Dukovany','migrace a bezpečnost','manželství pro všechny','ekologie','EU/euro','něco jiné','maturity','důchodová reforma'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				personal_topics: ['mám zdravotní problémy','přišel jsem o práci','nemohu podnikat','mám finanční problémy','zavřené obchody a služby','nemohu dát děti do školy','nemohu studovat','nemohu cestovat','nemohu do hospody','chybí mi kultura','nemohu jet na chalupu','cítím napětí v rodině','cítím se osaměle','chybí mi kamarádi','vadí mi roušky / respirátory','celkový pocit vyčerpání','mám deprese','cítím se pod tlakem','pocit ohrožení svobody','něco jiné','nic mě netrápí'].sort((a, b) => a.localeCompare(b, 'cs')).map(x => {return {name: x, hash: betterURL(x)}}),
				mood_people: ['lidé jsou pozitivní', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'lidé jsou negativní', 'lidé jsou naštvaní'],
				mood_personal: ['mám se dobře', 'zlepšuje se to', 'žádné velké změny', 'zhoršuje se to', 'je to špatné'],
				sex: ['žena', 'muž', 'jiné', 'nechci uvést'],
				edu: ['základní/vyučený', 'maturita/voš', 'vysokoškolské'],
				age: ['do 30 let', '30-39 let', '40-49 let', '50-59 let', '60-69 let', '70 let a více'],
				region: this.$store.state.static.regions.concat('Žiju mimo Českou republiku')
			},
			leads: ['andrej-babis','ivan-bartos','petr-fiala','tomio-okamura','jan-hamacek','vojtech-filip','robert-slachta','lubomir-volny','zuzana-majerova-zahradnikova'],
			selected: {
				parties: ['ano-2011', 'pirati-a-starostove', 'prisaha', 'svoboda-a-prima-demokracie---spd', 'spolu-ods-kducsl-top09', 'trikolora-svobodni-soukromnici', 'volny-blok'],
				topics: [
					{hash: 'statni-dluh', title: 'Státní dluh'},
					{hash: 'danove-reformy', title: 'Daňové reformy'},
					{hash: 'koronavirus-/-ockovani', title: 'Koronavirus a očkování'},
					{hash: 'migrace-a-bezpecnost', title: 'Migrace a bezpečnost'}
				]
			}
		}
	},
  components: {
		SimpleGraph
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		partiesRunning: function () {
			return this.$store.getters.parties().filter(x => x.status === 1)
		},
		partiesReported: function () {
			var list = [];

			if (this.loaded) {
				this.results.forEach((x, index) => {
					x.report.parties.forEach(p => {
						if (this.partiesRunning.find(x => x.hash === p.hash)) {
							var px = list.find(x => x.hash === p.hash);

							if (!px) {
								px = {
									hash: p.hash,
									party: this.$(p.hash),
									data: [null, null, null, null, null, null]
								}

								list.push(px);
							}

							px.data[index] = p;
						}
					})
				});

				list.sort((a, b) => a.party.short.localeCompare(b.party.short, 'cs'));
			}


			return list;
		},
		leaders: function () {
			var list = [];

			var hash = this.leads;

			this.partiesRunning.forEach(party => {
				if (hash.indexOf(party.leader_hash) > -1) {
					list.push(party.leader[0]);
				}
			})

			return list;
		},
		results: function () {
			return [
				this.$store.getters.pdv('research/report/4'),
				this.$store.getters.pdv('research/report/5'),
				this.$store.getters.pdv('research/report/6'),
				this.$store.getters.pdv('research/report/7'),
				this.$store.getters.pdv('research/report/8'),
				this.$store.getters.pdv('research/report/9')
			]
		},
		loaded: function () {
			var l = true;
			this.results.forEach(x => {
				if (x === null || typeof x === 'undefined') l = false;
			});
			return l;
		}
	},
  methods: {
		date,
		pct: function (count, base) {
			return Math.round(10000 * count / (base || this.results.report.count)) / 100;
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Volební trendy"});
  }
};
