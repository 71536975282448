export default [
  {
    basic: true,
    question: 'Kdy jsou volby?',
    note: 'Něco na zahřátí, jisté první čtyři body. Vyznačte všechny správné dny a časy, kdy lze hlasovat v řádných volbách t.j. nikoli v karanténě, izolaci, u auta apod.',
    options: [
      {label: '8. 10. od 14:00 do 22:00', answer: 1},
      {label: '9. 10. od 8:00 do 14:00', answer: 1},
      {label: '6. 10. od 14:00 do 22:00', answer: 0},
      {label: '8. 10. od 8:00 do 22:00', answer: 0},
      {label: '9. 10. od 7:00 do 21:00', answer: 0},
      {label: '15. 10. od 14:00 do 22:00', answer: 0},
      {label: '16. 10. od 8:00 do 14:00', answer: 0},
      {label: '10. 10. od 9:00 do 15:00', answer: 0}
    ],
    settings: {
      type: 'list',
      multiple: true,
      correct: 2,
      wrong: 2,
      value: 4
    },
    comment: 'Řádné datum voleb je <strong>pátek 8. října od 14:00 do 22:00 a sobota 9. října od 8:00 do 14:00</strong>.'
  },
  {
    basic: true,
    question: 'Volební lídři',
    note: 'Jak dobře znáte celostátní volební lídry? Označte správné jméno u každé fotky. Za každou správnou odpověď jsou dva body.',
    preload: {
      type: 'candidate/list',
      list: [
        'vladimira-vitova',
        'andrej-babis',
        'pavel-sehnal',
        'jan-hamacek',
        'vojtech-filip',
        'radim-spacek',
        'ctirad-musil',
        'ondrej-hysek',
        'ivan-bartos',
        'robert-slachta',
        'tomio-okamura',
        'petr-fiala',
        'zuzana-majerova-zahradnikova',
        'lubomir-volny',
        'magdalena-davis'
      ]
    },
    settings: {
      type: 'faces',
      group: 4,
      correct: 1,
      wrong: 2,
      value: 8
    }
  },
  {
    basic: false,
    question: 'Lídři ve vašem kraji',
    note: 'Celostátní lídři jsou na obrazovkách a sítích vidět často. Ale co ti krajští, znáte je? Označte správně 4 lídry kandidátek z vašeho kraje, za každého máte dva body.',
    preload: {
      type: 'candidate/leaders'
    },
    settings: {
      type: 'leaders',
      preload: true,
      multiple: true,
      correct: 4,
      wrong: 4,
      value: 8,
      region: true
    }
  },
  {
    basic: false,
    question: 'Volební motto',
    note: 'Dokážete správně přiřadit následující volební heslo ke straně? Za dva body.',
    preload: {
      type: 'party/motto'
    },
    settings: {
      type: 'motto',
      preload: true,
      correct: 1,
      wrong: 3,
      value: 2
    }
  },
  {
    basic: false,
    question: 'Klimatické změny',
    note: 'Podíváme se teď na postoje stran k několika otázkám, podle toho, jak na ně odpověděly v rámci volební kalkulačky. Můžete získat až 6 bodů, správně nemusí být ani jedna možnost, nebo všechny.<br><br>Vyberte všechny strany, které na otázku <strong>Souhlasíte s názorem, že vývoj klimatu představuje riziko pro lidskou civilizaci a měli bychom se snažit jeho projevy mírnit?</strong> odpověděly <strong><em>Ano</em></strong> nebo <strong><em>Rozhodně ano</em></strong>.',
    options: [
      {party: 'ano-2011', answer: 0},
      {party: 'pirati-a-starostove', answer: 1},
      {party: 'spolu-ods-kducsl-top09', answer: 1},
      {party: 'svoboda-a-prima-demokracie---spd', answer: 0},
      {party: 'ceska-strana-socialne-demokraticka', answer: 1},
      {party: 'prisaha', answer: 1},
      {party: 'volny-blok', answer: 0},
      {party: 'strana-zelenych', answer: 1},
      {party: 'aliance-narodnich-sil', answer: 0},
      {party: 'monarchiste', answer: 0}
    ],
    settings: {
      type: 'calc',
      show: 3,
      value: 6
    },
    id: 'zp1'
  },
  {
    basic: false,
    question: 'Státní pomoc',
    note: 'Stejně jako u předchozí otázky, vyberte všechny strany, které ve volební kalkulačce na otázku <strong>Považujete pomoc státu v době koronavirové krize za dostatečnou? (programy Antivirus, Pětadvacítka apod)</strong> odpověděly <strong><em>Ano</em></strong> nebo <strong><em>Rozhodně ano</em></strong>.',
    options: [
      {party: 'ano-2011', answer: 1},
      {party: 'pirati-a-starostove', answer: 0},
      {party: 'spolu-ods-kducsl-top09', answer: 0},
      {party: 'svoboda-a-prima-demokracie---spd', answer: 0},
      {party: 'ceska-strana-socialne-demokraticka', answer: 1},
      {party: 'prisaha', answer: 0},
      {party: 'komunisticka-strana-cech-a-moravy', answer: 1},
      {party: 'trikolora-svobodni-soukromnici', answer: 0},
      {party: 'moravane', answer: 0},
      {party: 'levice', answer: 1}
    ],
    settings: {
      type: 'calc',
      show: 3,
      value: 6
    },
    id: 'soc1'
  },
  {
    basic: false,
    question: 'Školství',
    note: 'Do třetice vyberte všechny strany, které ve volební kalkulačce na otázku <strong>Jste pro zachování státní maturity?</strong> odpověděly <strong><em>Ano</em></strong> nebo <strong><em>Rozhodně ano</em></strong>.',
    options: [
      {party: 'ano-2011', answer: 1},
      {party: 'pirati-a-starostove', answer: 0},
      {party: 'spolu-ods-kducsl-top09', answer: 0},
      {party: 'svoboda-a-prima-demokracie---spd', answer: 1},
      {party: 'ceska-strana-socialne-demokraticka', answer: 1},
      {party: 'prisaha', answer: 1},
      {party: 'komunisticka-strana-cech-a-moravy', answer: 1},
      {party: 'moravske-zemske-hnuti', answer: 0},
      {party: 'nevolte-urza-cz', answer: 0},
      {party: 'strana-zelenych', answer: 0}
    ],
    settings: {
      type: 'calc',
      show: 3,
      value: 6
    },
    id: 'sk3'
  },
  {
    basic: true,
    question: 'Opravdu to slibují?',
    note: 'Teď to bude trošku složitější. Musíte rozhodnout, zda následující úryvek textu opravdu je v programu uvedené strany, nebo nikoli. Správná odpověď je za 6 bodů.',
    options: [
      {party: 'pirati-a-starostove', copy: 'Garantujeme seniorům, že nesnížíme důchody a jejich zákonnou valorizaci.', theme: 'duchodova-reforma', answer: 1},
      {party: 'ceska-strana-socialne-demokraticka', copy: 'Ukončíme umělé zvýhodňování elektromobility a jiných ztrátových ekologistických aktivit', theme: 'elektromobilita', answer: 0, correct: 'trikolora-svobodni-soukromnici'},
      {party: 'prisaha', copy: 'Musíme podpořit dostupnost vakcín a zdravotnického materiálu mimo nejbohatší země světa, abychom se skutečně dokázali zbavit pandemie covid-19.', theme: 'ockovani', answer: 0, correct: 'strana-zelenych'}
    ],
    settings: {
      type: 'program',
      value: 6
    }
  },
  {
    basic: false,
    question: 'Je tohle v jejich programu?',
    note: 'A ještě jeden úryvek, u kterého musíte rozhodnout, zda je opravdu v programu uvedené strany, nebo nikoli. Správná odpověď je za 6 bodů.',
    options: [
      {party: 'ano-2011', copy: 'Zavedeme tzv. milionářskou daň, tj. daň z obřích majetků nad 100 milionů korun se sazbou 1 % ročně po vzoru aktuální německé diskuze. Majetky nad 100 milionů budou tedy spadat pod majetkové přiznání.', theme: 'dane', answer: 0, correct: 'ceska-strana-socialne-demokraticka'},
      {party: 'komunisticka-strana-cech-a-moravy', copy: 'NE jakýmkoli vnucovaným kvótám pro přijetí nelegálních migrantů.', theme: 'migrace', answer: 1},
      {party: 'strana-zelenych', copy: 'Převedeme evidenci dat v oblasti exekucí pod stát. Umožníme lidem lépe zjistit, jaké dluhy mají u státu, jeho organizací nebo krajů, měst a obcí.', theme: 'exekuce-a-oddluzeni', answer: 1}
    ],
    settings: {
      type: 'program',
      value: 6
    }
  },
  {
    basic: false,
    question: 'Kdy jsou volby z auta?',
    note: 'Minulé otázky byly určitě náročné, teď si dáme pár otázek k volbám samotným. Pokud je člověk v karanténě a chtěl by volit, může volit z auta. Jsou k tomu určena drive-in stanoviště v každém okrese, stačí mít jen občanku a potvrzení od lékaře nebo hygieny o karanténě. Otázkou za 4 body je, který den se volby z auta konají.',
    options: [
      {label: '6. 10. od 8:00 do 17:00', answer: 1},
      {label: '7. 10. od 8:00 do 17:00', answer: 0},
      {label: '8. 10. od 8:00 do 17:00', answer: 0},
      {label: '9. 10. od 8:00 do 14:00', answer: 0},
      {label: '6. 10. od 10:00 do 14:00', answer: 0},
      {label: '7. 10. od 10:00 do 14:00', answer: 0},
      {label: '8. 10. od 14:00 do 22:00', answer: 0},
      {label: '9. 10. od 8:00 do 12:00', answer: 0}
    ],
    settings: {
      type: 'list',
      correct: 1,
      wrong: 3,
      value: 4
    },
    comment: 'Pokud jste v karanténě a chcete volit, můžete volit <strong>ve středu 6. října od 8:00 do 17:00</strong> na vybraném stanovišti.'
  },
  {
    basic: true,
    question: 'Kdy je můj hlas neplatný?',
    note: 'Při hlasování samotném je nutné si dát pozor na pár chyb, které mohou znamenat, že hlasování bude neplatné. Vyznačte všechny případy, kdy bude hlas neplatný. Tato otázka je za 4 body.',
    options: [
      {label: 'Dám do obálky více volebních lístků', answer: 1},
      {label: 'Dám volební lístek do špatné obálky', answer: 1},
      {label: 'Dám do volební obálky záložku do knihy', answer: 1},
      {label: 'Dám do volební obálky roztrhaný lístek', answer: 1},
      {label: 'Zakroužkuji 5 či více kandidátů', answer: 0},
      {label: 'Pokrčím volební lístek', answer: 0},
      {label: 'Olíznu volební obálku', answer: 0},
      {label: 'Nakreslím na obálku srdíčko', answer: 0}
    ],
    settings: {
      type: 'list',
      multiple: true,
      correct: 2,
      wrong: 2,
      value: 4
    },
    comment: 'Volební lístek by neměl být znehodnocen, <strong>roztrhán</strong>, polit, <strong>nahrazen nějakým jiným lístkem</strong>. Do obálky patří vždy jen jeden. Volební obálka je jiná než ta, ve které chodí volební lístky domů a dostanete ji až ve volební místnosti.'
  },
  {
    basic: true,
    question: 'Kroužkování',
    note: 'U letošních voleb je možné kroužkovat. Až kolika kandidátům lze udělat kroužek kolem volebního čísla a dát mu tak preferenční hlas? Otázka je za dva body.',
    options: [
      {label: '4', answer: 1},
      {label: '3', answer: 0},
      {label: '2', answer: 0},
      {label: '1', answer: 0},
      {label: '5', answer: 0},
      {label: '6', answer: 0},
      {label: '7', answer: 0},
      {label: '8', answer: 0}
    ],
    settings: {
      type: 'list',
      correct: 1,
      wrong: 3,
      value: 2
    },
    comment: 'Můžete zakroužkovat <strong>čtyři kandidáty</strong>, kterým tak dáváte svůj preferenční hlas. Pokud jich zakroužkujete více, tak se na kroužkování nebude brát zřetel, ale hlas pro stranu bude nadále platný. Kroužkovat můžete jen na jedné kandidátní listině, ne napříč stranami. Do volební obálky patří jen jeden lístek.'
  },
  {
    basic: false,
    question: 'Euro',
    note: 'Ještě se vrátíme k postujům stran. Opět můžete získat až 6 bodů, správně nemusí být ani jedna varianta, nebo všechny. Vyberte všechny strany, které ve volební kalkulačce na otázku <strong>Měla by ČR činit aktivní kroky, aby do roku 2030 přijala euro?</strong> odpověděly <strong><em>Ano</em></strong> nebo <strong><em>Rozhodně ano</em></strong>.',
    options: [
      {party: 'ano-2011', answer: 0},
      {party: 'pirati-a-starostove', answer: 1},
      {party: 'spolu-ods-kducsl-top09', answer: 0},
      {party: 'svoboda-a-prima-demokracie---spd', answer: 0},
      {party: 'ceska-strana-socialne-demokraticka', answer: 1},
      {party: 'prisaha', answer: 0},
      {party: 'strana-zelenych', answer: 1},
      {party: 'trikolora-svobodni-soukromnici', answer: 0},
      {party: 'monarchiste', answer: 1},
      {party: 'aliance-narodnich-sil', answer: 0}
    ],
    settings: {
      type: 'calc',
      show: 3,
      value: 6
    },
    id: 'eu1'
  },
  {
    basic: false,
    question: 'Referenda',
    note: 'A tentokrát už naposledy vyberte všechny strany, které ve volební kalkulačce na otázku <strong>Souhlasíte se zavedením referenda, ve kterém by bylo možné hlasovat o zrušení nějakého přijatého zákona?</strong> odpověděly <strong><em>Ano</em></strong> nebo <strong><em>Rozhodně ano</em></strong>.',
    options: [
      {party: 'ano-2011', answer: 1},
      {party: 'pirati-a-starostove', answer: 1},
      {party: 'spolu-ods-kducsl-top09', answer: 0},
      {party: 'svoboda-a-prima-demokracie---spd', answer: 0},
      {party: 'ceska-strana-socialne-demokraticka', answer: 0},
      {party: 'prisaha', answer: 0},
      {party: 'komunisticka-strana-cech-a-moravy', answer: 1},
      {party: 'volny-blok', answer: 1},
      {party: 'moravske-zemske-hnuti', answer: 0},
      {party: 'monarchiste', answer: 0}
    ],
    settings: {
      type: 'calc',
      show: 3,
      value: 6
    },
    id: 'p-r1'
  },
  {
    basic: true,
    question: 'Najdu tohle v programu?',
    note: 'Opět se podíváme do volebních programů. Musíte rozhodnout, zda následující úryvek textu opravdu je v programu uvedené strany nebo nikoli. Správná odpověď je za 6 bodů.',
    options: [
      {party: 'svoboda-a-prima-demokracie---spd', copy: 'Každý máme mít rovná práva. I právo sňatku s tím, koho milujeme. Kohokoliv třetího to neomezí. Naopak to zlepší život tisícům párů i dětí.', theme: 'manzelstvi-pro-vsechny', answer: 0, correct: 'pirati-a-starostove'},
      {party: 'spolu-ods-kducsl-top09', copy: 'Profesionálně a zodpovědně připravíme české předsednictví EU na podzim 2022. Zaručíme, že každý člen vlády bude ovládat alespoň jeden cizí jazyk, kterým se bez problému domluví.', theme: 'predsednictvi-eu', answer: 1},
      {party: 'trikolora-svobodni-soukromnici', copy: 'Prosadíme snížení DPH u výstavby či rekonstrukce standardních bytů z 15 % na 10 %.', theme: 'bydleni', answer: 0, correct: 'spolu-ods-kducsl-top09'}
    ],
    settings: {
      type: 'program',
      value: 6
    }
  },
  {
    basic: false,
    question: 'Volby ze zahraničí',
    note: 'Následuje otázka je za dva body. Pokud byste volili ze zahraničí, budete k tomu určitě potřebovat občanský průkaz nebo cestovní pas ... a pak ještě něco. Vyznačte všechny možné odpovědi.',
    options: [
      {label: 'voličsky průkaz', answer: 1},
      {label: 'být na speciálním seznamu', answer: 1},
      {label: 'řidičský průkaz', answer: 0},
      {label: 'stranickou knížku', answer: 0},
      {label: 'covid pass', answer: 0},
      {label: 'zdravotní kartičku pojištěnce', answer: 0},
      {label: 'víza', answer: 0},
      {label: 'maturitní vysvědčení', answer: 0}
    ],
    settings: {
      type: 'list',
      multiple: true,
      correct: 2,
      wrong: 2,
      value: 2
    },
    comment: 'Volit v zahraničí může každý, kdo má <strong>voličský průkaz nebo je na zvláštím seznamu voličů</strong>, který vede ambasáda. U voleb je nutné se prokázat občanským nebo cestovním průkazem.'
  },
  {
    basic: true,
    question: 'Hlasování',
    note: 'Nejspíš ale půjdete volit v České republice. Co z následujících věcí můžete mít s sebou? Lze vybrat více odpovědí. Za správně zodpovězenou otázku jsou 4 body.',
    options: [
      {label: 'Občanský průkaz', answer: 1},
      {label: 'Cestovní pas', answer: 1},
      {label: 'Respirátor', answer: 1},
      {label: 'Voličský průkaz', answer: 1},
      {label: 'Triko s logem strany', answer: 0},
      {label: 'Nůž', answer: 0},
      {label: 'Volební noviny', answer: 0},
      {label: '3 promile v krvi', answer: 0}
    ],
    settings: {
      type: 'list',
      multiple: true,
      correct: 2,
      wrong: 2,
      value: 4
    },
    comment: 'K samotnému hlasování není potřeba moc věcí. Stačí <strong>občanský průkaz nebo cestovní pas</strong>. Pokud máte, mějte <strong>voličský průkaz</strong>. Respirátor bude vyžadován, mějte ho prosím. Na ostatní věci pozor, nesmíte být opilí, propagovat nějakou stranu, být ozbrojen, apod.'
  },
  {
    basic: true,
    question: 'Voličský průkaz',
    note: 'U této otázky jde o další 4 body. Další užitečnou věcí je voličský průkaz, který si až do středy 6. 10. můžete vyřídit na úřadě v místě svého trvalého bydliště. Z uvedených možností vyberte, kde díky němu můžete hlasovat.',
    options: [
      {label: 've volební místnosti podle trvalého bydliště', answer: 1},
      {label: 've vedlejší obci', answer: 1},
      {label: 'v libovolné volební místnosti v ČR', answer: 1},
      {label: 'na ambasádě', answer: 1},
      {label: 'poštou', answer: 0},
      {label: 'na Czechpointu', answer: 0},
      {label: 'na úřadě', answer: 0},
      {label: 'online', answer: 0}
    ],
    settings: {
      type: 'list',
      multiple: true,
      correct: 2,
      wrong: 2,
      value: 4
    },
    comment: 'S voličským průkazem můžete volit <strong>v jakékoli volební místnosti, v místě trvalého bydliště, kdekoli v České republice nebo na ambasádě</strong>. Pokud ho máte a jste v karanténě, musíte ho k volbám předložit také.'
  },
  {
    basic: true,
    question: 'Kde kandidují?',
    note: 'Každý člověk může kandidovat jen v jednom kraji. Kde tedy kandidují ti nejvíce viditelní lídři, předsedové stran, apod? Za každou správnou odpověď jsou dva body.',
    preload: {
      type: 'candidate/list',
      list: [
        'vladimira-vitova',
        'andrej-babis',
        'pavel-sehnal',
        'jan-hamacek',
        'vojtech-filip',
        'radim-spacek',
        'ctirad-musil',
        'ondrej-hysek',
        'ivan-bartos',
        'robert-slachta',
        'tomio-okamura',
        'petr-fiala',
        'zuzana-majerova-zahradnikova',
        'lubomir-volny',
        'magdalena-davis'
      ]
    },
    settings: {
      type: 'region',
      group: 4,
      correct: 1,
      wrong: 2,
      value: 8
    }
  },
  {
    basic: true,
    question: 'Výsledky voleb',
    note: 'A poslední otázka za dva body. Kdy se dozvíme výsledky voleb?',
    options: [
      {label: 'v sobotu večer, případně v neděli ráno', answer: 1},
      {label: 'v sobotu ve 14:00', answer: 0},
      {label: 'na konci měsíce', answer: 0},
      {label: 'příští neděli', answer: 0},
      {label: '28. října', answer: 0},
      {label: 'týden před zasedáním Sněmovny', answer: 0},
      {label: 've středu', answer: 0},
      {label: 'v neděli v 20:00', answer: 0}
    ],
    settings: {
      type: 'list',
      correct: 1,
      wrong: 3,
      value: 2
    },
    comment: 'Výsledky voleb bývají poměrně jasné už <strong>v sobotu večer</strong>, do nedělního rána by měly být kompletní. Samozřejmě strany i občané mají možnost výsledky napadnout, až poté je oficiální vyhlášení. V sobotu ve 14:00 hodin bývají k dispozici exit polls, výsledky průzkumu u volebních místností.'
  }
];
