import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-guide-form',
	data: function () {
		return {
			phase: 0,
			selected: {
				region: null,
				parties: [],
				tags: [],
				topics: [],
				form: 'osobne'
			}
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			return this.$store.getters.parties().filter(x => x.status === 1).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		tags: function () {
			return this.$store.getters.pdv('program/tags');
		}
	},
	methods: {
		betterURL,
		confirm: function () {
			var query = [];

			query.push('kraj=' + (this.selected.region === 'abroad' || this.selected.form === 'zahranici' ? 'ustecky-kraj' : this.selected.region));
			query.push('strany=' + this.selected.parties.join(','));
			query.push('tema=' + this.selected.tags.join(','));
			query.push('otazky=' + this.selected.topics.join(','));
			query.push('form=' + (this.selected.region === 'abroad' ? 'zahranici' : this.selected.form));

			this.$router.push('/pruvodce-volbami/na-miru?' + query.join('&'));
		},
		toggle: function (hash, list) {
			if (this.selected[list].find(x => x === hash)) {
				this.selected[list].splice(this.selected[list].findIndex(x => x === hash), 1);
			} else {
				this.selected[list].push(hash);
			}
		},
		go: function (i) {
			this.phase = i;
			window.scrollTo(0, 0);
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Průvodce volbami"});
  }
}
