export default {
	name: 'Gap',
	props: ['height', 'background'],
	computed: {
		_height: function () {
			if (this.height || this.height === 0) {
				return this.height
			} else {
				return 2;
			}
		}
	}
};
