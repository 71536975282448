import axios from 'axios';

export default {
	name: 'test',
	data: function () {
		return {
			list: [],
			plist: [],
			sql: '',
			psql: '',
			poll: {
				pass: '',
				meta: {
					date: '',
					agency: 'Kantar CZ',
					type: null,
					link: '',
				},
				data: {
					'kdu-csl': null,
					'strana-zelenych': null,
					'ceska-strana-socialne-demokraticka': null,
					'komunisticka-strana-cech-a-moravy': null,
					'obcanska-demokraticka-strana': null,
					'starostove-a-nezavisli': null,
					'svobodni': null,
					'ceska-piratska-strana': null,
					'top-09': null,
					'ano-2011': null,
					'svoboda-a-prima-demokracie---spd': null,
					'trikolora-hnuti-obcanu': null,
					'pirati-a-starostove': null,
					'spolu-ods-kducsl-top09': null,
					'trikolora-svobodni-soukromnici': null,
					'prisaha': null,
					'aliance-pro-budoucnost': null
				},
				response: null
			},
			agencies: [
				'Kantar CZ',
				'KANTAR - alternativní scénář',
				'MEDIAN',
				'Ipsos',
				'STEM',
				'STEM - alternativní',
				'Phoenix Research',
				'SANEP',
				'CVVM',
				'Data Collect',
				'Data Collect - strany'
			],
			gen: {
				type: false,
				result: null
			}
		}
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		popularity: function () {
			return this.$store.getters.pdv('popularity/last')
		},
		data: function () {

			if (!this.popularity) return;

			this.list = [];

			// this.$store.getters.parties().forEach(party => {
			// 	if (party.links && party.links.length > 0) {
			// 		party.links.forEach(link => {
			//
			// 			var fb = link.link.split('facebook.com').length > 1;
			// 			var tw = link.link.split('twitter.com').length > 1;
			// 			var ig = link.link.split('instagram.com').length > 1;
			//
			// 			if (fb || tw || ig) {
			// 				var o = {};
			// 				o.hash = party.hash;
			// 				o.link = link.link;
			// 				o.platform = fb ? 'fb' : (tw ? 'tw' : 'ig');
			// 				o.related = null;
			//
			// 				if (party.support) o.related = party.support;
			// 				if (party.coalition && party.coalition.split(',').length === 1) o.related = party.coalition;
			//
			// 				o.val = null;
			// 				o.value = 0;
			//
			// 				var x = this.popularity.list.find(x => x.hash === party.hash);
			//
			// 				if (x) o.val === x.value;
			//
			// 				if (!link.region) this.list.push(o);
			// 			}
			// 		});
			// 	}
			//
			// 	if (party.leader && party.leader[0] && party.leader[0].links && party.leader[0].links.length > 0) {
			// 		party.leader[0].links.forEach(link => {
			//
			// 			var fb = link.link.split('facebook.com').length > 1;
			// 			var tw = link.link.split('twitter.com').length > 1;
			// 			var ig = link.link.split('instagram.com').length > 1;
			//
			// 			if (fb || tw || ig) {
			// 				var o = {};
			// 				o.hash = party.leader[0].hash;
			// 				o.link = link.link;
			// 				o.platform = fb ? 'fb' : (tw ? 'tw' : 'ig');
			// 				o.related = party.hash;
			//
			// 				var n = this.$store.getters.party(party.leader[0].nominee);
			//
			// 				if (n.hash != party.hash) {
			// 					o.related += ',' + n.hash;
			// 				}
			//
			// 				o.val = null;
			// 				o.value = 0;
			//
			// 				var x = this.popularity.list.find(x => x.hash === party.leader.hash);
			//
			// 				if (x) o.val === x.value;
			//
			// 				this.list.push(o);
			// 			}
			// 		});
			// 	}
			// })

			if (this.popularity) {
				this.popularity.list.forEach(item => {
					var l = this.list.find(x => x.link === item.link);

					if (!l) {
						l = item;
						l.val = null;

						this.list.push(l);
					} else {
						l.value = item.value;
						l.val = null;
					}
				});
			}

			this.list.sort((a, b) => a.platform.localeCompare(b.platform, 'cs'));

			return this.list;
		}
	},
	methods: {
		add: function () {

			var arr = [
				'null',
				this.poll.meta.day,
				this.poll.meta.agency,
				(this.poll.meta.type || 'null'),
				this.poll.meta.link
			];

			Object.keys(this.poll.data).forEach(key => {
				arr.push(this.poll.data[key] || 'null');
			});

			arr.push(1);

			axios.post(this.$store.state.api + 'admin/poll-add', {
				// key: this.sid,
				pass: this.poll.pass,
				data: arr
			}).then((response) => {
				if (response.data.code === 200) {
					this.poll.response = 'Uloženo';

					setTimeout(() => {
						this.poll.response = null
					}, 2000);

					Object.keys(this.poll.data).forEach(key => {
						this.poll.data[key] = null;
					});

					this.poll.meta.day = '';
					this.poll.meta.link = '';
					this.poll.meta.type = null;
				} else {
					this.poll.response = 'Chyba';

					setTimeout(() => {
						this.poll.response = null
					}, 2000);
				}
			})
		},
		generate: function () {
			var s = [];

			this.list.forEach(item => {
				if (item.val) {
					var i = [
						'(null',
						'"2021-09-30"',
						'"' + item.hash + '"',
						item.related ? '"' + item.related + '"' : 'null',
						'"' + item.platform + '"',
						'"' + item.link + '"',
						item.val + ')'
					];

					s.push(i.join(', '));
				}
			})

			this.sql = 'INSERT INTO popularity VALUES ' + s.join(', ');
		},
		pgenerate: function () {
			var s = [];

			this.plist.forEach(item => {
				// if (item.amount) {
					var i = [
						'(null',
						'"' + item.party.hash + '"',
						'"2021-09-30"',
						(item.amount || 0),
						(item.income || 0),
						(item.spend || 0) + ')'
					];

					s.push(i.join(', '));
				// }
			})

			this.psql = 'INSERT INTO budget VALUES ' + s.join(', ');
		},
		genCreate: function () {
			var list = [];

			this.$store.state.static.questions.list.forEach(q => {
				if (this.gen.type === true || q.priority === 1) {
					var obj = [
						q.id,
						Math.round(Math.random()) > .5 ? 3 : 1,
						Math.round(Math.random()) + 1
					];

					list.push(obj);
				}
			});

			this.gen.result = JSON.stringify(list);
		}
	},
	mounted: function () {
		this.$store.getters.parties().forEach(party => {
			var obj = {
				party,
				acc: party.account,
				amount: null,
				income: null,
				spend: null
			}

			if (party.account && party.status === 1) this.plist.push(obj);
		});
	}
};
