export default {
  source: 'https://www.plzensky-kraj.cz/informace-o-umisteni-volebnich-stanovist-drive-in-3',
  list: [
    {
      name: "Parkoviště DEPO 2015",
      address: "Presslova 14, Plzeň",
      designated: "Plzeň-město",
      maps: {
        "link": 'ragohevage',
        "embed": 'ragohevage'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Nepomucká 1139, Přeštice",
      designated: "Plzeň-jih",
      maps: {
        "link": 'fomocatoho',
        "embed": 'fomocatoho'
      }
    },
    {
      name: "Dětské dopravní hřiště",
      address: "Revoluční 570, Třemošná",
      designated: "Plzeň-sever",
      maps: {
        "link": 'hosuzuzafa',
        "embed": 'hosuzuzafa'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Sadová 635, Domažlice",
      designated: "Domažlice",
      maps: {
        "link": 'fototajace',
        "embed": 'fototajace'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Sokolovská 1070, Tachov",
      designated: "Tachov",
      maps: {
        "link": 'lovamumago',
        "embed": 'lovamumago'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Roháčova 773, Rokycany",
      designated: "Rokycany",
      maps: {
        "link": 'hazegusona',
        "embed": 'hazegusona'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Za Kasárny 324, Klatovy",
      designated: "část okresu Klatovy, vymezená správními obvody ORP Klatovy a ORP Horažďovice",
      maps: {
        "link": 'jerajakosu',
        "embed": 'jerajakosu'
      }
    },
    {
      name: "Správa a údržba silnic Plzeňského kraje",
      address: "Pražská 917, Sušice",
      designated: "část okresu Klatovy, vymezená správním obvodem ORP Sušice",
      maps: {
        "link": 'hanudusozo',
        "embed": 'hanudusozo'
      }
    }
  ]
}
