export default {
	name: 'layout-changes',
	data: function () {
		return {
			hide: []
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		answers: function () {

			var list = [];

			this.parties.forEach(party => {
				if (party.interestCount > 0) list.push(party.hash);
			});

			return this.$store.getters.pdv('calc/fetch/' + list.join(','));
		},
		list: function () {
			if (!this.answers) return [];

			var list = this.$store.state.static.questions.list;

			list.forEach(q => {
				this.parties.forEach(party => {
					q.answers[party.hash] = {
						answer: 0,
						comment: null,
						importance: 0
					}
				});
			});

			this.answers.list.forEach(item => {
				if (item.status === 1) {
					var q = list.find(x => x.id === item.question).answers[item.hash];

					q.answer = item.answer;
					q.comment = (!item.comment || item.comment.length === 0) ? null : item.comment;
					q.importance = item.importance;
				}
			});

			return list;
		},
		parties: function () {
			var list = [];

			this.$store.getters.parties().forEach(party => {
				if (party.interestCount > 0) list.push(party);
			});

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		},
		partiesAll: function () {
			var list = [];

			this.$store.getters.parties().filter(x => x.status === 1).forEach(x => list.push(x));

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		}
	},
  methods: {
		filterToggle: function (party) {
			if (this.hide.find(x => x === party)) {
				this.hide.splice(this.hide.findIndex(x => x === party), 1);
			} else {
				this.hide.push(party);
			}
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Porovnání odpovědí"});
  }
};
