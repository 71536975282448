export default {
	name: 'program-block-dynamic',
	props: ['hash', 'src', 'headline'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		data: function () {
			return this.$store.getters.getSource('volby/psp/2021/program/' + this.hash + '/' + this.src);
		}
	}
};
