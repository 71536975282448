export default {
  all: [48.93, 31.46, 47.72, 5.85, 11.98],
  regions: [
    [50.61, 31.39, 53.72, 4.53, 18.61],
    [49.26, 33.47, 43.17, 6.53, 11.09],
    [48.8 , 30.42, 47.29, 7.23, 13.86],
    [48,97, 31,96, 47.77, 5.84,  9.62],
    [50.63, 32.99, 44.33, 3.61, 12.89],
    [47.05, 27.61, 50.40, 8.04,  9.92],
    [49.24, 30.89, 43.50, 4.47, 10.16],
    [50.24, 33.58, 50.55, 5.90, 14.02],
    [47.32, 31.49, 42.91, 9.00,  9.00],
    [47.85, 35.39, 41.88, 7.14, 10.71],
    [48.94, 32.11, 47.82, 4.54, 13.44],
    [49.02, 30.85, 53.19, 6.65, 10.90],
    [48.21, 32.72, 46.48, 5.20, 10.09],
    [48.51, 28.10, 47.99, 4.56,  8.94],
  ]
}
