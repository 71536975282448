import {betterURL, date} from '@/common/helpers';

export default {
	name: 'party-program',
	props: ['hash'],
	data: function () {
		return {}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			return this.$(this.hash);
		},
		program: function () {
			return this.$store.getters.pdv('program/fetch/' + this.hash);
		},
		chunks: function () {
			var list = [];
			var str = null;

			if (this.program && this.program.list && this.program.list.program) {
				if (this.program.list.program.chunks && this.program.list.program.chunks != "") {
					str = this.program.list.program.chunks.split("\\").join("");
					list = JSON.parse(str);
				} else {
					list = [{headline: "Probíhá zpracování"}];
				}
			}

			return {str, list};
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		audio: function () {
			if (!this.program) return;
			if (!this.program.list) return;
			if (!this.program.list.program) return;
			if (!this.program.list.program.audio) return;

			return JSON.parse(this.program.list.program.audio);
		},
		audioembed: function () {
			if (!this.audio) return;

			var ap = this.audio.find(x => x.type === 'podcast');

			if (!ap) return;

			return ap.href.split('podcasts').join('embed.podcasts');
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		},
		tagsUsed: function () {
			var list = [];

			if (this.tags && this.program && this.program.list && this.program.list.tags) {
				this.program.list.tags.forEach(tag => {
					var chunks = tag.chunk.split(',');

					chunks.forEach(chunk => {

						var tu = this.tags.find(x => x.hash === tag.tag);

						if (tu) {
							var obj = {
								src: chunk,
								icon: tu.icon,
								name: tu.name,
								tag: tag.tag
							}

							list.push(obj);
						}
					});
				});
			}

			return list;
		}
	},
	methods: {
		betterURL, date,
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: 'Volební program: ' + self.party.name});
					setTimeout(() => {
						if (location.hash != '') {
							this.$el.querySelector("a[name=" + location.hash.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
						}
					}, 1500);
				}
			}, 200);
		},
		backtop: function () {
			if (this.$refs.tableofcontent) this.$refs.tableofcontent.$el.scrollIntoView({behavior: "smooth", block: "center"});
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
