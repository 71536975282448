export default {
	name: 'party-filter',
	props: ['attr', 'value', 'operand'],
	computed: {
		parties: function () {
			var list = this.$store.getters.parties().filter(x => x.status === 1);

			if (this.attr) {
				if (this.operand) {
					if (this.operand === 'higher') {
						list = list.filter(x => x[this.attr] > this.value);
					}
				} else {
					list = list.filter(x => x[this.attr] === this.value);
				}
			}

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));
			return list;
		},
		status: function () {
			if (this.$store.state.hide.length === 0) return null;
			if (this.$store.state.hide.length === 1) return 'jedna skrytá strana';
			if (this.$store.state.hide.length < 5) return this.$store.state.hide.length + ' skryté strany';
			return this.$store.state.hide.length + ' skrytých stran';
		}
	},
	methods: {
		handleClick: function (party) {
			this.$store.commit('partyHideToggle', party);
		},
		handleHideAll: function () {
			this.$store.commit('partyHideAll');
		},
		handleShowAll: function () {
			this.$store.commit('partyHideNone');
		}
	}
};
