export default {
  source: 'https://t.co/fFYBzqp9G6?amp=1',
  list: [
    {
      name: "dvůr Magistrátu města Hradec Králové",
      address: "Československé armády 408/51, Hradec Králové",
      designated: "Hradec Králové",
      maps: {
        "link": 'catabujeju',
        "embed": 'catabujeju'
      }
    },
    {
      name: "budova bývalých kasáren",
      address: "Československé armády 162, Jičín",
      designated: "Jičín",
      maps: {
        "link": 'bazudobutu',
        "embed": 'bazudobutu'
      }
    },
    {
      name: "budova Celního úřadu",
      address: "Kladská 272, Náchod",
      designated: "Náchod",
      maps: {
        "link": 'bazahonopo',
        "embed": 'bazahonopo'
      }
    },
    {
      name: "budova vrátnice Centra odborné přípravy Vyšší odborné školy a Střední průmyslové školy v Rychnově nad Kněžnou",
      address: "Na Jamách 1180, Rychnov nad Kněžnou",
      designated: "Rychnov nad Kněžnou",
      maps: {
        "link": 'honecehafa',
        "embed": 'honecehafa'
      }
    },
    {
      name: "budova Hasičské zbrojnice - garáž",
      address: "Dlouhá 752, Trutnov – Horní Staré Město",
      designated: "Trutnov",
      maps: {
        "link": 'mopadavaru',
        "embed": 'mopadavaru'
      }
    }
  ]
}
