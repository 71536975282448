export default {
  source: '',
  list: [
    {
      name: "Parkoviště za Podjezdem",
      address: "Jeseník, za Podjezdem",
      designated: "Jeseník",
      maps: {
        "link": 'marokomagu',
        "embed": 'marokomagu'
      }
    },
    {
      name: "SSOK",
      address: "Olomouc, Lipenská 753/120",
      designated: "Olomouc",
      maps: {
        "link": 'pobepokaha',
        "embed": 'pobepokaha'
      }
    },
    {
      name: "parkoviště u koupaliště",
      address: "Prostějov, místní část Vrahovice, ul. Mikoláše Alše",
      designated: "Prostějov",
      maps: {
        "link": 'lamadesabe',
        "embed": 'lamadesabe'
      }
    },
    {
      name: "parkoviště před letištěm",
      address: "letiště Bochoř",
      designated: "Přerov",
      maps: {
        "link": 'potugucude',
        "embed": 'potugucude'
      }
    },
    {
      name: "SSOK",
      address: "Vikýřovice, Ztracená 684",
      designated: "Šumperk",
      maps: {
        "link": 'posupumovu',
        "embed": 'posupumovu'
      }
    }
  ]
}
