export default {
	name: 'share-block',
	props: ['copy', 'title', 'middle', 'link'],
	data: function () {
		return {
			copied: false
		}
	},
	computed: {
		path: function () {
			return 'https://2021.programydovoleb.cz' + (this.link || this.$route.fullPath.split('#')[0]);
		}
	},
	methods: {
		copyLink: function () {
			navigator.clipboard.writeText(this.path);
			this.copied = true;

				this.$store.dispatch("ge", {
					action: "copy",
					category: "share",
					label: this.$route.fullPath
				});

			setTimeout(() => this.copied = false, 1000);
		}
	}
};
