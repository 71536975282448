import axios from 'axios';

export default {
	name: 'admin-motto',
	props: ['hash', 'sid', 'data', 'about'],
	data: function () {
		return {
			list: [],
			addMotto: ''
		}
	},
	methods: {
		remove: function (item) {
			axios.post(this.$store.state.api + 'admin/motto-remove', {
				key: this.sid,
				motto: item.motto,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.motto === item.motto).removed = true;
				}
			})
		},
		add: function () {
			axios.post(this.$store.state.api + 'admin/motto-add', {
				key: this.sid,
				motto: this.addMotto,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						motto: response.data.motto,
						removed: false
					})

					this.addLink = '';
				}
			})
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			this.list.push({
				motto: item.motto,
				removed: false
			});
		})
	}
};
