export default {
	name: 'main-header',
	props: ['logo', 'name'],
	data: function () {
		return {
			showMenu: false,
			showMenu2: false
		}
	},
	watch:{
    $route (){
        this.showMenu = false;
    }
}
};
