import {beautifyNumber, betterURL, getLinkType} from "@/common/helpers";

export default {
	name: 'party-regional',
	props: ['hash', 'reg'],
	data: function () {
		return {
			regions: ['Praha', 'Středočeský', 'Jihočeský', 'Plzeňský', 'Karlovarský', 'Ústecký', 'Liberecký', 'Královéhradecký', 'Pardubický', 'Vysočina', 'Jihomoravský', 'Olomoucký', 'Zlínský', 'Moravskoslezský'],
			pollsShowAll: false
		}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		regionName: function () {
			var name = this.$store.state.static.regions.find(x => betterURL(x) === this.reg);

			return name || 'Nezařazení kandidáti';
		},
		party: function () {
			var data = this.$store.getters.pdv('party/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		peopleSource: function () {
			if (!this.party) return undefined;

			var fce = 'candidate/party/' + this.hash;

			if (this.party.status != 1 && this.party.reg) {
				fce = 'candidate/reg/' + this.party.reg;
			}

			var data = this.$store.getters.pdv(fce);

			return data ? data.list : undefined;
		},
		people: function () {
			if (!this.peopleSource) return undefined;

			var regions = undefined;

			this.$store.state.static.regions.forEach((region, index) => {
				var obj = {
					name: region,
					list: this.peopleSource.filter(x => x.region === index),
					links: this.party.links.filter(x => typeof x.region === 'number' && x.region === index).map(x => x.link),
					contacts: this.party.contacts.filter(x => typeof x.region === 'number' && x.region === index).map(x => x.value)
				}

				if (betterURL(region) === this.reg) regions = obj;
			});

			if (this.peopleSource.filter(x => x.region === -1).length > 0) {
				var obj = {
					name: "Nezařazeno",
					list: this.peopleSource.filter(x => x.region === -1),
					links: []
				}

				if ('nezarazeno' === this.reg) regions = obj;
			}

			if (regions.list) {
				regions.list.sort((a, b) => (a.position || 100) - (b.position || 100))
			}

			return regions;
		},
		leader: function () {
			var lead = undefined;

			if (this.people) {
				lead = this.people.list.find(x => x.position === 1);
			}

			return lead;
		},
		links: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (typeof x.region != 'undefined') {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		embeds: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (x.embed === 1 && !x.region) {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		width: function () {
			return (window.innerWidth > 450 ? 444 : window.innerWidth - 64)
		},
		linksSelected: function () {
			var list = [];

			var prep = [];

			this.party.links.forEach(link => {
				if (link.region && this.$store.state.static.regions.findIndex(x => betterURL(x) === this.reg) === link.region) prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			// // console.log(this.party.links.length, prep, this.$store.state.static.regions.findIndex(x => betterURL(x) === this.reg));

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var link = prep.find(x => x.type === 'link');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (link) list.push(link);
			if (wiki) list.push(wiki);

			return list;
		},
		
	},
	methods: {
		beautifyNumber,
		betterURL,
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('youtube').length > 1) return 'yt';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		},
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: self.party.name + ', kandidátka pro ' + this.regionName});
				}
			}, 100);
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
