import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-candidates',
	data: function () {
		return {
			search: ''
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		candidates: function () {
			return this.$store.getters.pdv('candidate/all');
		},
		sorted: function () {
			if (!this.candidates) return;

			var list = this.candidates.list

			list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));

			return list;
		},
		results: function () {
			var list = [];

			if (this.search.length > 2) {
				list = this.candidates.list.filter(x => x.hash.indexOf(betterURL(this.search)) > -1);
				list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));
			}

			return list;
		}
	},
	methods: {
		regional: function (index) {
			var list = [];

			list = this.candidates.list.filter(x => x.region === index && x.position === 1);
			list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));

			return list;
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Přehled všech kandidátů"});
  }
}
