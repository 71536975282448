import NewsFeed from "@/components/news-feed/do";
import ResultsGraph from "@/components/results-graph/do";
import {beautifyNumber, betterURL, date} from "@/common/helpers";
import {results2017, db as db2017} from '@/helpers/votes-imperiali';
import {results2021, db as db2021} from '@/helpers/votes-imperiali-2021';

export default {
	name: 'layout-homepage-results',
	data: function () {
		return {
			results2017,
			results2021,
			db2017,
			db2021,
			partiesVotes: []
		}
	},
  components: {
		NewsFeed,
		ResultsGraph
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		elected: function () {
			return this.$store.getters.pdv('party/elected')
		},
		regions: function () {
			return this.$store.state.static.regionsShort
		},
		data: function () {

			var list = ['spolu-ods-kducsl-top09', 'ano-2011', 'pirati-a-starostove', 'svoboda-a-prima-demokracie---spd', 'prisaha', 'ceska-strana-socialne-demokraticka', 'komunisticka-strana-cech-a-moravy', 'trikolora-svobodni-soukromnici', 'volny-blok', 'strana-zelenych'];

			var obj = {
				about: {
					agency: 'Výsledky',
					amount: 5414637,
					date: '2021-10-09',
					source: 'https://volby.cz/pls/ps2021/ps2?xjazyk=CZ'
				},
				list: [],
				mandates: []
			}

			list.forEach(hash => {
				var party = this.$(hash);

				var o1 = {
					mandates: party.mandates || 0,
					party
				}

				var o2 = {
					color: party.color,
					hash,
					logo: party.logo,
					pct: party.results,
					short: party.short
				}

				obj.list.push(o2);
				obj.mandates.push(o1);

			});

			return obj;
		}
	},
  methods: {
		beautifyNumber, betterURL, date,
		rnd: function (res, r_optional) {
			var r = r_optional || 100;

			return Math.round(res * r) / r;
		},
		pct: function (value, base, r_optional) {
			var res = value / base * 100;

			return this.rnd(res, r_optional);
		},
		partyInYear: function (hash, year) {
			return this['results20' + year].find(x => x.hash === hash);
		},
		vopir: function (year, list, region) { // votesOfPartiesInRegion
			var sum = 0;

			list.forEach(item => {
				sum += this.partyInYear(this.$(item).hash, year).votes[region];
			})

			return sum;
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Výsledky voleb 2021"});

		this.partiesVotes.push({party: this.$('spolu-ods-kducsl-top09'), y17: [1, 53, 721], y21: ['spolu-ods-kducsl-top09'], note: "Rok 2017 je součtem zisku hlasů ODS, KDU-ČSL a TOP 09."});
		this.partiesVotes.push({party: this.$(768), y17: [768], y21: [768]});
		this.partiesVotes.push({party: this.$('pirati-a-starostove'), y17: [166, 720], y21: ['pirati-a-starostove'], note: "Rok 2017 je součtem zisku hlasů České pirátské strany a Starostů a nezávislých."});
		this.partiesVotes.push({party: this.$(1114), y17: [1114], y21: [1114]});
		this.partiesVotes.push({party: this.$(7), y17: [7], y21: [7]});
		this.partiesVotes.push({party: this.$(47), y17: [47], y21: [47]});
  }
};
