import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-ministers',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		ministers: function () {
			return this.$store.getters.pdv('ministers/all');
		}
	},
	methods: {
		mins: function (hash) {
			var list = [];

			this.ministers.ministers.filter(x => x.ministry === hash).forEach(m => list.push(m));
			// console.log(list[0]);
			list.sort((a, b) => a.person.split('-')[a.person.split('-').length - 1].localeCompare(b.person.split('-')[b.person.split('-').length - 1], 'cs'));
			// list.sort((a, b) => a.hash.localeCompare(b.hash, 'cs'));

			return list;
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Přehled kandidátů na ministry"});

		var timer;

		var hash = location.hash.split('#')[1];
		var url = this.$store.state.static.ministry.find(x => x.hash === hash);

		if (location.hash != '' && url) {
			timer = setInterval(() => {
				var el = this.$el.querySelector("a[name=" + betterURL(url.name) + "]")

				if (this.ministers && el) {
					el.scrollIntoView({behavior: "smooth", block: "center"});
					clearInterval(timer);
				}
			}, 250);
		}
  }
}
