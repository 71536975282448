export default {
	name: 'layout-parties',
	data: function () {
		return {
			selection: -1
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			var list = [];

			this.$store.getters.parties().forEach(party => {
				if (this.selection === -1 && party.status < 4 || this.selection === party.status) {
					list.push(party);
				}
			});

			list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

			return list;
		}
	},
	methods: {
		select: function (value) {
				this.selection = -2;

				setTimeout(() => this.selection = value, 100);
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Přehled stran a hnutí"});
  }
}
