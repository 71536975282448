import { render, staticRenderFns } from "./src/template.html?vue&type=template&id=04776c43&scoped=true&"
import script from "./src/script.js?vue&type=script&lang=js&"
export * from "./src/script.js?vue&type=script&lang=js&"
import style0 from "./src/style.scss?vue&type=style&index=0&id=04776c43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04776c43",
  null
  
)

export default component.exports