export default {
	name: 'layout-changes',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		changelog: function () {
			return this.$store.getters.pdv('changes/fetch')
		}
	},
  methods: {
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Changelog"});
  }
};
