import axios from 'axios';

export default {
	name: 'admin-links',
	props: ['hash', 'sid', 'data', 'region', 'open'],
	data: function () {
		return {
			list: [],
			addLink: ''
		}
	},
	methods: {
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('youtube').length > 1) return 'yt';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/link-remove', {
				key: this.sid,
				src: src.link,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.link === src.link).removed = true;
				}
			})
		},
		add: function () {
			var embedeable = 0;

			if (this.addLink.split('twitter.com').length > 1) embedeable = 1;

			axios.post(this.$store.state.api + 'admin/link-add', {
				key: this.sid,
				src: this.addLink,
				embed: embedeable,
				region: this.region || 'null',
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						link: response.data.link,
						type: this.getLinkType(response.data.link),
						removed: false
					})

					this.addLink = '';
				}
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				// if ((this.region === null && !src.region) || (!this.region && src.region === null) || this.region === src.region) {
				if ((this.region === null && typeof src.region != 'number') || (!this.region && typeof src.region != 'number') || this.region === src.region) {
					this.list.push({
						link: src.link,
						type: this.getLinkType(src.link),
						removed: false
					});
				}
			})
		}
	}
};
