import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PersonSummary',
	props: ['person'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		region: function () {
			var obj = {
				link: '/',
				label: 'nezařazeno'
			}

			if (this.person) {
				obj.link = '/strana/' + this.person.party + '/nezarazeno';

				if (this.person.region > -1) {
					var r = this.$store.state.static.regions[this.person.region];

					obj.link = '/strana/' + betterURL(r) + '/nezarazeno';
					obj.label = r;
				}
			}

			return obj;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
