import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'admin-person-core',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			values: [],
			district: null,
			town: null,
			alert: null,
			sending: false,
			search: ''
		}
	},
	methods: {
		save: function () {
			var data = {
				name: [
					this.values.find(x => x.id === 'title_before').content,
					this.values.find(x => x.id === 'name_first').content,
					this.values.find(x => x.id === 'name_family').content,
					this.values.find(x => x.id === 'title_after').content
				],
				work: this.values.find(x => x.id === 'work').content,
				age: this.values.find(x => x.id === 'age').content,
				sex: this.values.find(x => x.id === 'sex').content,
				home: {
					name: this.values.find(x => x.id === 'home').content,
					id: this.values.find(x => x.id === 'home').contentNum
				}
			}

			this.sending = true;
			axios.post(this.$store.state.api + 'admin/core-update', {
				key: this.sid,
				hash: this.hash,
				values: data
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		},
		setValue: function (source, destination) {
			destination.contentNum = source.id;
			destination.content = source.name;
		}
	},
	computed: {
		towns: function () {
			return this.$store.getters.getSource('obecne/obce');
		},
		districts: function () {
			var list = [];

			if (this.towns) {
				list = this.towns.areas.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		},
		townsInDistrict: function () {
			var list = [];

			if (this.towns && this.district) {
				list = this.towns.list.filter(x => x.in === this.district);
				list = list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

				if (this.district === 1199) {
					list.push({
						id: 554782,
						name: 'Praha'
					})
				}
			}

			return list;
		},
		found: function () {
			var list = [];

			if (this.towns) {
				if (this.search.length === 0) {
					list.push({
						name: 'Praha',
						id: 554782
					});
					list.push({
						name: 'Brno',
						id: 554782
					});
					list.push({
						name: 'České Budějovice',
						id: 544256
					});
					list.push({
						name: 'Hradec Králové',
						id: 569810
					});
					list.push({
						name: 'Jihlava',
						id: 586846
					});
					list.push({
						name: 'Karlovy Vary',
						id: 554961
					});
					list.push({
						name: 'Liberec',
						id: 563889
					});
					list.push({
						name: 'Olomouc',
						id: 500496
					});
					list.push({
						name: 'Ostrava',
						id: 554821
					});
					list.push({
						name: 'Pardubice',
						id: 555134
					});
					list.push({
						name: 'Plzeň',
						id: 554791
					});
					list.push({
						name: 'Ústí nad Labem',
						id: 554804
					});
					list.push({
						name: 'Zlín',
						id: 585068
					});
				} else if (this.search.length < 3 && ['a', 'e'].indexOf(this.search.charAt(0).toLowerCase()) > -1) {
					list.push({
						name: 'Aš',
						id: 554499
					});
					list.push({
						name: 'Eš',
						id: 561754
					});
				} else if (this.search.length > 2) {
					list = this.towns.list.filter(x =>  betterURL(x.name).split(betterURL(this.search)).length > 1).sort((a, b) => a.name.localeCompare(b.name, 'cs'));
				}
			}

			return list;
		}
	},
	mounted: function () {
		this.values.push({
			label: 'Permalink',
			id: 'hash',
			content: '/kandidat/' + this.data.hash,
			type: 'text',
			empty: false,
			editable: false
		})
		this.values.push({
			label: 'Jméno',
			id: 'name_first',
			original: this.data.name_first,
			content: this.data.name_first,
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Příjmení',
			id: 'name_family',
			original: this.data.name_family,
			content: this.data.name_family,
			type: 'text',
			empty: false,
			editable: true
		})
		this.values.push({
			label: 'Titul před',
			id: 'title_before',
			original: this.data.title_before,
			content: this.data.title_before,
			type: 'text',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Titul za',
			id: 'title_after',
			original: this.data.title_after,
			content: this.data.title_after,
			type: 'text',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Povolání',
			id: 'work',
			original: this.data.work,
			content: this.data.work,
			type: 'text',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Bydliště',
			id: 'home',
			original: this.data.home,
			content: this.data.home,
			contentid: this.data.home_num,
			type: 'home',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Věk ke dni 9. 10. 2021',
			id: 'age',
			content: this.data.age_at_election,
			type: 'number',
			empty: true,
			editable: true
		})
		this.values.push({
			label: 'Pohlaví',
			id: 'sex',
			content: this.data.sex,
			options: [{id: 1, name: 'muž'}, {id: 2, name: 'žena'}],
			type: 'select',
			empty: true,
			editable: true
		})
	}
};
