export default {
  source: 'https://www.kr-stredocesky.cz/web/volby/volby-do-poslanecke-snemovny-parlamentu-cr',
  list: [
    {
      name: "Táborská kasárna",
      address: "Benešov, ul. Táborská 57, areál bývalých Táborských kasáren, příjezd ul. Táborská",
      designated: "Benešov",
      maps: {
        "link": 'hodufolava',
        "embed": 'papanohobu'
      }
    },
    {
      name: "Parkoviště u zimní stadion",
      address: "Beroun, ul. Na Ostrově 83, parkoviště u zimního stadionu",
      designated: "Beroun",
      maps: {
        "link": 'butosucuco',
        "embed": 'rehesegufe'
      }
    },
    {
      name: "V zatáčce U jatek",
      address: "Kladno, ul. Železničářů, parkoviště poblíž zimního stadionu, příjezd pouze z ulice Jos. Čapka starý místopis: v zatáčce U jatek",
      designated: "Kladno",
      maps: {
        "link": 'letosapanu',
        "embed": 'rahefuvobo'
      }
    },
    {
      name: "Zámek Kolín",
      address: "Kolín, ul. Sokolská 545, areál zámku, Kolín II, vjezd a výjezd jednosměrná ul.",
      designated: "Kolín",
      maps: {
        "link": 'baperunuca',
        "embed": 'henufekuco'
      }
    },
    {
      name: "Plavecký bazén",
      address: "Kutná Hora, ul. Čáslavská, parkoviště před plaveckým bazénem",
      designated: "Kutná Hora",
      maps: {
        "link": 'culoherema',
        "embed": 'hokekusucu'
      }
    },
    {
      name: "Centrum prevence kriminality a dopravní výchovy",
      address: "Mělník, ul. Karolíny Světlé 595, areál Centra prevence kriminality a dopravní výchovy",
      designated: "Mělník",
      maps: {
        "link": 'desecosane',
        "embed": 'mazebaputa'
      }
    },
    {
      name: "U letiště",
      address: "Mladá Boleslav, ul. Regnerova 1306, u letiště, otočka autobusu, poblíž muzeum Metoděje Vlacha",
      designated: "Mladá Boleslav",
      maps: {
        "link": 'luketofame',
        "embed": 'cacetelune'
      }
    },
    {
      name: "U Obecního domu Kokos",
      address: "Nymburk, ul. Bedřicha Smetany 55, příjezd po silnici II. třídy č. 330 ul. Velké Valy, parkoviště u Obecního domu",
      designated: "Nymburk",
      maps: {
        "link": 'luhomugupa',
        "embed": 'dohemevabo'
      }
    },
    {
      name: "V sídle hasičské zbrojnice",
      address: "Brandýs nad Labem – Stará Boleslav, ul. Kralupská 1710, v sídle hasičské zbrojnice",
      designated: "Praha-Východ",
      maps: {
        "link": 'cejebuteja',
        "embed": 'bagenekahe'
      }
    },
    {
      name: "Husovo náměstí, u sloupu",
      address: "Hostivice, Husovo náměstí (pozemní komunikace poblíž Mariánského sloupu) ",
      designated: "Praha-Západ",
      maps: {
        "link": 'covebagunu',
        "embed": 'lacotelede'
      }
    },
    {
      name: "Parkoviště u nemocnice",
      address: "Příbram, ul. Podbrdská, parkoviště u budov areálu II Oblastní nemocnice Příbram",
      designated: "Příbram",
      maps: {
        "link": 'kopedereda',
        "embed": 'juhogavena'
      }
    },
    {
      name: "U fotbalového stadionu",
      address: "Rakovník, ul. nábř. T. G. Masaryka, příjezdová plocha před městským fotbalovým stadionem",
      designated: "Rakovník",
      maps: {
        "link": 'nopasedeha',
        "embed": 'novajupofu'
      }
    }
  ]
}
