import {beautifyNumber, betterURL, date, getLinkType} from "@/common/helpers";

export default {
	name: 'PartySummary',
	props: ['hash'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			var data = this.$store.getters.pdv('party/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		links: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (!x.region) {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		embeds: function () {
			if (!this.party) return undefined;

			var list = [];

			if (this.party.links) {
				this.party.links.forEach(x => {
					if (x.embed === 1 && !x.region) {
						list.push(x.link);
					}
				});
			}

			return list;
		},
		polls: function () {
			return this.$store.getters.pdv('polls/party/' + this.hash)
		},
		pollsGraph: function () {
			if (!this.polls) return undefined;

			var agencies = [
				{
					name: 'KANTAR',
					color: '#000',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'Kantar'
				},
				{
					name: 'CVVM',
					color: '#019DE0',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'CVVM'
				},
				{
					name: 'STEM',
					color: '#B00058',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'STEM'
				},
				{
					name: 'MEDIAN',
					color: '#00518F',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'Median'
				},
				{
					name: 'IPSOS',
					color: '#00afa9',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'Ipsos'
				},
				{
					name: 'DATA COLLECT',
					color: '#11579a',
					list: [],
					last: undefined,
					diff: undefined,
					display: 'Data Collect'
				}
			];

			this.polls.list.forEach(poll => {
				var agency = poll.agency.toUpperCase();

				var obj = {
					date: poll.date,
					value: poll[this.hash]
				}

				if (agency === 'KANTAR CZ') agency = 'KANTAR';
				if (agency === 'KANTAR TNS') agency = 'KANTAR';

				var a = agencies.find(x => x.name === agency);

				if (a && obj.value) {
					a.list.push(obj);
				}
			});

			agencies.forEach(a => {

				a.list.sort((a, b) => (b.date || '2000-00-00').localeCompare((a.date || '2000-00-00'), 'cs'));

				if (a.list.length > 0) {
					a.last = a.list[0];
				}

				if (a.list.length > 1) {
					a.diff = Math.round(100 * (a.list[0].value - a.list[1].value)) / 100;
				}
			});

			agencies.sort((a, b) => (b.last || {date: '2020-00-00'}).date.localeCompare((a.last || {date: '2020-00-00'}).date, 'cs'));

			return agencies;
		},
		popularity: function () {
			return this.$store.getters.pdv('popularity/party/' + this.hash)
		},
		popularityData: function () {
			if (!this.popularity) return;

			var list = [];

			this.popularity.list.forEach(item => {
				if (!list.find(x => x.hash === item.hash)) {
					var obj = {
						hash: item.hash,
						platform: {
							fb: [],
							tw: [],
							ig: []
						}
					}

					list.push(obj);
				}
			});

			this.popularity.list.forEach(item => {
				var entry = list.find(x => x.hash === item.hash);

				if (entry) {
					entry.platform[item.platform].push({
						date: item.date,
						value: item.value
					});
				}

			});

			list.forEach(item => {
				Object.keys(item.platform).forEach(key => {
					item.platform[key].sort((a, b) => b.date.localeCompare(a.date, 'cs'));
				});
			});

			return list;
		},
		linksSelected: function () {
			var list = [];

			var prep = [];

			this.party.links.forEach(link => {
				prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var link = prep.find(x => x.type === 'link');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (link) list.push(link);
			if (wiki) list.push(wiki);

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('youtube').length > 1) return 'yt';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		}
	}
};
