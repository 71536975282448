import axios from 'axios';

export default {
	name: 'admin-contact',
	props: ['hash', 'sid', 'data', 'region', 'open'],
	data: function () {
		return {
			list: [],
			contact: ''
		}
	},
	methods: {
		getLinkType: function () {
			return 'icon-none';
		},
		remove: function (contact) {
			axios.post(this.$store.state.api + 'admin/contact-remove', {
				key: this.sid,
				contact: contact.content,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.content === contact.content).removed = true;
				}
			})
		},
		add: function () {
			axios.post(this.$store.state.api + 'admin/contact-add', {
				key: this.sid,
				contact: this.contact,
				region: this.region,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						content: response.data.contact,
						type: this.getLinkType(response.data.contact),
						removed: false
					})

					this.contact = '';
				}
			})
		}
	},
	mounted: function () {
		this.data.forEach(contact => {
			if ((this.region === null && !contact.region) || (!this.region && contact.region === null) || this.region === contact.region) {
				this.list.push({
					content: contact.value,
					type: this.getLinkType(contact.link),
					removed: false
				});
			}
		})
	}
};
