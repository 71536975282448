// import {betterURL} from "@/common/helpers";
import axios from 'axios';

export default {
	name: 'layout-dreamteam',
	data: function () {
		return {
			resort: [],
			selected: [],
			view: 0,
			search: '',
			name: '',
			sending: false
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			var list = [];

			this.$store.getters.parties().forEach(party => {
				if (party.status === 1) {
					list.push(party)
				} // else if (party.status === 2) {
					// list.push(party)
				// }
			});

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		},
		dreamteam: function () {
			return this.$store.getters.getSource('volby/psp/2021/dreamteam');
		},
		people: function () {
			if (!this.dreamteam) return;

			return this.$store.getters.pdv('candidate/dreamteam');
		}
	},
	methods: {
		partyClick: function (party) {
			var i = this.selected.findIndex(x => x === party);

			if (i > -1) {
				this.selected.splice(i, 1);
			} else {
				this.selected.push(party);
			}
		},
		personClick: function (resort, person) {
			resort.selected = person;
			this.prepareSuggestions();
			this.search = '';
		},
		prepareSuggestions: function () {
			var partiesUsed = [];

			if (!this.ministersList) {
				// setTimeout(() => this.prepareSuggestions(), 500);
			} else {
				this.resort.forEach(resort => {
					resort.suggestions = [];
					if (resort.selected) {
						if (partiesUsed.indexOf(resort.selected.party) === -1) partiesUsed.push(resort.selected.party);
					}
				});

				this.ministersList.ministers.forEach(minister => {
					var r = this.resort.find(x => x.ministry.hash === minister.ministry);

					if (minister.ministry === 'premier' || partiesUsed.indexOf(minister.hash) > -1) {
						r.suggestions.push(this.ministersList.list.find(p => p.hash === minister.person))
					}
				});

				this.resort.forEach(resort => resort.suggestions.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs')));
			}

		},
		clear: function () {
			this.resort.forEach(resort => {
				resort.selected = null;
			});
			this.prepareSuggestions();
			window.scrollTo(0, 0);
		},
		send: function () {
			if (this.sending === true) return;
			this.sending = true;

			var values = [];

			this.resort.forEach(resort => {
				if (resort.selected) {
					values.push({
						resort: resort.ministry.hash,
						person: resort.selected.hash
					})
				}
			});

			axios.post(this.$store.state.api + 'dreamteam/add', {
				name: this.name,
				values
			}).then((response) => {
				// setTimeout(() => {
					this.sending = false;
					// // console.log(response);
					this.$router.push('/vladni-tym-snu/' + response.data.hash);
				// }, 2000);
			})
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Sestavte si svůj vládní tým snů"});

		this.$store.state.static.ministry.forEach(ministry => {
			var obj = {
				ministry,
				selected: null,
				suggestions: []
			}

			if (this.resort != 'jine') this.resort.push(obj);
		});

		// setTimeout(() => this.prepareSuggestions(), 2500);
  }
}
