import axios from 'axios';

export default {
	name: 'admin-video',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			list: [],
			nove: {
				name: '',
				source: ''
			}
		}
	},
	methods: {
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/video-remove', {
				key: this.sid,
				source: src.source,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.source === src.source).removed = true;
				}
			})
		},
		add: function () {
			if (this.nove.name.trim() === '' || this.nove.source.trim() === '') return;

			axios.post(this.$store.state.api + 'admin/video-add', {
				key: this.sid,
				source: this.nove.source,
				title: this.nove.name,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						source: this.nove.source,
						name: this.nove.name,
						removed: false
					})

					this.nove.name = '';
					this.nove.source = '';
				}
			})
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					source: src.source,
					name: src.title,
					removed: false
				});
			})
		} else {
			this.list = []
		}
	}
};
