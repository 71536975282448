export default {
	name: 'CandidateStats',
	props: ['data', 'region', 'party'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		youngest: function () {
			var list = [];

			if (this.data && this.data.length > 5) {
				this.data.forEach(p => list.push(p));
				list.sort((a, b) => a.age_at_election - b.age_at_election);
				list.splice(5);
			}

			return list;
		},
		oldest: function () {
			var list = [];

			if (this.data && this.data.length > 5) {
				this.data.forEach(p => list.push(p));
				list.sort((a, b) => b.age_at_election - a.age_at_election);
				list.splice(5);
			}

			return list;
		}
	},
	methods: {
		averageAge: function (list) {
			var age = 0;
			var count = 0;

			list.forEach(person => {
				if (person.age_at_election) {
					count++;
					age += person.age_at_election;
				}
			});

			var result = this.round(age / count);

			return result;
		},
		round: function (value) {
			return Math.round(100 * value)/100
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
	}
};
