import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PersonLineSummary',
	props: ['person', 'icons'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		photo: function () {
			return this.person.photo && this.person.photo[0] ? this.person.photo[0].link : this.$(this.person.nominee).logo
		},
		links: function () {
			var list = [];

			var prep = [];

			this.person.links.forEach(link => {
				prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (wiki) list.push(wiki);

			return list;
		},
		audio: function () {
			var test = false;

			if (this.person.position < 4) {
				var id = (this.person.region + 1) + '_' + this.$(this.person.party).election_number;

				if (this.person.position === 1 && !this.$store.state.static.rozhlas[id]) {
					test = true;
				} else if (this.person.position === this.$store.state.static.rozhlas[id]) {
					test = true;
				}
			}

			return test;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
