import BarChart from '../../../types/bar-chart.js'

let colors = {
	'KANTAR CZ': '#000',
	'MEDIAN': '#00518f',
	'SANEP': '#d31e27',
	'CVVM': '#0399d9',
	'STEM': '#781333',
	'KANTAR': '#000',
	'G82': '#ccc',
	'PHOENIX RESEARCH': '#f90',
	'KANTAR TNS': '#000',
	'JAN HERZMAN': '#ccc',
	'FOCUS': '#ccc',
	'STEM-MARK': '#781333',
	'MÉDEA RESEARCH': '#f90',
	'IPSOS': '#00afa9',
	'Příjmy': '#009206',
	'Výdaje': '#d72027',
	'Zůstatek': '#00afa9'
}

export default {
  components: {
    BarChart
  },
	props: ['data', 'color'],
  data () {
    return {
      datacollection: null,
			height: 400
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      this.datacollection = {
        datasets: this.myDatasets
      }
    }
  },
  computed: {
		myDatasets () {
			var ds = [];

			this.data.forEach(item => {
				var timestamp = new Date(item.date);

				var agency = item.agency;

				if (!ds.find(x => x.label === agency)) ds.push({
					label: agency,
					data: [],
					borderColor: agency === 'Zůstatek' ? colors[agency] : 'transparent',
					pointBackgroundColor: agency === 'Zůstatek' ? colors[agency] : 'transparent',
					backgroundColor: agency === 'Zůstatek' ? 'transparent' : colors[agency],
					lineTension: .3,
					beginAtZero: true,
					type: agency === 'Zůstatek' ? 'line' : 'bar'
				});

				var d = ds.find(x => x.label === agency);

				d.data.push({
					x: timestamp,
					y: item.value,
					r: 2
				})
			});

			return ds;
		},
    myStyles () {
      return {
        height: `${this.height}px`,
        position: 'relative'
      }
    },
		myOptions () {
			return {
				responsive: true,
				maintainAspectRatio: false,
        scales: {
            xAxes: [{
								stacked: true,
                type: 'time',
                time: {
                    unit: 'year',
										tooltipFormat: 'D. M. 20YY'
                },
								ticks: {
									min: '2020-12-01',
									max: '2022-01-31'
								}
            }],
						yAxes: [{
							beginAtZero: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10
                }
						}]
        }
			}
		}
  },
	watch: {
		data: function () {
			this.fillData();
		}
	}
}
