export default {
	name: 'layout-votecalc-results',
	props: ['id'],
	data: function () {
		return {
			matrix: [
				[1.0 , 0.7, 0.01, 0.0, 0.0,  0.0],
				[0.7 , 1.0, 0.2,  0.0, 0.0,  0.0],
				[0.01, 0.2, 1.0,  0.2, 0.01, 0.01],
				[0.0 , 0.0, 0.2,  1.0, 0.7,  0.0],
				[0.0 , 0.0, 0.01, 0.7, 1.0,  0.0],
				[0.0 , 0.0, 0.01, 0.0, 0.0,  1.0]
			],
			parties: [],
			person: {
				modifiers: {
					shortCount: 0,
					longCount: 0,
					shortReg: 1,
					shortImp: 2,
					longReg: 1,
					longImp: 2,
					shortCheck: 0,
					longCheck: 0
				}
			},
			typeToDemo: true,
			type: 0,
			use: 2,
			partyDemo: null
		}
	},
	components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		answers: function () {

			var list = [];

			this.parties.forEach(party => {
				if (party.interestCount > 0) list.push(party.hash);
			});

			return this.$store.getters.pdv('calc/fetch/' + list.join(','));
		},
		results: function () {
			return this.$store.getters.pdv('votecalc/fetch/' + (this.id || 'test1'));
		},
		resmix: function () {
			if (this.partyDemo) {
				return this.partyDemo
			} else {
				return this.results;
			}
		},
		list: function () {
			if (!this.answers || !this.results) return [];

			this.parties.forEach(party => {
				party.modifiers.longCount = 0;
				party.modifiers.shortCount = 0;
			});

			var list = this.$store.state.static.questions.list;

			list.forEach(q => {
				this.parties.forEach(party => {
					q.answers[party.hash] = {
						answer: 4,
						comment: null,
						importance: 1,
						value: null
					}
				});
			});

			var party = null;

			this.answers.list.forEach(item => {
				if (item.status === 1) {
					var q = list.find(x => x.id === item.question);

					if (!party || party.hash != item.hash) party = this.parties.find(x => x.hash === item.hash);

					if (item.importance === 2) {
						if (q.priority === 1) party.modifiers.shortCount++;
						party.modifiers.longCount++
					}

					q.answers[item.hash].answer = item.answer;
					q.answers[item.hash].comment = (!item.comment || item.comment.length === 0) ? null : item.comment;
					q.answers[item.hash].importance = item.importance;
				}
			});

			var short = 40;
			var long = 113;

			function balance(base, prefix, obj) {
				var diff = (base - obj[prefix + 'Count']) / base;

				// type 1
				// var reg = diff;
				// var imp = 1 + diff;

				// type 2
				// var reg = 1 - (1 - diff) / 4;
				// var imp = 1.5 + diff * 2;

				// type 3
				var reg = 1 - (1 - diff * diff) / 4;
				var imp = 1.5 + diff * diff * 2;

				var sum = reg * (base - obj[prefix + 'Count']) + imp * obj[prefix + 'Count'];

				var ease = sum / base;

				obj[prefix + 'Reg'] = reg / ease;
				obj[prefix + 'Imp'] = imp / ease;
			}

			this.parties.forEach(party => {
				balance(short, 'short', party.modifiers);
				balance(long, 'long', party.modifiers);
			});

			var result = this.resmix.result;

			// // console.log(result);

			this.type = result.type;

			this.person.modifiers.shortCount = result.values.filter(x => x[2] === 2).length;
			this.person.modifiers.longCount = this.person.modifiers.shortCount;

			balance(short, 'short', this.person.modifiers);
			balance(long, 'long', this.person.modifiers);

			list.forEach(q => {

				var res = result.values.find(x => x[0] === q.id);

				if (q.priority === 1 || this.type === 2) {
					if (res) {
						this.parties.forEach(party => {
							q.answers[party.hash].value = this.getValue(q.answers[party.hash].answer, q.answers[party.hash].importance, party.modifiers, res[1], res[2], this.person.modifiers);
						});
					} else {
						this.parties.forEach(party => {
							q.answers[party.hash].value = this.getValue(q.answers[party.hash].answer, q.answers[party.hash].importance, party.modifiers, 4, 1, this.person.modifiers);
						});
					}
				}

			});

			this.parties.forEach(party => {
				party.results.basic = Math.round(100 * list.reduce((a, b) => a + (b.answers[party.hash].value ? b.answers[party.hash].value.basic : 0), 0)) / 100;
				party.results.base = Math.round(100 * list.reduce((a, b) => a + (b.answers[party.hash].value ? b.answers[party.hash].value.base : 0), 0)) / 100;
				party.results.balanced = Math.round(100 * list.reduce((a, b) => a + (b.answers[party.hash].value ? b.answers[party.hash].value.balanced : 0), 0)) / 100;
			});

			return list;
		},
		finals: function () {
			if (!this.list) return [];

			var list = [];

			this.parties.forEach(party => {
				var obj = {
					party,
					value: 0,
					pct: 0
				}

				if (this.use === 1) obj.value = party.results.balanced;
				if (this.use === 2) obj.value = party.results.base;
				if (this.use === 3) obj.value = party.results.basic;

				obj.pct = Math.round(10000 * obj.value / (this.type === 1 ? 40 : 113)) / 100;

				// if (obj.pct > 100) obj.pct = 100;

				list.push(obj);
			})

			list.sort((a, b) => b.value - a.value);

			if (this.use === 1 && list[0].pct > 100) {

				var down = list[0].pct / 100;

				list.forEach(obj => {
					obj.pct = Math.round(100 * obj.pct / down) / 100;
				})
			}

			return list;
		},
		partiesAll: function () {
			var list = [];

			this.$store.getters.parties().filter(x => x.status === 1).forEach(x => list.push(x));

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		}
	},
	methods: {
		filterToggle: function (party) {
			if (this.hide.find(x => x === party)) {
				this.hide.splice(this.hide.findIndex(x => x === party), 1);
			} else {
				this.hide.push(party);
			}
		},
		getValue: function (answerParty, importanceParty, modifiersParty, answerPerson, importancePerson, modifiersPerson) {
			if (!modifiersParty || !modifiersPerson) {
				// console.log(answerParty, importanceParty, modifiersParty, answerPerson, importancePerson, modifiersPerson);
			}

			// basic

			var basic = 0;

			if (answerParty === answerPerson && importanceParty === importancePerson) {
				basic = 1;
			} else if ((answerParty === 0 || answerParty === 4) && (answerPerson === 0 || answerPerson === 4)) {
				basic = 1;
			} else if (answerParty === answerPerson && importanceParty != importancePerson) {
				basic = .95;
			} else if ((answerPerson === 2 && (answerParty === 3 || answerParty === 1)) || ((answerPerson === 3 || answerPerson === 1) && answerParty === 2)) {
				basic = .25;
			} else if ((answerPerson === 2 && (answerParty === 4)) || ((answerPerson === 4) && answerParty === 2)) {
				basic = .1;
			}

			// base

			var row = 0;
			var index = 0;

			if (answerParty === 0 || answerParty === 4) index = 5;
			if (answerParty === 1 && importanceParty === 2) index = 0;
			if (answerParty === 1 && importanceParty === 1) index = 1;
			if (answerParty === 2) index = 2;
			if (answerParty === 3 && importanceParty === 2) index = 4;
			if (answerParty === 3 && importanceParty === 1) index = 3;

			if (answerPerson === 0 || answerPerson === 4) row = 5;
			if (answerPerson === 1 && importancePerson === 2) row = 0;
			if (answerPerson === 1 && importancePerson === 1) row = 1;
			if (answerPerson === 2) row = 2;
			if (answerPerson === 3 && importancePerson === 2) row = 4;
			if (answerPerson === 3 && importancePerson === 1) row = 3;

			var value = this.matrix[row][index];

			// balanced

			var balanced = value;

			var prefix = this.type === 1 ? 'short' : 'long';

			if (importanceParty === 1 && importancePerson === 1) balanced *= modifiersParty[prefix + 'Reg'] * modifiersPerson[prefix + 'Reg'];
			if (importanceParty === 1 && importancePerson === 2) balanced *= modifiersParty[prefix + 'Reg'] * modifiersPerson[prefix + 'Imp'];
			if (importanceParty === 2 && importancePerson === 1) balanced *= modifiersParty[prefix + 'Imp'] * modifiersPerson[prefix + 'Reg'];
			if (importanceParty === 2 && importancePerson === 2) balanced *= modifiersParty[prefix + 'Imp'] * modifiersPerson[prefix + 'Imp'];

			balanced = Math.round(balanced * 100) / 100;

			return {
				basic,
				base: value,
				balanced
			};
		},
		usePartyDemo: function (party, level) {

			level = this.typeToDemo === true ? 2 : 1;

			var obj = {
				result: {
					type: level || 2,
					values: [],
					party
				}
			}

			this.answers.list.filter(x => x.hash === party.hash && this.$store.state.static.questions.list.find(y => y.id === x.question).priority > (this.typeToDemo === true ? -1 : 0)).forEach(item => {
				obj.result.values.push([
					item.question,
					item.answer,
					item.importance
				]);
			});

			this.use = 2;
			this.partyDemo = obj;
		},
		flip: function (value) {
			return value === 1 ? 2 : 1
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: "Výsledky volební kalkulačky"});

		this.$store.getters.parties().forEach(party => {
			if (party.interestCount > 0) {
				var obj = {
					hash: party.hash,
					short: party.short,
					logo: party.logo,
					color: party.color,
					interestCount: party.interestCount,
					modifiers: {
						shortCount: 0,
						longCount: 0,
						shortReg: 1,
						shortImp: 2,
						longReg: 1,
						longImp: 2,
						checkLong: 0,
						checkShort: 0
					},
					results: {
						basic: 0,
						base: 0,
						balanced: 0
					}
				}

				// // console.log(obj);
				this.parties.push(obj);

				// list.push(party);
			}

		});

		this.parties.sort((a, b) => a.short.localeCompare(b.short, 'cs'));
	}
};
