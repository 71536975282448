import BubbleChart from '../../../types/line-chart.js'

// let colors = {
// 	'KANTAR CZ': '#000',
// 	'MEDIAN': '#00518f',
// 	'SANEP': '#d31e27',
// 	'CVVM': '#0399d9',
// 	'STEM': '#781333',
// 	'KANTAR': '#000',
// 	'G82': '#ccc',
// 	'PHOENIX RESEARCH': '#f90',
// 	'KANTAR TNS': '#000',
// 	'JAN HERZMAN': '#ccc',
// 	'FOCUS': '#ccc',
// 	'STEM-MARK': '#781333',
// 	'MÉDEA RESEARCH': '#f90',
// 	'IPSOS': '#00afa9',
// 	'Příjmy': '#009206',
// 	'Výdaje': '#d72027',
// 	'Zůstatek': '#00afa9'
// }

export default {
  components: {
    BubbleChart
  },
	props: ['data', 'color'],
  data () {
    return {
      datacollection: null,
			height: 400
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      this.datacollection = {
        datasets: this.myDatasets
      }
    }
  },
  computed: {
		myDatasets () {
			var ds = [];

			this.data.forEach(item => {
				var timestamp = new Date(item.date);

				var agency = item.agency;

				if (agency === 'KANTAR CZ') agency = 'KANTAR';
				if (agency === 'KANTAR TNS') agency = 'KANTAR';
				if (agency === 'STEM-MARK') agency = 'STEM';

				// if (agency === 'FOCUS') return;
				// if (agency === 'MÉDEA RESEARCH') return;
				// if (agency === 'JAN HERZMAN') return;
				// if (agency === 'G82') return;

				var color = '#ccc';

				if (this.$store.state.static.pollsters[agency]) {
					color = this.$store.state.static.pollsters[agency];
				}

				if (!ds.find(x => x.label === agency)) ds.push({
					label: agency,
					data: [],
					borderColor: color,
					pointBackgroundColor: color,
					backgroundColor: 'transparent',
					lineTension: .3,
					borderWidth: 2,
					beginAtZero: true
				});

				var d = ds.find(x => x.label === agency);

				d.data.push({
					x: timestamp,
					y: item.value,
					r: 2
				})
			});

			return ds;
		},
    myStyles () {
      return {
        height: `${this.height}px`,
        position: 'relative'
      }
    },
		myOptions () {
			return {
				responsive: true,
				maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'year',
										tooltipFormat: 'D. M. 20YY'
                },
								ticks: {
									min: '2020-06-01',
									max: '2021-12-31'
								}
            }],
						yAxes: [{
							beginAtZero: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10
                }
						}]
        }
			}
		}
  },
	watch: {
		data: function () {
			this.fillData();
		}
	}
}
