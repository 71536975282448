export default {
	name: 'BoxWrapper',
	props: ['href', 'to', 'click'],
	computed: {
		_click: function () {
			return this.click || function () {};
		}
	},
	methods: {
		slideTo: function (name, ev) {
			ev.preventDefault();
			document.querySelector("a[name=" + name.split('#')[1] + "]").scrollIntoView({behavior: "smooth", block: "center"});
		}
	}
};
