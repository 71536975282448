import axios from 'axios';

export default {
	name: 'admin-garant-list',
	props: ['candidates', 'hash', 'sid', 'chunks'],
	data: function () {
		return {
			ministry: null,
			mcn: '',
			person: null,
			list: []
		}
	},
	components: {

	},
	computed: {
		candlist: function () {
			var arr = [];

			this.candidates.forEach(c => {
				var o = {
					hash: c.hash,
					name: c.name_first + ' ' + c.name_family,
					ft: c.name_family + ' ' + c.name_first
				}

				arr.push(o);
			})

			arr.sort((a, b) => a.ft.localeCompare(b.ft, 'cs'));

			return arr;
		}
	},
	methods: {
		remove: function (contact) {
			axios.post(this.$store.state.api + 'admin/garant-remove', {
				key: this.sid,
				garant: contact.garant.hash,
				chunk: contact.chunk.src,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					contact.removed = true;
				}
			})
		},
		add: function () {
			axios.post(this.$store.state.api + 'admin/garant-add', {
				key: this.sid,
				garant: this.person.hash,
				chunk: this.ministry.src,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.push({
						chunk: this.chunks.find(x => x.src === this.ministry.src),
						garant: this.candidates.find(x => x.hash === this.person.hash),
						removed: false
					})

					this.ministry = null;
					this.person = null;
				}
			})
		},
		fetch: function () {
			this.list = [];

			axios.post(this.$store.state.api + 'admin/garant-list', {
				key: this.sid,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					response.data.list.forEach(item => {
						this.list.push({
							chunk: this.chunks.find(x => x.src === item.chunk),
							garant: this.candidates.find(x => x.hash === item.garant),
							removed: false
						})
					});

					this.list.sort((a, b) => a.garant.name_family.localeCompare(b.garant.name_family, 'cs'));
					this.list.sort((a, b) => a.chunk.headline.localeCompare(b.chunk.headline, 'cs'));
				}
			})
		}
	},
	mounted: function () {
		this.fetch();
	},
	watch: {
		data: function () {
			this.fetch();
		}
	}
};
