import questions from "@/layout/quiz/questions";
import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-guide-results',
	data: function () {
		return {
			questions
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		candidates: function () {
			var list = [
				'vladimira-vitova',
				'andrej-babis',
				'pavel-sehnal',
				'jan-hamacek',
				'vojtech-filip',
				'radim-spacek',
				'ctirad-musil',
				'ondrej-hysek',
				'ivan-bartos',
				'robert-slachta',
				'tomio-okamura',
				'petr-fiala',
				'zuzana-majerova-zahradnikova',
				'lubomir-volny',
				'magdalena-davis'
			]
			return this.$store.getters.pdv('candidate/list/' + list.join(','));
		},
		leaders: function () {
			return this.$store.getters.pdv('candidate/leaders');
		},
		motto: function () {
			return this.$store.getters.pdv('party/motto');
		},
		data: function () {
			return this.$store.state.dynamic.quiz;
		},
		evaluation: function () {
			var points = 0;
			var questions = [];

			if (this.data) {
				this.data.questions.forEach(item => {
					var obj = {
						question: item.question,
						points: 0,
						max: item.value,
						type: item.type,
						multiple: item.multiple
					};

					if (item.type === 'list') {
						if (item.multiple === true) {
							item.options.forEach(opt => {
								if (opt.answer === 0 && !item.answers.find(x => x.id === opt.id)) {
									obj.points += item.value / 4;
								}
								if (opt.answer === 1 && item.answers.find(x => x.id === opt.id)) {
									obj.points += item.value / 4;
								}
								if (opt.answer === 1 && !item.answers.find(x => x.id === opt.id)) {
									if (!obj.correct) obj.correct = [];
									obj.correct.push(opt);
								}
								if (opt.answer === 0 && item.answers.find(x => x.id === opt.id)) {
									if (!obj.correct) obj.correct = [];
									obj.correct.push(opt);
								}

							});
						} else {
							if (item.answers[0].answer === 1) {
								obj.points += item.value;
							} else {
								obj.correct = [item.options.find(x => x.answer === 1).label];
							}
						}
					}

					if (item.type === 'faces') {
						item.groups.forEach(group => {
							if (group.answers[0].hash === group.answers[0].selected) {
								obj.points += item.value / 4;
							} else {
								if (!obj.correct) obj.correct = [];
								obj.correct.push({
									selected: group.answers[0].selected,
									answer: group.answers[0].hash
								})
							}
						})
					}

					if (item.type === 'leaders') {
						item.answers.forEach(opt => {
							if (opt.region === opt.selected) {
								obj.points += item.value / 4;
							}
						});
						item.options.forEach(opt => {
							if (opt.region === opt.selected && !item.answers.find(x => x.id === opt.id)) {
								if (!obj.correct) obj.correct = [];
								obj.correct.push(opt);
							}
							if (opt.region != opt.selected && item.answers.find(x => x.id === opt.id)) {
								if (!obj.correct) obj.correct = [];
								obj.correct.push(opt);
							}
						})
					}

					if (item.type === 'motto') {
						if (item.answers[0].correct === 1) {
							obj.points += item.value;
							obj.quote = item.quote;
						} else {
							obj.correct = item.quote;
						}
					}

					if (item.type === 'region') {
						item.groups.forEach(group => {
							if (group.answers[0].id === group.answers[0].selected) {
								obj.points += item.value / 4;
							} else {
								if (!obj.correct) obj.correct = [];
								obj.correct.push(group.person);
							}
						})
					}

					if (item.type === 'calc') {
						item.answers.forEach(opt => {
							if (opt.answer === opt.correct) {
								obj.points += item.value / item.answers.length;
							} else {
								if (!obj.correct) {
									obj.calc = this.$store.state.static.questions.list.find(x => x.id === item.id).question;
									obj.correct = [];
								}
								obj.correct.push(opt);
							}
						});

						obj.list = item.answers;
						obj.id = item.id;
					}

					if (item.type === 'program') {
						obj.p1 = item.correct;
						obj.p2 = item.party;

						if (item.answer === item.answers[0].id) {
							obj.points += item.value;
							obj.ok = true;
						} else {
							obj.ok = false;
						}

						obj.correct = {
							quote: item.quote,
							answer: item.answer,
							mislead: item.party,
							party: item.correct
						}

					}

					questions.push(obj);
					points += obj.points;
				});
			}

			return {
				points,
				questions
			}
		},
		partiesAll: function () {
			var list = [];

			this.$store.getters.parties().filter(x => x.status === 1).forEach(x => list.push(x));

			list.sort((a, b) => a.short.localeCompare(b.short, 'cs'));

			return list;
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		}
	},
  methods: {
		betterURL
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Kvíz - výsledky"});
  }
}
