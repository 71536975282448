export default {
	name: 'app',
    data: function () {
      return {}
    },
    components: {
    },
    computed: {
			loaded: function () {
				return this.$store.getters.pdv('party/list');
			}
    },
    methods: {
    },
    mounted: function () {

    },
};
