export default {
	name: 'layout-person-list',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		list: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/seznam/poslanci')
		},
		parties: function () {
			return this.$store.getters.getSource('volby/psp/2021/rejstrik/seznam/strany')
		},
		regions: function () {
			if (!this.list) return;

			var list = [];

			this.$store.state.static.regions.forEach((region, index) => {
				var obj = {
					id: index,
					name: region,
					list: this.list.filter(x => x.psp.election.region === index)
				}

				list.push(obj);
			});

			return list;
		}
	},
  methods: {
		getLinkType: function (link) {
			if (link.split('facebook.com').length > 1) return 'fb';
			if (link.split('twitter.com').length > 1) return 'tw';
			if (link.split('instagram.com').length > 1) return 'ig';
			if (link.split('wikipedia').length > 1) return 'wiki';
			if (link.split('hlidac').length > 1) return 'hlidac-statu';
			// if (link.split('nasi-politici').length > 1) return 'nasi-politici';
			if (link.split('demagog').length > 1) return 'demagog';
			return 'link';
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Poslanci 8. volebního období"});
  }
};
