import {beautifyNumber, betterURL, date, truncate, getLinkType} from "@/common/helpers";

export default {
	name: 'PersonTinySummary',
	props: ['hash'],
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		person: function () {
			var data = this.$store.getters.pdv('candidate/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		photo: function () {
			return this.person && this.person.photo && this.person.photo[0] ? this.person.photo[0].link : undefined
		},
		region: function () {
			var obj = {
				link: '/',
				label: 'nezařazeno'
			}

			if (this.person) {
				obj.link = '/strana/' + this.person.party + '/nezarazeno';

				if (this.person.region > -1) {
					var r = this.$store.state.static.regions[this.person.region];

					obj.link = '/strana/' + betterURL(r) + '/nezarazeno';
					obj.label = r;
				}
			}

			return obj;
		},
		links: function () {
			var list = [];

			var prep = [];

			this.person.links.forEach(link => {
				prep.push({
					href: link.link,
					type: getLinkType(link.link)
				});
			});

			var tw = prep.find(x => x.type === 'tw');
			var fb = prep.find(x => x.type === 'fb');
			var ig = prep.find(x => x.type === 'ig');
			var wiki = prep.find(x => x.type === 'wiki');

			if (tw) list.push(tw);
			if (fb) list.push(fb);
			if (ig) list.push(ig);
			if (wiki) list.push(wiki);

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		truncate,
		getLinkType
	}
};
