export default {
  source: 'https://deska.praha.eu/files/ICTRv1ZtDis3R2kGc632Qg==/UknmE2qsbxfejnDCKYq21pbxsh2TgNMdPW9EY0UysE1pbxsh2y1pbxsh2bbyg=/MHMP_1503355_2021.pdf',
  list: [
    {
      name: "P+R Braník",
      address: "Praha 4, pozemek parc. č. 1969/4 v katastrálním území Braník",
      designated: "Praha 4, Praha 11, Praha 12, Praha – Křeslice, Praha – Kunratice, Praha – Libuš, Praha – Šeberov, Praha – Újezd ",
      maps: {
        "link": 'nutebuneju',
        "embed": 'gogolafano'
      }
    },
    {
      name: "P+R Kotlářka ",
      address: "Praha 5, pozemek parc. č. 1893/5 v katastrálním území Košíře",
      designated: "Praha 5, Praha 13, Praha 16, Praha – Lipence, Praha – Lochkov, Praha – Slivenec, Praha – Řeporyje, Praha – Zbraslav, Praha – Velká Chuchle, Praha – Zličín",
      maps: {
        "link": 'luhubogute',
        "embed": 'jekekavafa'
      }
    },
    {
      name: "Parkoviště Letná",
      address: "Praha 7, pozemek parc. č. 2137/12 v katastrálním území Holešovice",
      designated: " Praha 1, Praha 2, Praha 6, Praha 7, Praha 17, Praha – Lysolaje, Praha – Nebušice, Praha – Přední Kopanina, Praha – Suchdol",
      maps: {
        "link": 'cubehurale',
        "embed": 'mugeronuho'
      }
    },
    {
      name: "Parkoviště Letňany",
      address: "Praha 9, pozemek parc. č. 547/43 v katastrálním území Letňany",
      designated: "Praha 8, Praha 9, Praha 14, Praha 18, Praha 19, Praha 20, Praha 21, Praha – Běchovice, Praha – Březiněves, Praha – Čakovice, Praha – Dolní Chabry, Praha – Dolní Počernice, Praha – Ďáblice, Praha – Klánovice, Praha – Satalice, Praha – Troja, Praha - Vinoř",
      maps: {
        "link": '2cYsy',
        "embed": 'kahemorezu'
      }
    },
    {
      name: "P+R Skalka I",
      address: "Praha 10, pozemek parc. č. 3237/41 v katastrálním území Strašnice",
      designated: "Praha 3, Praha 10, Praha 15, Praha 22, Praha – Benice, Praha – Dolní Měcholupy, Praha – Dubeč, Praha – Koloděje, Praha – Kolovraty, Praha – Královice, Praha – Nedvězí, Praha – Petrovice, Praha – Štěrboholy",
      maps: {
        "link": '2F8TD',
        "embed": 'dobafatoto'
      }
    }
  ]
}
