export default {
  source: 'https://t.co/8yxy6o5bzM?amp=1',
  list: [
    {
      name: "parkoviště v areálu ENTERIA ARENY",
      address: "Sukova třída 1735, Pardubice",
      designated: "Pardubice",
      maps: {
        "link": 'nulojulufa',
        "embed": 'nulojulufa'
      }
    },
    {
      name: "parkoviště v areálu civilního letiště Chrudim - Aeroklub ČR-Chrudim, z. s.",
      address: "Sečská 25, Chrudim",
      designated: "Chrudim",
      maps: {
        "link": 'nanovobuvo',
        "embed": 'nanovobuvo'
      }
    },
    {
      name: "parkoviště Aquaparku - letního koupaliště",
      address: "V Lukách, Ústí nad Orlicí",
      designated: "Ústí nad Orlicí",
      maps: {
        "link": 'racalatogu',
        "embed": 'racalatogu'
      }
    },
    {
      name: "parkoviště u sportovního stadionu",
      address: "U Stadionu, Svitavy",
      designated: "Svitavy",
      maps: {
        "link": 'cubafasopu',
        "embed": 'cubafasopu'
      }
    }
  ]
}
