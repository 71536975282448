import axios from 'axios';
import state from './state';

axios.defaults.headers.common['Accept'] = '*/*';

const server = state.server;
const api = state.api;

const antiCache = 'c=' + window.cacheControlInitial ; // Math.round(new Date().getTime() / 1000);

const actions = {};

var page_visited = 0;
var page_last = "xxx";

actions.ga = function (context, payload) {

  document.title = payload.title + ' - Programy do voleb';

  payload.path = payload.path || context.state.route.fullPath.slice(1, context.state.route.fullPath.length);
  payload.path = payload.path.split('?')[0];

  if (page_last != payload.path) {

    page_last = payload.path;
    page_visited++;

    var api = state.api;

    if (payload.api) {
      api = api.split('2021').join(payload.api);
    }

    axios.post(api + 'system/log&c=' + (new Date().getTime()), {
      n: page_visited,
      t: payload.title,
      p: payload.path,
      m: ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) ? 1 : 0
    });
  }

  // if (window.gtag) {
  //   window.gtag('config', 'UA-4347750-19', {'page_path': payload.path, 'page_title': payload.title});
  // } else {
  //   console.log('GA:', '/' + payload.path, ' : ', payload.title);
  // }
};

actions.ge = function (context, payload) {
  // console.log(payload);

  axios.post(state.api + 'system/ev&c=' + (new Date().getTime()), {
    p: (payload.path || context.state.route.fullPath.slice(1, context.state.route.fullPath.length)).split('?')[0],
    e: payload.action + "-" + payload.category,
    v: payload.label
  });
};

actions.fetchSource = function (context, payload) {
  var lookup = context.state.dynamic.source.find(item => item.source === payload.source);

  if (lookup) {
    // // console.log('Town', lookup.name, 'is known');
    if (payload && payload.onComplete) payload.onComplete();
  } else {
    try {
      axios.get(server + payload.source + '.json?' + antiCache).then(response => {
        context.commit('fetchSource', {
          source: payload.source,
          content: response.data
        });

        if (payload && payload.onComplete) payload.onComplete();
      });
    } catch (e) {
      if (payload && payload.onError) payload.onError();
    }
  }
}

actions.pdv = function (context, payload) {
  var lookup = context.state.dynamic.pdv.find(item => item.request === payload.request);

  if (lookup) {
    if (payload && payload.onComplete) payload.onComplete();
  } else {
    try {
      axios.get(api + payload.request + '&' + antiCache).then(response => {
        context.commit('pdv', {
          request: payload.request,
          antiCache,
          content: response.data
        });

        if (payload && payload.onComplete) payload.onComplete();
      });
    } catch (e) {
      if (payload && payload.onError) payload.onError();
    }
  }
}

export default actions;
