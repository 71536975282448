import axios from 'axios';

export default {
	name: 'layout-interest-questions-idea',
	data: function () {
		return {
			selected: null,
			question: '',
			sent: false,
			sending: false
		}
	},
  components: {},
	computed: {
		list: function () {
			return this.$store.getters.pdv('interest/topics');
		},
		listSorted: function () {
			if (!this.list) return [];

			var list = this.list.list;
					list.sort((a, b) => a.title.localeCompare(b.title, 'cs'));

			return list;
		},
		valid: function () {
			return this.question.length > 10 && this.question.length < 281;
		}
	},
  methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=interest/add-idea', {
					topic: this.selected,
					question: encodeURIComponent(this.question)
				}).then(response => {
					this.sending = false;
					this.sent = true;
					this.response = response;
				});
			}
		},
		another: function () {
			this.sent = false;
			this.question  = '';
		},
		anotherTopic: function () {
			this.selected = null;
			this.another();
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Pomoc s otázkami pro kandidáty"});
  }
};
