export default {
  source: 'https://www.kr-karlovarsky.cz/krajsky-urad/deska/Documents/volby/Volebni_stanoviste_2021.pdf',
  list: [
    {
      name: "areál Krajského úřadu",
      address: "Závodní 353/88, Karlovy Vary – Dvory",
      designated: "Karlovy Vary",
      maps: {
        "link": 'fevakasero',
        "embed": 'fevakasero'
      }
    },
    {
      name: "areál Krajské správy a údržby silnic",
      address: "Chebská 282, Sokolov",
      designated: "Sokolov",
      maps: {
        "link": 'datanuvapo',
        "embed": 'datanuvapo'
      }
    },
    {
      name: "areál Nemocnice",
      address: "K Nemocnici 1204/17, Cheb",
      designated: "Cheb",
      maps: {
        "link": 'remofefezo',
        "embed": 'remofefezo'
      }
    }
  ]
}
