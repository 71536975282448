import {betterURL} from "@/common/helpers";
import questions from "@/layout/quiz/questions";
import axios from 'axios';

export default {
	name: 'layout-quiz-form',
	data: function () {
		return {
			test: false,
			phase: 0,
			selected: {
				region: null
			},
			questions: [],
			answers: []
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		candidates: function () {
			var list = [
				'vladimira-vitova',
				'andrej-babis',
				'pavel-sehnal',
				'jan-hamacek',
				'vojtech-filip',
				'radim-spacek',
				'ctirad-musil',
				'ondrej-hysek',
				'ivan-bartos',
				'robert-slachta',
				'tomio-okamura',
				'petr-fiala',
				'zuzana-majerova-zahradnikova',
				'lubomir-volny',
				'magdalena-davis'
			];
			return this.$store.getters.pdv('candidate/list/' + list.join(','));
		},
		leaders: function () {
			return this.$store.getters.pdv('candidate/leaders');
		},
		motto: function () {
			return this.$store.getters.pdv('party/motto');
		}
	},
	methods: {
		betterURL,
		confirm: function () {
			var query = [];

			return query;
			// this.$router.push('/pruvodce-volbami/na-miru?' + query.join('&'));
		},
		toggle: function (value, list) {
			if (list.find(x => x.id === value.id)) {
				list.splice(list.findIndex(x => x.id === value.id), 1);
			} else {
				list.push(value);
			}
			return list;
		},
		select: function (value, list) {
			if (list.length === 0) {
				list.push(value);
			} else {
				list.pop();
				list.push(value);
			}
		},
		click: function (list, value, multiple) {
			if (multiple) {
				this.toggle(value, list);
			} else {
				this.select(value, list);
			}
		},
		go: function (i) {
			this.phase = i;
			window.scrollTo(0, 0);

			if (i === 1) {
				if (this.selected.region === 99) this.selected.region = 5;
				this.prepare();
			}

			if (i === 21) {
				this.send();
			}
		},
		check: function (index) {
			var result = true;

			if (this.questions.length < 20) {
				result = false;
			} else if (!this.questions[index]) {
				result = false;
			} else if (index === 1 || index === 18) {
				if (this.questions[index].groups[0].answers.length === 0) return false;
				if (this.questions[index].groups[1].answers.length === 0) return false;
				if (this.questions[index].groups[2].answers.length === 0) return false;
				if (this.questions[index].groups[3].answers.length === 0) return false;
			} else {
				if (this.questions[index].answers.length === 0) return false;
			}

			return result;
		},
		send: function () {

			this.$store.commit('quiz', {
				data: {
					questions: this.questions,
					points: this.evaluation().points,
					region: this.selected.region
				},
				cb: () => this.$router.push('/volebni-kviz/vysledky')
			});

			axios.post(this.$store.state.api + 'quiz/add', {
				data: JSON.stringify(this.evaluation().questions.map(x => x = x.points)),
				region: this.selected.region,
				points: this.evaluation().points
			})
		},
		prepare: function () {
			questions.forEach((item, index) => {
				var q = {
					question: item.question,
					answers: [],
					note: item.note,
					value: item.settings.value
				};

				if (index === 1 || index === 18) {
					q.answers = [null, null, null, null];
				}

				q.multiple = item.settings.multiple || false;

				var opt, r;

				if (item.settings.type === 'list') {
					q.type = 'list';
					q.options = [];
					q.used = [];

					while (q.options.length < item.settings.correct + item.settings.wrong) {
						r = Math.floor(Math.random() * (item.options.length));

						if (q.used.indexOf(r) === -1) {
							opt = item.options[r];

							if (q.options.filter(x => x.answer === opt.answer).length < item.settings[opt.answer === 1 ? 'correct' : 'wrong']) {
								q.options.push({
									id: r,
									label: opt.label,
									answer: opt.answer
								});
								q.used.push(r);
							}
						}
					}
				}

				if (item.settings.type === 'calc') {
					q.type = 'calc';
					q.options = [];
					q.used = [];
					q.id = item.id;

					while (q.options.length < item.settings.show) {
						r = Math.floor(Math.random() * (item.options.length));

						if (q.used.indexOf(r) === -1) {
							q.used.push(r);
							opt = item.options[r];

							q.options.push({
								party: opt.party
							});

							q.answers.push({
								party: opt.party,
								answer: 0,
								correct: opt.answer
							});
						}
					}
				}

				if (item.settings.type === 'program') {
					q.type = 'program';
					q.options = [];
					q.id = item.id;

					r = Math.floor(Math.random() * (item.options.length));
					opt = item.options[r];

					q.quote = opt.copy;
					q.party = opt.party;
					q.answer = opt.answer;
					q.correct = opt.answer === 1 ? opt.party : opt.correct;

					q.options.push({id: 1, label: 'Obsahuje'});
					q.options.push({id: 0, label: 'Neobsahuje'});

					// q.answers.push(1);
				}

				if (item.settings.type === 'leaders') {
					q.type = 'leaders';
					q.options = [];
					q.used = [];

					while (q.options.length < item.settings.correct + item.settings.wrong) {
						r = Math.floor(Math.random() * (this.leaders.list.length));
						opt = this.leaders.list[r];

						if (!q.used.find(x => x === opt.hash) && this.leaders.list[r].photo.length > 0) {
							if ((q.options.filter(x => x.region != this.selected.region).length < item.settings.wrong && opt.region != this.selected.region) || (q.options.filter(x => x.region === this.selected.region).length < item.settings.correct && opt.region === this.selected.region)) {
								q.options.push({
									id: opt.hash,
									hash: opt.hash,
									region: opt.region,
									selected: this.selected.region,
									person: opt
								});
								q.used.push(opt.hash);
							}
						}
					}
				}

				if (item.settings.type === 'motto') {
					q.type = 'motto';
					q.options = [];
					q.used = [];

					r = Math.floor(Math.random() * (this.motto.list.length));
					opt = this.motto.list[r];
					q.used.push(opt.hash);

					q.quote = opt;

					q.options.push({
						hash: opt.hash,
						correct: 1
					});

					while (q.options.length < item.settings.wrong + 1) {
						r = Math.floor(Math.random() * (this.motto.list.length));
						opt = this.motto.list[r];

						if (!q.used.find(x => x === opt.hash)) {
							q.used.push(opt.hash);

							q.options.push({
								hash: opt.hash,
								correct: 0
							});
						}
					}
				}

				var g, gg;

				if (item.settings.type === 'faces') {
					q.type = 'faces';
					q.groups = [
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []}
					];
					q.used = [];

					for (g = 0; g < q.groups.length; g++) {

						gg = q.groups[g];

						while (!gg.person) {
							r = Math.floor(Math.random() * (this.candidates.list.length));
							opt = this.candidates.list[r];
							if (opt.about.length > 0) {
								opt.about[0] = "";
							}

							if (!q.used.find(x => x === opt.hash)) {
								gg.person = opt;
								q.used.push(opt.hash);
							}
						}

						while (gg.options.length < item.settings.correct + item.settings.wrong) {
							r = Math.floor(Math.random() * (this.candidates.list.length));
							opt = this.candidates.list[r];
							if (opt.about.length > 0) {
								opt.about[0] = "";
							}

							if (!gg.used.find(x => x === opt.hash)) {
								if ((gg.options.filter(x => x.hash != gg.person.hash).length < item.settings.wrong && opt.hash != gg.person.hash) || (gg.options.filter(x => x.hash === gg.person.hash).length < item.settings.correct && opt.hash === gg.person.hash)) {
									gg.options.push({
										id: opt.hash,
										hash: opt.hash,
										selected: gg.person.hash,
										person: opt
									});
									gg.used.push(opt.hash);
								}
							}
						}
					}
				}

				if (item.settings.type === 'region') {
					q.type = 'region';
					q.groups = [
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []},
						{person: null, options: [], answers: [], used: []}
					];
					q.used = [];

					for (g = 0; g < q.groups.length; g++) {

						gg = q.groups[g];

						while (!gg.person) {
							r = Math.floor(Math.random() * (this.candidates.list.length));
							opt = this.candidates.list[r];
							if (opt.about.length > 0) {
								opt.about[0] = "";
							}

							if (!q.used.find(x => x === opt.hash)) {
								gg.person = opt;
								q.used.push(opt.hash);
							}
						}

						while (gg.options.length < item.settings.correct + item.settings.wrong) {
							r = Math.floor(Math.random() * (this.$store.state.static.regions.length));
							opt = this.$store.state.static.regions[r];

							if (!gg.used.find(x => Number(x) === Number(r))) {
								if ((gg.options.filter(x => x.id != gg.person.region).length < item.settings.wrong && r != gg.person.region) || (gg.options.filter(x => x.id === gg.person.region).length < item.settings.correct && r === gg.person.region)) {
									gg.options.push({
										id: r,
										selected: gg.person.region,
										label: opt
									});
									gg.used.push(r);
								}
							}
						}
					}
				}

				this.questions.push(q);
			});
		},
		evaluation: function () {
			var points = 0;
			var questions = [];

			if (this.questions) {
				this.questions.forEach(item => {
					var obj = {
						question: item.question,
						points: 0,
						max: item.value,
						type: item.type,
						multiple: item.multiple
					};

					if (item.type === 'list') {
						if (item.multiple === true) {
							item.options.forEach(opt => {
								if (opt.answer === 0 && !item.answers.find(x => x.id === opt.id)) {
									obj.points += item.value / 4;
								}
								if (opt.answer === 1 && item.answers.find(x => x.id === opt.id)) {
									obj.points += item.value / 4;
								}
							});
						} else {
							if (item.answers[0].answer === 1) {
								obj.points += item.value;
							}
						}
					}

					if (item.type === 'faces') {
						item.groups.forEach(group => {
							if (group.answers[0].hash === group.answers[0].selected) {
								obj.points += item.value / 4;
							}
						})
					}

					if (item.type === 'leaders') {
						item.answers.forEach(opt => {
							if (opt.region === opt.selected) {
								obj.points += item.value / 4;
							}
						});
					}

					if (item.type === 'motto') {
						if (item.answers[0].correct === 1) {
							obj.points += item.value;
						}
					}

					if (item.type === 'region') {
						item.groups.forEach(group => {
							if (group.answers[0].id === group.answers[0].selected) {
								obj.points += item.value / 4;
							}
						})
					}

					if (item.type === 'calc') {
						item.answers.forEach(opt => {
							if (opt.answer === opt.correct) {
								obj.points += item.value / item.answers.length;
							}
						});
					}

					if (item.type === 'program') {
						if (item.answer === item.answers[0].id) {
							obj.points += item.value;
						}
					}

					questions.push(obj);
					points += obj.points;
				});
			}

			return {
				points,
				questions
			};
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Kvíz"});

		if (this.test) {
			setTimeout(() => {
				this.selected.region = 0;
				this.go(1);
			}, 2500);
		}
  }
}
