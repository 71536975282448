import axios from 'axios';

export default {
	name: 'admin-party-calculator',
	props: ['hash', 'sid', 'data'],
	data: function () {
		return {
			list: {
				primary: [],
				secondary: []
			},
			sending: false,
			sent: false
		}
	},
	methods: {
		updated: function () {
			this.sent = true;

			setTimeout(() => this.sent = false, 2000);
		},
		update: function () {
			if (this.sending) return;

			this.sending = true;

			var answers = [];

			this.list.primary.forEach(a => {
				if ((a.answer >= 1 && a.answer <= 3) || (a.answer === 4 && a.comment.length > 4)) {
					answers.push(a);
				}
			});

			this.list.secondary.forEach(a => {
				if ((a.answer >= 1 && a.answer <= 3) || (a.answer === 4 && a.comment.length > 4)) {
					answers.push(a);
				}
			});

			answers.forEach(x => {
				x.comment = x.comment.split('"').join('“');
			});

			axios.post(this.$store.state.api + 'admin/interest-answer-update-party', {
				key: this.sid,
				hash: this.hash,
				type: 1,
				answers
			}).then((response) => {
				if (response.data.code === 200) {
					this.updated();
					this.sending = false;
					this.storedAnswer = this.answer;
				} else {
					this.sending = false;
				}
			})
		}
	},
	mounted: function () {
		this.$store.state.static.questions.list.forEach(q => {
			var obj = {
				id: q.id,
				topic: q.topic,
				question: q.question,
				answer: 0,
				importance: 1,
				comment: ''
			};

			var a = this.data.find(x => x.question === q.id);

			if (a) {
				obj.answer = a.answer;
				obj.importance = a.importance;
				obj.comment = a.comment;
			}

			if (q.priority === 1) {
				this.list.primary.push(obj);
			} else {
				this.list.secondary.push(obj);
			}
		});
	}
};
