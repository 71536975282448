import axios from 'axios';
import {truncate, stripURLintoDomain, date} from '@/common/helpers';

export default {
	name: 'layout-tips',
	data: function () {
		return {
			type: -1,
			data: null
		}
	},
	components: {},
	computed: {
		headline: function () {
			var s = '';

			if (this.type === 0) s = 'Poslední tipy';
			if (this.type === 1) s = 'Tipy, které ještě nebyly zpracovány';
			if (this.type === 2) s = 'Posledních 25 zpracovaných tipů';
			if (this.type === 3) s = 'Výpis všech zpracovaných tipů';

			return s;
		}
	},
	methods: {
		truncate,
		stripURLintoDomain,
		date,
		fetch: function (id) {
			this.type = id;
			this.data = null;

			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=tip/fetch', {
				type: id
			}).then(response => {
				this.sending = false;
				this.sent = true;
				this.data = response.data.list;
			});
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.$store.dispatch("ga", {title: "Zpracování tipů"});
		this.fetch(0);
	}
};
