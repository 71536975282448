import {betterURL} from "@/common/helpers";

export default {
	name: 'layout-tags',
	props: ['hash'],
	data: function () {
		return {
			lastHash: null
		}
	},
  components: {},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));

				if (this.lastHash != this.hash) {
					this.lastHash = this.hash;
					this.$store.dispatch("ga", {title: list.find(x => x.hash === this.hash).name + ' jako volební téma'});
				}
			}

			return list;
		},
		content: function () {
			var tags = this.$store.getters.pdv('program/tag/' + this.hash);
			var list = [];

			if (tags) {
				tags.list.forEach(tag => {
					tag.snap = JSON.parse(tag.snippet);
					list.push(tag);
				});
				list.sort((a, b) => a.hash.localeCompare(b.hash, 'cs'));
			}

			return list;
		},
		headline: function () {
			if (!this.tags) {
				return "Zaostřeno";
			} else {
				return (this.tags.find(x => x.hash === this.hash) || {name: 'Zaostřeno'}).name;
			}
		},
		copy: function () {
			if (!this.tags) {
				return "V této části jsou vybraná různá specifická témata. U každého tématu se můžete podívat, zda a jak je téma ve volebním programu stran nějak zpracované. Témata budou průběžně přibývat, stejně tak může docházet k postupnému doplnění, jak strany zveřejňují své programy.";
			} else {
				return (this.tags.find(x => x.hash === this.hash) || {content: 'Zaostřeno'}).content;
			}
		}
	},
  methods: {
		getChunkData: function (id, party) {
			var chunks = JSON.parse(this.$(party).program.program.chunks);

			var chunk = chunks.find(x => x.src === id);

			if (!chunk) // console.log(party);

			return {
				name: chunk.headline,
				src: chunk.src,
				hash: betterURL(chunk.headline)
			}
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
		}
	}
};
