import axios from 'axios';
import NewsItem from '../item/do';
import {date} from '@/common/helpers';

export default {
	name: 'news-feed',
	props: ['hash', 'embeds', 'more', 'limit', 'compact'],
	data: function () {
		return {
			loading: false,
      data: []
		}
	},
	components: {
		NewsItem
	},
	computed: {
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		list: function () {
			var arr = [];

			if (this.parties && this.data.length > 0) {
				this.data.forEach(item => {
					var obj = {
						date: date(item.date),
						content: item.content,
						source: item.source,
						label: item.source.split('/')[2].split('.'),
						logo: item.logo || '/static/icon/logo-programy.svg',
						link: item.link,
						hash: '/strana/' + item.hash,
						title: item.title || 'Programy do voleb',
						color: '#aaa',
						embed: item.embed
					}

					obj.label = obj.label[obj.label.length - 2].toUpperCase();

					if ((!this.hash && item.hash) || this.hash) {
						obj.hash = '/strana/' + (item.hash || this.hash);

						var party = this.parties.list.find(x => x.hash === item.hash);

						if (party) {
							if (!item.logo) obj.logo = this.$store.state.server + party.logo;
							if (!item.title) obj.title = party.name;
							obj.color = party.color;
						}
					}

					arr.push(obj);
				});
			}

			if (this.limit) arr = arr.splice(0, this.limit)

			return arr;
		}
	},
	methods: {
		load: function () {
			if (this.loading === true) return;
			this.loading = true;

			axios.post('https://2021.programydovoleb.cz/lib/app/api.php?action=news/fetch', {
				hash: this.hash,
				offset: this.data.length
			}).then(response => {
				this.loading = false;
				response.data.list.forEach(item => this.data.push(item));
			});
		}
	},
	mounted: function () {
		this.load();
	},
	watch: {
		hash: function () {
			this.data = [];
			this.loading = false;
			this.load();
		}
	}
}
