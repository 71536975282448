import NewsFeed from "@/components/news-feed/do";
import ResultsGraph from "@/components/results-graph/do";
import {beautifyNumber, betterURL, date} from "@/common/helpers";

export default {
	name: 'layout-homepage',
	data: function () {
		return {
			selectedPoll: 'Ipsos'
		}
	},
  components: {
		NewsFeed,
		ResultsGraph
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		polls: function () {
			return this.$store.getters.pdv('polls/last-3-simar')
		},
		pollsWithDiff: function () {
			return this.$store.getters.pdv('polls/last-simar-with-diff');
		},
		pop: function () {
			return this.$store.getters.getSource('volby/psp/2021/poll-of-polls')
		},
		video: function () {
			return this.$store.getters.pdv('program/video');
		},
		pollsWithDiffList: function () {
			var list = [];

			if (this.pollsWithDiff) {
				Object.keys(this.pollsWithDiff.list).forEach(key => {
					var obj = {
						name: key,
						color: this.$store.state.static.pollsters[key.toUpperCase()],
						date: this.pollsWithDiff.list[key][0].date
					}

					list.push(obj);
				});

				list.sort((a, b) => b.date.localeCompare(a.date, 'cs'));

				if (list.length > 0) this.selectedPoll = list[0].name;
			}

			return list;
		},
		pollWithDiff: function () {
			if (!this.pollsWithDiff && !this.parties) return undefined;

			var list = [];
			var about = {};

			var p = this.pollsWithDiff.list[this.selectedPoll];

			about.agency = p[0].agency;
			about.date = p[0].date;
			about.amount = p[0].type;
			about.source = p[0].link;
			about.collect_from = p[0].collect_from;
			about.collect_to = p[0].collect_to;
			about.error = p[0].error;
			about.attendance = p[0].attendance;

			Object.keys(p[0]).forEach(key => {
				if (['agency', 'date', 'link', 'type', 'error', 'collect_from', 'collect_to', 'attendance'].indexOf(key) === -1 && p[0][key] != null) {
					var obj = {
						hash: key
					};

					var party = this.parties.list.find(x => x.hash === key);

					if (party) {
						obj.color = party.color;
						obj.logo = this.$store.state.server + party.logo;
						obj.pct = p[0][key];

						if (p[1] && p[1][key]) {
							obj.pct2 = p[1][key];
						}

						obj.short = party.short;
					}

					list.push(obj);
				}
			});

			list.sort((a, b) => b.pct - a.pct);

			return {list, about};
		},
		poll: function () {
		},
		parties: function () {
			return this.$store.getters.pdv('party/list')
		},
		partiesRunning: function () {
			return (this.parties ? this.parties.list.filter(x => x.status === 1) : []).sort((a, b) => a.short.localeCompare(b.short, 'cs'))
		},
		lastCandidates: function () {
			return this.$store.getters.pdv('candidate/last10')
		},
		candidates: function () {
			if (!this.lastCandidates || !this.parties) return [];

			var list = [];

			this.lastCandidates.list.forEach(cand => {
				var obj = {
					cand,
					party: null,
					member: null,
					nominee: null
				}

				if (cand.party) obj.party = this.parties.list.find(x => x.hash === cand.party);
				if (cand.nominee) obj.nominee = this.parties.list.find(x => x.reg === cand.nominee);
				if (cand.member) obj.member = this.parties.list.find(x => x.reg === cand.member);

				list.push(obj);
			});

			return list;
		},
		popularityData: function () {
			return this.$store.getters.pdv('popularity/best')
		},
		width: function () {
			return window.innerWidth
		},
		tags: function () {
			var tags = this.$store.getters.pdv('program/tags');
			var list = [];

			if (tags) {
				tags.list.forEach(tag => list.push(tag));
				list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			}

			return list;
		}
	},
  methods: {
		beautifyNumber,
		betterURL,
		date,
		slide: function (id) {
			this.$el.querySelector("a[name=" + id + "]").scrollIntoView({behavior: "smooth", block: "center"});
		}
	},
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch("ga", {title: "Sněmovní volby 2021"});
  }
};
