export default {
  source: 'https://www.kr-vysocina.cz/vismo5/dokumenty2.asp?id_org=450008&id=4109309',
  list: [
    {
      name: "Parkoviště",
      address: "Chotěbořská, Havlíčkův Brod",
      designated: "Havlíčkův Brod",
      maps: {
        "link": 'gujehajehe',
        "embed": 'gujehajehe'
      }
    },
    {
      name: "P+R Žižkova",
      address: "Žižkova, Jihlava",
      designated: "Jihlava",
      maps: {
        "link": 'mozobugojo',
        "embed": 'mozobugojo'
      }
    },
    {
      name: "zimní stadion, příjezd od Křemešnické",
      address: "areál stadionu Pelhřimov",
      designated: "Pelhřimov",
      maps: {
        "link": 'cuzakesohu',
        "embed": 'cuzakesohu'
      }
    },
    {
      name: "parkoviště u tělocvičky obchodní akademie",
      address: "Otmarova, Třebíč",
      designated: "Třebíč",
      maps: {
        "link": 'nupununelo',
        "embed": 'nupununelo'
      }
    },
    {
      name: "rychlobruslařský ovál",
      address: "Jungmannova ulice, Žďár nad Sázavou",
      designated: "Žďár nad Sázavou",
      maps: {
        "link": 'bojunemago',
        "embed": 'bojunemago'
      }
    }
  ]
}
