import axios from 'axios';
import InterestAnswer from '../../interest-answer/do';

export default {
	name: 'admin-interest-selection',
	props: ['data', 'hash', 'sid', 'topics'],
	data: function () {
		return {
			list: []
		}
	},
	components: {
		InterestAnswer
	},
	computed: {
	},
	methods: {
		topic: function (topic) {
			if (topic.selected === true) {
				this.remove(topic);
			} else {
				this.add(topic);
			}
		},
		remove: function (topic) {
			axios.post(this.$store.state.api + 'admin/interest-remove', {
				key: this.sid,
				topic: topic.hash,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					topic.selected = false;
				}
			})
		},
		add: function (topic) {
			axios.post(this.$store.state.api + 'admin/interest-add', {
				key: this.sid,
				topic: topic.hash,
				hash: this.hash
			}).then((response) => {
				if (response.data.code === 200) {
					topic.selected = true;
				}
			})
		},
		fetch: function () {
			// if (this.topics && this.topics.list) {
				this.list = [];

				this.$store.state.static.questions.topics.forEach(x => {
					this.list.push({
						hash: x.topic,
						name: x.name,
						selected: false
					})
				})

				this.list.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
			// }
			if (this.data.topics && this.data.topics.length > 0) {
				this.data.topics.forEach(x => this.list.find(y => y.hash === x.topic).selected = true);
			}
		}
	},
	mounted: function () {
		this.fetch();
	},
	watch: {
		data: function () {
			this.fetch();
		}
	}
};
