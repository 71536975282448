export default {
	name: 'main-footer',
	mounted: function () {
		setTimeout(() => {
			if (!window.Darujme) {
				var fn = function (w, d, s, u, a, b) {
					w['DarujmeObject'] = u;
					w[u] = w[u] || function () { (w[u].q = w[u].q || []).push(arguments) };
					a = d.createElement(s); b = d.getElementsByTagName(s)[0];
					a.async = 1; a.src = "https://www.darujme.cz/assets/scripts/widget.js";
					b.parentNode.insertBefore(a, b);
				}
				fn(window, document, 'script', 'Darujme');
			}
			window.Darujme(1, "zwio042l56a9yqn3", 'render', "https://www.darujme.cz/widget?token=zwio042l56a9yqn3", "100%");
		}, 100);
	}
};
