import TwitterFeed from "@/components/twitter/do";
import NewsFeed from "@/components/news-feed/do";
import ChartSingleParty from "@/components/charts/components/single-party-chart/do";
import ChartBudget from "@/components/charts/components/budget-chart/do";
import {beautifyNumber, betterURL, date, getLinkType} from "@/common/helpers";

export default {
	name: 'party-candidates',
	props: ['hash'],
	data: function () {
		return {
			view: 'alphabet'
		}
	},
	components: {
		TwitterFeed,
		ChartSingleParty,
		ChartBudget,
		NewsFeed
	},
	computed: {
		$: function () {
			return this.$store.getters.party;
		},
		party: function () {
			var data = this.$store.getters.pdv('party/detail/' + this.hash);

			return data ? data.list[0] : undefined;
		},
		peopleSource: function () {
			if (!this.party) return undefined;

			var fce = 'candidate/party/' + this.hash;

			if (this.party.status != 1 && this.party.reg) {
				fce = 'candidate/reg/' + this.party.reg;
			}

			var data = this.$store.getters.pdv(fce);

			return data ? data.list : undefined;
		},
		people: function () {
			if (!this.peopleSource) return undefined;

			var regions = [];

			this.$store.state.static.regions.forEach((region, index) => {
				var obj = {
					name: region,
					list: this.peopleSource.filter(x => x.region === index),
					links: this.party.links.filter(x => x.region && x.region === index).map(x => x.link),
					contacts: this.party.contacts.filter(x => x.region && x.region === index).map(x => x.value)
				}

				regions.push(obj);
			});

			if (this.peopleSource.filter(x => x.region === -1).length > 0) {
				var obj = {
					name: "Nezařazeno",
					list: this.peopleSource.filter(x => x.region === -1),
					links: []
				}

				regions.push(obj);
			}

			regions.forEach(region => {
				if (region.list) {
					region.list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));
				}
			})

			return regions;
		},
		alphabet: function () {
			if (!this.peopleSource) return undefined;

			var ab = "abcčdďefghijklmnňopqrřsštťuúvwxyýzž".split('');
			var list = [];

			ab.forEach(char => {
				var obj = {
					char: char.toUpperCase(),
					list: this.peopleSource.filter(x => x.name_family.charAt(0).toLowerCase() === char)
				}

				obj.list.sort((a, b) => a.name_family.localeCompare(b.name_family, 'cs'));

				list.push(obj);
			});

			return list;
		}
	},
	methods: {
		beautifyNumber,
		betterURL,
		date,
		getLinkType,
		ga: function () {
			var self = this;

			var t = setInterval(() => {
				if (self.party) {
					clearInterval(t);
					self.$store.dispatch("ga", {title: 'Kandidáti ' + self.party.name});
				}
			}, 100);
		}
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.ga();
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 0);
			this.ga();
		}
	}
};
