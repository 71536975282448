export default {
  source: 'https://jmk.brandcloud.pro/api/storage/download-file?id=1316367',
  list: [
    {
      name: "v areálu brněnského výstaviště – vjezd bránou č. 4 z ulice Křížkovského",
      address: "Křížkovského, Brno",
      designated: "Brno-město",
      maps: {
        "link": 'fedeguroma',
        "embed": 'fedeguroma'
      }
    },
    {
      name: "v areálu brněnského výstaviště – vjezd bránou č. 4 z ulice Křížkovského",
      address: "Křížkovského, Brno",
      designated: "Brno-venkov",
      maps: {
        "link": 'fedeguroma',
        "embed": 'fedeguroma'
      }
    },
    {
      name: "parkoviště na Sportovním ostrově Ludvíka Daňka",
      address: "Mlýnská, Blansko",
      designated: "Blansko",
      maps: {
        "link": 'mehovaleja',
        "embed": 'mehovaleja'
      }
    },
    {
      name: "Parkoviště před Zimním stadionem",
      address: "Břeclav, 2881",
      designated: "Břeclav",
      maps: {
        "link": 'goloconote',
        "embed": 'goloconote'
      }
    },
    {
      name: "Parkoviště u městského hřbitova",
      address: "Purkyňova, Hodonín",
      designated: "Hodonín",
      maps: {
        "link": 'nekanodufu',
        "embed": 'nekanodufu'
      }
    },
    {
      name: "parkoviště nám. Čsl. armády u pivovaru (vedle Rotundy)",
      address: "nám. Čsl. armády, Vyškov",
      designated: "Vyškov",
      maps: {
        "link": 'dafojabefa',
        "embed": 'dafojabefa'
      }
    },
    {
      name: "parkoviště u areálu Policie ČR",
      address: "Pražská 3872/59a, Znojmo",
      designated: "Znojmo",
      maps: {
        "link": 'bumonohone',
        "embed": 'bumonohone'
      }
    }
  ]
}
