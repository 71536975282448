export default {
  topics: [
    {topic: 'digitalizace', name: 'Digitalizace'},
    {topic: 'doprava', name: 'Doprava'},
    {topic: 'energetika', name: 'Energetika'},
    {topic: 'evropske-zalezitosti', name: 'Evropské záležitosti'},
    {topic: 'finance', name: 'Finance'},
    {topic: 'hybridni-hrozby', name: 'Hybridní hrozby'},
    {topic: 'kultura', name: 'Kultura'},
    {topic: 'media', name: 'Média'},
    {topic: 'mensiny', name: 'Menšiny'},
    {topic: 'mistni-rozvoj', name: 'Místní rozvoj'},
    {topic: 'mladez', name: 'Mládež'},
    {topic: 'obchod', name: 'Obchod'},
    {topic: 'obrana', name: 'Obrana'},
    {topic: 'petice-a-referenda', name: 'Petice a referenda'},
    {topic: 'prace', name: 'Práce'},
    {topic: 'prumysl', name: 'Průmysl'},
    {topic: 'rodina', name: 'Rodina'},
    {topic: 'rovne-prilezitosti', name: 'Rovné příležitosti'},
    {topic: 'sdilena-ekonomika', name: 'Sdílená ekonomika'},
    {topic: 'seniori', name: 'Senioři'},
    {topic: 'socialni-veci', name: 'Sociální věci'},
    {topic: 'sport', name: 'Sport'},
    {topic: 'spravedlnost', name: 'Spravedlnost'},
    {topic: 'skolstvi', name: 'Školství'},
    {topic: 'telovychova', name: 'Tělovýchova'},
    {topic: 'ustavni-otazky', name: 'Ústavní otázky'},
    {topic: 'veda', name: 'Věda'},
    {topic: 'verejna-sprava', name: 'Veřejná správa'},
    {topic: 'vnitro', name: 'Vnitro'},
    {topic: 'volby', name: 'Volby'},
    {topic: 'vzdelavani', name: 'Vzdělávání'},
    {topic: 'zahranicni-veci', name: 'Zahraniční věci'},
    {topic: 'zdravotnictvi', name: 'Zdravotnictví'},
    {topic: 'zemedelstvi', name: 'Zemědělství'},
    {topic: 'zivotni-prostredi', name: 'Životní prostředí'}
  ],
  list: [
    {id: 'di1', answers: {}, priority: 0, topic: 'digitalizace', question: 'Měl by stát dotovat Wi-Fi v obcích zdarma?'	},
    {id: 'di2', answers: {}, priority: 0, topic: 'digitalizace', question: 'Převažují podle vás přínosy uložení fotky a otisků prstů na občanský průkaz nad riziky?'	},
    {id: 'di3', answers: {}, priority: 1, topic: 'digitalizace', question: 'Měl by stát zajistit, aby i při úplné digitalizaci bylo možné jednat s úřady osobně nebo v listinné podobě?'	},
    {id: 'di4', answers: {}, priority: 0, topic: 'digitalizace', question: 'Měl by stát investovat do digitalizace služeb, přestože to může v některých případech znamenat vyšší náklady pro provoz?'},

    {id: 'do1', answers: {}, priority: 1, topic: 'doprava', question: 'Měla by být MHD plně hrazená z obecních rozpočtů?'	},
    {id: 'do2', answers: {}, priority: 0, topic: 'doprava', question: 'Souhlasíte s názorem, že aby se města vrátila lidem, je nutné omezit automobilovou dopravu?'	},
    {id: 'do3', answers: {}, priority: 1, topic: 'doprava', question: 'Měl by stát podporovat vznik infrastruktury pro elektromobily?'	},
    {id: 'do4', answers: {}, priority: 0, topic: 'doprava', question: 'Měla by být výstavba vysokorychlostních tratí a jejich napojení do zahraničí prioritou?'},

    {id: 'en1', answers: {}, priority: 0, topic: 'energetika', question: 'Jste pro další výstavbu či dostavbu jaderných elektráren?'},
    {id: 'en2', answers: {}, priority: 0, topic: 'energetika', question: 'Měl by ČEZ investovat či stavět elektrárny mimo území České republiky?'},
    {id: 'en3', answers: {}, priority: 0, topic: 'energetika', question: 'Zavedli byste dotace nebo formu daňové úlevy firmám i jednotlivcům, pokud si na střechy instalují solární panely?'},
    {id: 'en4', answers: {}, priority: 1, topic: 'energetika', question: 'Myslíte si, že by výroba elektřiny z uhlí měla být součástí energetického mixu i po roce 2030?'},

    {id: 'eu1', answers: {}, priority: 0, topic: 'evropske-zalezitosti', question: 'Měla by ČR činit aktivní kroky, aby do roku 2030 přijala euro?'},
    {id: 'eu2', answers: {}, priority: 1, topic: 'evropske-zalezitosti', question: 'Měly by se země EU, které nejsou zasažené migrací, více zapojit do společného řešení?'},
    {id: 'eu3', answers: {}, priority: 0, topic: 'evropske-zalezitosti', question: 'Jste pro rozšíření EU o země Balkánu, pokud státy splní všechny nutné podmínky?'},

    {id: 'fin1', answers: {}, priority: 1, topic: 'finance', question: 'Mělo by Česko v příštím volebním období výrazně snížit tempo zadlužování, přestože jeho dluh patří k nejnižším v EU?'},
    {id: 'fin2', answers: {}, priority: 1, topic: 'finance', question: 'Měl by stát snížit daně podnikatelům a firmám a pomoci jim tak k zotavení po covidu?'},
    {id: 'fin3', answers: {}, priority: 1, topic: 'finance', question: 'Bylo podle vás EET přínosné a mělo by se obnovit?'},
    {id: 'fin4', answers: {}, priority: 1, topic: 'finance', question: 'Jste pro zavedení progresivní daně z příjmu fyzických osob?'},

    {id: 'hh1', answers: {}, priority: 0, topic: 'hybridni-hrozby', question: 'Měla by se Česká republika bránit hybridním hrozbám stejným způsobem a formou jako agresor?'},
    {id: 'hh2', answers: {}, priority: 0, topic: 'hybridni-hrozby', question: 'Měla by být všechna média, která přijímají peníze či obsah do zpravodajství ze zemí mimo EU, označena ve vysílání i na sociálních sítích?'},
    {id: 'hh3', answers: {}, priority: 1, topic: 'hybridni-hrozby', question: 'Vadilo by vám, pokud by se ruské nebo čínské firmy podílely na dostavbě jaderných elektráren?'},
    {id: 'hh4', answers: {}, priority: 1, topic: 'hybridni-hrozby', question: 'Měl by Koudelka pokračovat v čele BIS?'},

    {id: 'ku1', answers: {}, priority: 0, topic: 'kultura', question: 'Považujete současnou podporu kultury za dostatečnou?'},
    {id: 'ku2', answers: {}, priority: 0, topic: 'kultura', question: 'Měla by Česká televize nadále z koncesionářských poplatků podporovat a financovat vznik nových českých filmů a seriálů?'},
    {id: 'ku3', answers: {}, priority: 0, topic: 'kultura', question: 'Myslíte si, že by veřejnoprávní média měla zařazovat českou tvorbu nějakým procentem z celkového vysílaného objemu?'},

    {id: 'me1', answers: {}, priority: 1, topic: 'media', question: 'Jste pro zachování koncesionářských poplatků jako formy financování České televize a Českého rozhlasu?'},
    {id: 'me2', answers: {}, priority: 0, topic: 'media', question: 'Měly by vyhledávače jako Google, Seznam, Bing a další platit tvůrcům obsahu za zveřejňování částí textů a obrázků ve vyhledávání?'},
    {id: 'me3', answers: {}, priority: 0, topic: 'media', question: 'Považujete současnou podobu volby do různých mediálních rad za funkční?'},
    {id: 'me4', answers: {}, priority: 1, topic: 'media', question: 'Podporujete zavedení přiměřené regulace soukromých médií, která budou limitovat jejich vlastnictví politiky a skupování médií, například omezením možné dominance na mediálním trhu?'},

    {id: 'mr1', answers: {}, priority: 0, topic: 'mistni-rozvoj', question: 'Souhlasíte se vznikem centrálního stavebního úřadu?'},
    {id: 'mr2', answers: {}, priority: 0, topic: 'mistni-rozvoj', question: 'Měla by města a obce dostávat větší díl z celkově vybraných daní?'},
    {id: 'mr3', answers: {}, priority: 0, topic: 'mistni-rozvoj', question: 'Měly by mít obce právo určovat podobu rodinných domů, které se v nich smí stavět?'},
    {id: 'mr4', answers: {}, priority: 1, topic: 'mistni-rozvoj', question: 'Měl by stát poskytovat bezúročné půjčky mladým rodinám na pořízení prvního bydlení?'},

    {id: 'ml1', answers: {}, priority: 0, topic: 'mladez', question: 'Měli by rodiče mít přístup do sociálních sítí svých dětí?'},
    {id: 'ml2', answers: {}, priority: 1, topic: 'mladez', question: 'Mělo by být součástí školní výchovy dětí vedení k vlastenectví?'},
    {id: 'ml3', answers: {}, priority: 0, topic: 'mladez', question: 'Souhlasíte s názorem, že by každému mladistvému mělo být umožněno žít (pracovat nebo studovat) na nějakou dobu v jiné části Unie? Neboli, měli by mladí vyrážet na zkušenou?'},

    {id: 'min1', answers: {}, priority: 0, topic: 'mensiny', question: 'Měla by se Česká republika připojit k Istanbulské úmluvě?'},
    {id: 'min2', answers: {}, priority: 1, topic: 'mensiny', question: 'Souhlasíte, aby mohl homosexuální pár uzavřít sňatek, který bude z pohledu práva rovnocenný s manželstvím?'},
    {id: 'min3', answers: {}, priority: 0, topic: 'mensiny', question: 'Máte pocit, že v české společnosti sílí extrémistické tendence a názory?'},

    {id: 'obch1', answers: {}, priority: 1, topic: 'obchod', question: 'Měl by stát omezovat, kdy smí mít obchod otevřeno? (svátky, neděle, apod)'},
    {id: 'obch2', answers: {}, priority: 0, topic: 'obchod', question: 'Podporujete zavedení globální daně pro korporace a digitální služby?'},
    {id: 'obch3', answers: {}, priority: 0, topic: 'obchod', question: 'Měl by stát podporovat nákup elektromobilů, formou dotací nebo daňových úlev?'},

    {id: 'obr1', answers: {}, priority: 1, topic: 'obrana', question: 'Považujete naše členství v NATO za důležitou součást naší obrany?'},
    {id: 'obr2', answers: {}, priority: 0, topic: 'obrana', question: 'Měla by být kurzy sebeobrany zavedeny jako povinná součást školní výuky?'},
    {id: 'obr3', answers: {}, priority: 0, topic: 'obrana', question: 'Měla by se EU starat o svou obranu a vytvořit za tímto účelem vlastní armádu?'},

    {id: 'p-r1', answers: {}, priority: 0, topic: 'petice-a-referenda', question: 'Souhlasíte se zavedením referenda, ve kterém by bylo možné hlasovat o zrušení nějakého přijatého zákona?'},
    {id: 'p-r2', answers: {}, priority: 0, topic: 'petice-a-referenda', question: 'Měl by stát vytvořit platformu pro online petice, která by využívala eObčanky, bankovní identitu apod k jasné identifikaci podepisujícího?'},
    {id: 'p-r3', answers: {}, priority: 1, topic: 'petice-a-referenda', question: 'Měla by být účast ve volbách povinná?'},

    {id: 'pra1', answers: {}, priority: 0, topic: 'prace', question: 'Myslíte si, že by minimální mzda měla do roku 2025 dosáhnout 20 000 korun?'},
    {id: 'pra2', answers: {}, priority: 1, topic: 'prace', question: 'Jste pro zkrácení pracovního týdne na 4 dny, neboli 32 hodin?'},
    {id: 'pra3', answers: {}, priority: 0, topic: 'prace', question: 'Měla by firma mít možnosti snadněji propustit nepotřebného zaměstnance?'},

    {id: 'pru1', answers: {}, priority: 1, topic: 'prumysl', question: 'Považujete Zelenou dohodu pro Evropu (European Green Deal) za novou příležitost pro český průmysl?'},
    {id: 'pru2', answers: {}, priority: 0, topic: 'prumysl', question: 'Měl by stát dotovat či daňově zvýhodnit průmyslové podniky se zaměřením na elektromobilitu, například tzv gigafactory?'},
    {id: 'pru3', answers: {}, priority: 0, topic: 'prumysl', question: 'Měla by těžká manuální práce být co nejdříve nahrazena roboty i za cenu rizika ztráty pracovních míst?'},

    {id: 'rod1', answers: {}, priority: 0, topic: 'rodina', question: 'Jste pro ukotvení pojmu manželství jako výhradního pojmenování svazku muže a ženy?'},
    {id: 'rod2', answers: {}, priority: 0, topic: 'rodina', question: 'Myslíte si, že problematika domácího násilí je dnes dostatečně řešená a oběti mají od státu dostatečnou ochranu?'},
    {id: 'rod3', answers: {}, priority: 1, topic: 'rodina', question: 'Jste pro, aby homosexuální pár mohl požádat o adopci, případně o osvojení dítěte partnera?'},
    {id: 'rod4', answers: {}, priority: 0, topic: 'rodina', question: 'Měly by být rodiny s více dětmi více daňově zvýhodněné?'},

    {id: 'rp1', answers: {}, priority: 0, topic: 'rovne-prilezitosti', question: 'Měli by být muži a ženy rovně zastoupeni ve Sněmovně či v krajských zastupitelstvech?'},
    {id: 'rp2', answers: {}, priority: 0, topic: 'rovne-prilezitosti', question: 'Měl by mít zaměstnavatel právo poptávat na nějakou pozici jen muže, nebo jen ženy?'},
    {id: 'rp3', answers: {}, priority: 1, topic: 'rovne-prilezitosti', question: 'Měly by státní organizace a úřady obsazovat do vedoucích pozic muže a ženy na základě kvót?'},

    {id: 'sek1', answers: {}, priority: 0, topic: 'sdilena-ekonomika', question: 'Měli by občasní řidiči alternativních taxislužeb splňovat stejné podmínky jako jejich profesionální kolegové?'},
    {id: 'sek2', answers: {}, priority: 0, topic: 'sdilena-ekonomika', question: 'Myslíte si, že by měl stát regulovat služby jako je například Airbnb?'},
    {id: 'sek3', answers: {}, priority: 1, topic: 'sdilena-ekonomika', question: 'Měli by všichni, kdo nabízejí služby sdílené ekonomiky, mít živnostenské oprávnění a být tak regulerními podnikateli?'},

    {id: 'sen1', answers: {}, priority: 0, topic: 'seniori', question: 'Souhlasíte s tím, aby byl důchodci přiznáván za každé vychované dítě bonus 500 korun k důchodu?'},
    {id: 'sen2', answers: {}, priority: 1, topic: 'seniori', question: 'Jste pro zvýšení věku odchodu do důchodu?'},
    {id: 'sen3', answers: {}, priority: 1, topic: 'seniori', question: 'Jste pro zachování slev pro seniory a studenty na jízdném?'},

    {id: 'sk1', answers: {}, priority: 0, topic: 'skolstvi', question: 'Myslíte si, že stát udělat inkluzivním vzděláváním dobrý krok?'},
    {id: 'sk2', answers: {}, priority: 1, topic: 'skolstvi', question: 'Mělo by být studium na veřejných vysokých školách nadále bez školného?'},
    {id: 'sk3', answers: {}, priority: 0, topic: 'skolstvi', question: 'Jste pro zachování státní maturity?'},

    {id: 'soc1', answers: {}, priority: 1, topic: 'socialni-veci', question: 'Považujete pomoc státu v době koronavirové krize za dostatečnou? (programy Antivirus, Pětadvacítka apod)'},
    {id: 'soc2', answers: {}, priority: 0, topic: 'socialni-veci', question: 'Měli by zdraví a schopní lidé, kteří pobírají dávky podpory v nezaměstnanosti, vykonávat veřejně prospěšné práce?'},
    {id: 'soc3', answers: {}, priority: 0, topic: 'socialni-veci', question: 'Souhlasíte, že příspěvek na dítě by měl být podmíněn školní docházkou?'},

    {id: 'spo1', answers: {}, priority: 0, topic: 'sport', question: 'Souhlasíte s názorem, že transgender sportovci by měli mít vlastní soutěžní kategorii?'},
    {id: 'spo2', answers: {}, priority: 0, topic: 'sport', question: 'Měl by stát vytvořit databázi chuligánů, aby bylo možné omezit jejich vstup na stadiony?'},
    {id: 'spo3', answers: {}, priority: 0, topic: 'sport', question: 'Měl by stát nadále finančně podporovat profesionální sportovce a kluby?'},

    {id: 'spr1', answers: {}, priority: 0, topic: 'spravedlnost', question: 'Mělo by dojít k zjednodušení oddlužení (osobních bankrotů) i za cenu zhoršení pozice věřitelů?'},
    {id: 'spr2', answers: {}, priority: 0, topic: 'spravedlnost', question: 'Podporujete přijetí zákona o ochraně oznamovatelů korupce (whistleblowerů) a s tím spojené zřízení důvěrných a důvěryhodných kanálů pro podání oznámení a zavedení ochrany oznamovatelů před odvetnými opatřeními?'},
    {id: 'spr3', answers: {}, priority: 1, topic: 'spravedlnost', question: 'Jste pro zestátnění exekucí a zákaz výkonu soudních exekucí jako svobodného povolání?'},

    {id: 'tel1', answers: {}, priority: 0, topic: 'telovychova', question: 'Měly by zdravotní pojišťovny více zvýhodňovat klienty, kteří sportují?'},
    {id: 'tel2', answers: {}, priority: 0, topic: 'telovychova', question: 'Měl by být zvýšen příspěvek na pohybové aktivity seniorů?'},
    {id: 'tel3', answers: {}, priority: 0, topic: 'telovychova', question: 'Měla by být joga součástí tělesné výchovy ve školách?'},

    {id: 'uo1', answers: {}, priority: 0, topic: 'ustavni-otazky', question: 'Máte pocit, že je v České republice ohrožena svoboda slova?'},
    {id: 'uo2', answers: {}, priority: 1, topic: 'ustavni-otazky', question: 'Měla by být ústava státu nadřazena přijatým mezinárodním smlouvám?'},
    {id: 'uo3', answers: {}, priority: 0, topic: 'ustavni-otazky', question: 'Považujete Senát za důležitou součást našeho demokratického systému?'},

    {id: 'ved1', answers: {}, priority: 0, topic: 'veda', question: 'Měly by genderová studia být součástí nabídky státních škol?'},
    {id: 'ved2', answers: {}, priority: 0, topic: 'veda', question: 'Souhlasíte s názorem, že v době sociálních sítí a desinformací prožíváme krizi vědy a důvěry ve vědecké poznání?'},
    {id: 'ved3', answers: {}, priority: 0, topic: 'veda', question: 'Měl by stát nabízet větší daňové úlevy firmám, které zde budou zřizovat vědecká a výzkumná centra?'},

    {id: 'vs1', answers: {}, priority: 1, topic: 'verejna-sprava', question: 'Myslíte si, že by mělo být nadále povinné uvádět pohlaví na občanském průkazu?'},
    {id: 'vs2', answers: {}, priority: 0, topic: 'verejna-sprava', question: 'Podpořili byste přesunutí některých ministerstev a úřadú státní správy mimo Prahu?'},
    {id: 'vs3', answers: {}, priority: 0, topic: 'verejna-sprava', question: 'Souhlasili byste s návrhem, že místo ve středu odpoledne by měly úřady otevřeno v sobotu dopoledne?'},

    {id: 'vn1', answers: {}, priority: 0, topic: 'vnitro', question: 'Měly by se vyšetřit nákupy a postupy státu z období koronavirové krize?'},
    {id: 'vn2', answers: {}, priority: 0, topic: 'vnitro', question: 'Měl by stát za přísných podmínek umožnit eutanázii?'},
    {id: 'vn3', answers: {}, priority: 1, topic: 'vnitro', question: 'Jste pro legalizaci pěstování a prodeje lehkých drog, čímž by stát získal další zdroj příjmů?'},

    {id: 'vol1', answers: {}, priority: 1, topic: 'volby', question: 'Jste pro zavedení korespondenční volby poštou?'},
    {id: 'vol2', answers: {}, priority: 0, topic: 'volby', question: 'Měl by stát umožnit volby přes internet?'},
    {id: 'vol3', answers: {}, priority: 0, topic: 'volby', question: 'Jste pro přímou volbu starostů?'},

    {id: 'vzd1', answers: {}, priority: 0, topic: 'vzdelavani', question: 'Měly by mít školy větší svobodu ve volbě osnov a obsahu?'},
    {id: 'vzd2', answers: {}, priority: 0, topic: 'vzdelavani', question: 'Jste pro trvalé zavedení prvků distančního vzdělávání do současného systému vzdělávání?'},
    {id: 'vzd3', answers: {}, priority: 1, topic: 'vzdelavani', question: 'Měla by součástí vzdělání být i mediální či digitální gramotnost a pochopení vlivu sociálních sítí na jedince?'},

    {id: 'zv1', answers: {}, priority: 0, topic: 'zahranicni-veci', question: 'Mělo by Turecko být nadále členem NATO?'},
    {id: 'zv2', answers: {}, priority: 0, topic: 'zahranicni-veci', question: 'Měla by se Česká republika nadále soustředit na úzkou spolupráci se zeměmi V4 (Slovensko, Polsko, Maďarsko)?'},
    {id: 'zv3', answers: {}, priority: 1, topic: 'zahranicni-veci', question: 'Považujete aktivity vlád Ruska a Číny za ohrožení České republiky?'},

    {id: 'zdr1', answers: {}, priority: 1, topic: 'zdravotnictvi', question: 'Měl by mít zaměstnavatel právo požadovat očkování, které je jinak nepovinné?'},
    {id: 'zdr2', answers: {}, priority: 0, topic: 'zdravotnictvi', question: 'Myslíte si, že pro udržení kvalitního zdravotnictví bude potřeba zavést nějakou formu spoluúčasti?'},
    {id: 'zdr3', answers: {}, priority: 1, topic: 'zdravotnictvi', question: 'Měly by uzeniny, slazené limonády a obecně nezdravé potraviny být zdaněny standardní sazbou 21 % místo zvýhodněné sazby na potraviny?'},

    {id: 'zem1', answers: {}, priority: 1, topic: 'zemedelstvi', question: 'Považujete kvóty na české potraviny v supermarketech za správnou formu podpory českého zemědělství?'},
    {id: 'zem2', answers: {}, priority: 0, topic: 'zemedelstvi', question: 'Jste pro zastropování přímých zemědělských dotací, které se přidělují takzvaně na hektar, podle konečného benefienta?'},
    {id: 'zem3', answers: {}, priority: 0, topic: 'zemedelstvi', question: 'Souhlasíte s nastavením pravidel zemědělských dotací tak, aby odpovídající část investičních dotací směřovala vždy do ekologického zemědělství?'},

    {id: 'zp1', answers: {}, priority: 1, topic: 'zivotni-prostredi', question: 'Souhlasíte s názorem, že vývoj klimatu představuje riziko pro lidskou civilizaci a měli bychom se snažit jeho projevy mírnit?'},
    {id: 'zp2', answers: {}, priority: 1, topic: 'zivotni-prostredi', question: 'Mělo by se přidělení dotace více přihlížet na dopady projektu na životní prostředí?'},
    {id: 'zp3', answers: {}, priority: 0, topic: 'zivotni-prostredi', question: 'Jste pro omezení velikosti polí, aby to podpořilo větší rozmanitost druhů v přírodě?'}

  ]
};
