import axios from 'axios';

export default {
	name: 'admin-party-core',
	props: ['hash', 'sid', 'data', 'region'],
	data: function () {
		return {
			values: [],
			district: null,
			town: null,
			alert: null,
			sending: false
		}
	},
	methods: {
		save: function () {
			var data = {
				name: this.values.find(x => x.id === 'name').content,
				short: this.values.find(x => x.id === 'short').content
			}

			this.sending = true;

			axios.post(this.$store.state.api + 'admin/party-core-update', {
				key: this.sid,
				hash: this.hash,
				values: data
			}).then(() => {
				this.alert = "Uloženo";
				this.sending = false;
				setTimeout(() => this.alert = null, 2500);
			})
		}
	},
	computed: {},
	mounted: function () {
		this.values.push({
			label: 'Link',
			id: 'hash',
			content: '/strana/' + this.data.hash,
			type: 'text',
			empty: false,
			editable: false
		})
		this.values.push({
			label: 'Jméno',
			id: 'name',
			original: this.data.name,
			content: this.data.name,
			type: 'text',
			empty: false,
			editable: typeof this.region != 'number'
		})
		this.values.push({
			label: 'Zkratka',
			id: 'short',
			original: this.data.short,
			content: this.data.short,
			type: 'text',
			empty: false,
			editable: typeof this.region != 'number'
		})
		if (this.region) {
			this.values.push({
				label: 'Region',
				id: 'region',
				content: this.$store.state.static.regions[this.region],
				type: 'text',
				empty: false,
				editable: false
			})
		}
	}
};
